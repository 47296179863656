import { isNil } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';

import Auth from '../service/AuthService';
import {
  setAdviserCore,
  setSessionToken,
  removeSessionToken,
  removeFiltersOption,
  removeSortByOption,
  removeAdviserCore,
  setAdviserData,
  getAdviserData,
  removeAdviserData,
  getSessionToken,
} from '../service/LocalStorageService';

export const ProfileContext = createContext({
  profile: {},
  setProfile: (profileData) => {},
  isLoading: true,
  logOut: () => {},
  isLoggedIn: false,
  hasSubscription: false,
  showWelcomeScreen: true,
  featureFlags: {
    profileQuestionsV2: false,
    xPlanIntegration: false,
  },
  selectedSubscriptionId: '',
  setSelectedSubscriptionId: (subscriptionId) => {},
  isComplii: false,
});

const ClearProfile = () => {
  setProfile(null);
};

export function LogOut() {
  loggingOutHandler.logOutHandlerFunction();
}

const loggingOutHandler = {
  logOutHandlerFunction: {},
};

export function ProfileContextProvider(props) {
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [featureFlags, setFeatureFlags] = useState();
  const [hasSubscription, setHasSubscription] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [isComplii, setIsComplii] = useState(false);

  const setProfileHandler = (profileData) => {
    setFeatureFlags(profileData.featureFlags);
    if (profileData.HasConfirmedWelcomeScreen !== null) {
      setShowWelcomeScreen(!profileData.hasConfirmedWelcomeScreen);
    }
    setHasSubscription(profileData.isSubscribedAdviser);
    setProfile(profileData);
    setIsComplii(profileData.isComplii);
  };

  const setSelectedSubscriptionIdHandler = (selectedId) => {
    setSelectedSubscriptionId(selectedId);
  };

  const logOutHandler = () => {
    removeAdviserCore();
    removeAdviserData();
    removeSessionToken();
    removeFiltersOption();
    removeSortByOption();
    setShowWelcomeScreen(false);
    setIsLoggedIn(false);
    setIsComplii(false);
  };

  loggingOutHandler.logOutHandlerFunction = logOutHandler;

  useEffect(() => {
    const sessionToken = getSessionToken();
    if (!isNil(sessionToken)) {
      setIsLoggedIn(true);
    } else {
      logOutHandler();
    }

    // Checking if the Session Token has Expired
    if (isNil(profile)) {
      if (!isNil(sessionToken)) {
        setIsLoading(true);
        Auth.getUser()
          .then((params) => {
            if (params.ok && params.data != null) {
              setProfileHandler(params.data.adviserUser);
              console.log('params.data.adviserUser', params.data.adviserUser);
            } else {
              logOutHandler();
            }
          })
          .catch((exception) => {
            // console.log(exception)
            logOutHandler();
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        logOutHandler();
        setIsLoading(false);
      }
    }
  });

  return (
    <ProfileContext.Provider
      value={{
        profile: profile,
        setProfile: setProfileHandler,
        isLoading: isLoading,
        isLoggedIn: isLoggedIn,
        logOut: logOutHandler,
        featureFlags: featureFlags,
        hasSubscription: hasSubscription,
        showWelcomeScreen: showWelcomeScreen,
        selectedSubscriptionId: selectedSubscriptionId,
        setSelectedSubscriptionId: setSelectedSubscriptionIdHandler,
        isComplii: isComplii,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
}
