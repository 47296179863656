import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import Auth from 'service/AuthService';
import { ProfileContext } from 'store/ProfileContext';

import compliiLogo from '../../../../../_assets/img/icons/profile/Complii_Logo 1.svg';
import clientProfiling from '../../../../../_assets/img/icons/profile/Group 209 1.svg';
import personlisedResults from '../../../../../_assets/img/icons/profile/Group 213 1.svg';
import realtimeSummaries from '../../../../../_assets/img/icons/profile/Group 273 2.svg';
import watchDemo from '../../../../../_assets/img/icons/profile/Group 274.svg';

import './CompliiView.scss';

const WelcomeCompliiScreen = () => {
  const history = useHistory();
  const profileContext = useContext(ProfileContext);

  const handleWatchDemo = () => {
    window.open('https://www.youtube.com/watch?v=09ChSm7U6qg', '_blank');
  };

  const handleClick = () => {
    Auth.setWelcomeScreen().then(() => {
      Auth.getUser().then((params) => {
        if (params.ok && params.data != null) {
          profileContext.setProfile(params.data.adviserUser);
          history.push('/clients');
        }
      });
    });
  };

  return (
    <div
      style={{
        maxWidth: '1300px',
        margin: '0 auto',
      }}
      className="p-5"
    >
      <div>
        <h2 style={{ justifyContent: 'center', alignItems: 'center' }}>Welcome to Oko Adviser</h2>
        <div className="mt-20">
          <p style={{ color: 'bold' }}>
            A platform for Advisers to identify and incorporate a clients personal sustainable
            considerations into their investment decisions. The efficient workflow is powered by
            Oko’s market leading sustainability data and supports client discovery, advanced
            research and advice support.
          </p>
        </div>
        <Row>
          <Col>
            <div>
              <p className="text-center mt-40">Client Profiling</p>
              <img style={{ width: '100%' }} src={clientProfiling} alt="welcome" />
            </div>
          </Col>
          <Col>
            <div>
              <p className="text-center mt-40">Personalised results</p>
              <img
                className="mx-auto"
                style={{ width: '100%' }}
                src={personlisedResults}
                alt="welcome"
              />
            </div>
          </Col>
          <Col>
            <div>
              <p className="text-center mt-40">Realtime summaries</p>
              <img style={{ width: '100%' }} src={realtimeSummaries} alt="welcome" />
            </div>
          </Col>
        </Row>
        <div className="text-container">
          <p>
            Plus much more. <br />
            Start a 30 day free trial today. A team member <br />
            will reach out to get you up and running
          </p>
        </div>
        <div className="text-container">
          <Button
            style={{
              width: '286px',
              height: '56px',
            }}
            className="btn-main-shadowless"
            onClick={handleClick}
          >
            Continue
          </Button>
        </div>
        <div className="row position-relative mt-40">
          <div className="col-12 py-3 d-flex justify-content-center align-items-center text-center">
            <div className="top-50">
              Watch Demo{' '}
              <img
                style={{ width: '30px', height: '20px' }}
                src={watchDemo}
                alt="watch demo"
                onClick={handleWatchDemo}
              />
            </div>
          </div>
          <div className="col position-absolute text-right">
            <img style={{ width: '180px', height: '60px' }} src={compliiLogo} alt="complii logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCompliiScreen;
