import { isEmpty } from 'lodash';

import { useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';

import Grade from '_components/commons/Grade';
import StatusPill from '_components/commons/StatusPill';

import { redirectToInstrumentPage } from '../../_helpers/instrument';
import { InstrumentInterface } from '../../model/Instrument';
import NoDataView from '../commons/Instrument/NoDataView';

interface RelatedInvestmentsProps {
  relatedInvestments?: InstrumentInterface[];
  clientName?: string;
  showMatch: boolean;
}

export default function RelatedInvestments(props: RelatedInvestmentsProps) {
  const history = useHistory();

  if (!props.relatedInvestments) {
    return null;
  }

  const renderContent = () => {
    if (isEmpty(props.relatedInvestments)) {
      return (
        <div>
          <NoDataView />
        </div>
      );
    }
    return (
      <>
        <Table
          style={{
            minHeight: '30vh',
          }}
          borderless
          hover
          className="custom-table text-center"
        >
          <thead>
            <tr>
              <th></th>
              <th style={{ position: 'relative', width: '400px' }}>{`Name`}</th>
              <th>{`Symbol`}</th>
              <th style={{ width: '200px' }}>{`Sector`}</th>
              <th>{`Type`}</th>
              {/* <th>{`Return(Yr)`}</th> */}
              <th style={{ width: '110px' }}>{`Grade`}</th>
              {/* <th>{`Planet`}</th>
              <th>{`Community`}</th>
              <th>{`Employees`}</th>
              <th>{`Ethics`}</th> */}
              {props.showMatch ? <th>{`Match`}</th> : ''}
            </tr>
          </thead>
          <tbody>
            {props?.relatedInvestments
              ? props.relatedInvestments.map((value) => {
                  return (
                    <tr key={value.id} onClick={redirectToInstrumentPage(value, history)}>
                      <td>
                        <img
                          className="company-icon"
                          alt="logo"
                          src={value.logoUrl}
                          width="42"
                          height="42"
                        />
                      </td>
                      <td style={{ position: 'relative', whiteSpace: 'pre-line' }}>{value.name}</td>
                      <td>{value.symbol}</td>
                      <td>{value.sector}</td>
                      <td className={value.type.length === 3 ? 'uppercase' : 'capitalize'}>
                        {value.type}
                      </td>
                      {/* <td style={style}>{value.return}</td> */}
                      <td>
                        <Grade className="grades-related-investment" grade={value.grade} />
                        {/* {value.overallPercentageValue === null ? (
                      ''
                    ) : (
                      <Grade
                        className="grades-related-investment"
                        ={Number(value..replace('%', ''))}
                      />
                    )} */}
                      </td>
                      {/* <td>{value.planetScore}</td>
                  <td>{value.communityScore}</td>
                  <td>{value.employeesScore}</td>
                  <td>{value.ethicsScore}</td> */}
                      {props.showMatch ? (
                        <td>
                          <StatusPill matchToValuesStatus={value.matchToValuesStatus} />
                        </td>
                      ) : (
                        ''
                      )}
                    </tr>
                  );
                })
              : ''}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <div className="panel w-100">
      <div className="mt-20 mb-20">
        <h4>{`Related Investments`}</h4>
        {props.clientName && (
          <p className="mb-0">{`Top, non conflicted funds based on ${props.clientName}'s sustainability profile`}</p>
        )}
      </div>
      {renderContent()}
    </div>
  );
}
