import React, { useState } from 'react';
import { useEffect } from 'react';

import { Button, Col, Container, Row } from 'reactstrap';

import { LoadingPage } from '_components/commons';
import { postXplanCode } from 'service/SendXplanCodeService';

import Bookmark from './../../../_assets/img/icons/profileIntro/support@3x.png';

export const XplanConnected = () => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const code = url.split('=').pop();

    postXplanCode(code)
      .then(() => {
        setConnected(true);
      })
      .catch((err) => {
        console.log(err.message);
        setConnected(false);
      });
  }, []);

  const handleClick = () => {
    window.location.href = '/';
  };

  const handleClickPortfolio = () => {
    window.location.href = '/clients/portfoliosUpload';
  };

  return connected ? (
    <>
      <Container className="investing-profile">
        <Row className="mt-80 mb-5 justify-content-center">
          <img src={Bookmark} width="150" />
        </Row>
        <Row className="justify-content-center mb-30">
          <p className="welcome-header">{`Connection Success!`}</p>
        </Row>
        <Row className="mb-3 justify-content-center">
          <p className="text-center" style={{ fontSize: '20px' }}>
            Your <span className="strong-text">Xplan</span> account is now connected!
          </p>
          <p
            className="text-center"
            style={{ fontSize: '20px' }}
          >{`Your Clients and Portfolios are now available on Oko Adviser.`}</p>
        </Row>
        <Row className="justify-content-center">
          <Col sm={4} className="mb-5">
            <div className="text-center btn-blue">
              <Button style={{ backgroundColor: '#53b8d3' }} onClick={handleClick}>
                Go to Client List
              </Button>
            </div>
          </Col>
          <Col sm={4} className="mb-5">
            <div className="text-center btn-blue">
              <Button onClick={handleClickPortfolio}>Go to Portfolios</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  ) : (
    <LoadingPage />
  );
};
