import { Table } from 'reactstrap';
import { Row } from 'reactstrap';

interface InsightsTableState {
  title: string;
  isLoading: boolean;
  tableItems: InsightsTablesState[];
  note: string;
  handleClick: (key: string) => void;
}

type InsightsTablesState = {
  logoUrl: string;
  name: string;
  symbol: string;
  sector: string;
  returnYr: string;
  overall: number;
};

export default function InsightsTable(props: InsightsTableState) {
  const styleFn = (value: any) => {
    if (value.returnYr === '- %') {
      return { color: '#2E3074' };
    } else if (value.returnYr.indexOf('-') > -1) {
      return { color: '#BD5F80' };
    } else {
      return { color: '#2AB564' };
    }
  };
  {
    return (
      <div className="table-panel">
        <Row style={{ marginLeft: 28, marginTop: 15 }}>
          <h4 className="table-title">{props.title}</h4>
        </Row>
        <Row style={{ marginLeft: 9, marginTop: 12 }}>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Symbol</th>
                <th>Sector</th>
                <th>Return (Yr)</th>
                <th>Overall</th>
              </tr>
            </thead>

            <tbody>
              {props.tableItems.map((value) => {
                return (
                  <tr key={value.name}>
                    <td>
                      <img src={value.logoUrl} alt="logo" width="40" height="40" />
                    </td>
                    <td>{value.name}</td>
                    <td>{value.symbol}</td>
                    <td>{value.sector}</td>
                    <td className="table-data-return" style={styleFn(value)}>
                      {value.returnYr}
                    </td>
                    <td>{value.overall}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
        <Row style={{ marginLeft: 28, marginTop: 10 }}>
          <p style={{ fontWeight: '300', fontSize: 10 }}>
            <b style={{ fontWeight: '900', fontSize: 10 }}>Note:</b> {props.note}
          </p>
        </Row>
      </div>
    );
  }
}
