export interface Behaviour {
  question: string;
  questionDesc: string;
  iconUrl: string;
  answer: string;
  behaviourDetail: string;
  category: string;
  isForMatch: boolean;
  isForConflict: boolean;
}

export interface SelectedAnswers {
  [key: string]: string;
}

export enum Category {
  'Planet' = 'planet',
  'People' = 'people',
  'Ethics' = 'ethics',
}
