import { find } from 'lodash';
import React, { PureComponent } from 'react';

import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, FormGroup, Col, Row, Button } from 'reactstrap';

import apiStripe from '../../config/apiStripe';
//import {getStripeCardAccountsByUserId} from '../../service/PaymentsService';
import { getCurrentSubscription } from '../../service/PaymentsService';
import { getSubscriptionTypes } from '../../service/SubscriptionAccountService';
import { createSubscription } from '../../service/SubscriptionAccountService';
import ValidatorService from '../../service/validator';
import { ButtonLoading, LoadingPage, TextField, PageHeader, TextFieldCardMask } from '../commons';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

class BillingEdit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionId: null,
      card: {
        exp_month: '',
        exp_year: '',
        last4: '',
        name: '',
        cvc: '',
      },
      loading: false,
      loadingButton: false,
    };
  }

  async componentDidMount() {
    this.setState(() => ({ loading: true }));
    getSubscriptionTypes()
      .then((sTypes) => {
        getCurrentSubscription()
          .then((cSubs) => {
            this.setState(() => ({
              subscriptionId: find(
                sTypes.adviserSubscriptions,
                (adviserSubscription) =>
                  adviserSubscription.subscriptionType === cSubs.SubscriptionType,
              ).subscriptionId,
              loading: false,
            }));
          })
          .catch((err) => {
            toast.error(err.message);
            this.setState({ loading: false });
          });
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ loading: false });
      });
  }

  handleChange({ target: { id, value } }) {
    this.setState((state) => ({ card: { ...state.card, [id]: value } }));
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState(() => ({ loadingButton: true }));
    const { subscriptionId } = this.state;

    let card = {
      name: encodeURI(this.state.card.name),
      number: this.state.card.last4,
      exp_month: this.state.card.exp_month,
      exp_year: '20' + this.state.card.exp_year,
      cvc: this.state.card.cvc,
    };

    let queryString = Object.keys(card)
      .map((key) => 'card[' + key + ']=' + card[key])
      .join('&');

    apiStripe.setHeaders({
      Authorization: 'Bearer ' + REACT_APP_STRIPE_PUBLIC_KEY,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    apiStripe
      .post('/v1/tokens', queryString)
      .then((res) => {
        if (res.data.id && res.status === 200) {
          createSubscription(subscriptionId, res.data.id)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              toast.error(err.message);
            })
            .finally(() => this.setState(() => ({ loadingButton: false })));
        } else {
          toast.error(res.data.error.message);
          this.setState(() => ({ loadingButton: false }));
        }
      })
      .catch((err) => {
        toast.error('Something went wrong.');
      })
      .finally(() => this.setState(() => ({ loadingButton: false })));
  }

  render() {
    const { completedRedirect, card, loading, loadingButton } = this.state;
    if (completedRedirect) {
      return <Redirect push to={{ pathname: '/account//billing' }} />;
    }
    return (
      <div className="slim-content">
        <div className="container">
          <PageHeader
            title="Billing details"
            // rightButton={
            //   <Button color="link" onClick={() => this.handleLogout()}>
            //     Sign out
            //   </Button>
            // }
          />
          {loading && <LoadingPage />}
          {!loading && (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <FormGroup>
                <TextField
                  control="name"
                  onChange={(e) => this.handleChange(e)}
                  value={card.name}
                  label="Name on card"
                  validate={false}
                  maxLength={100}
                  required
                />
              </FormGroup>
              <FormGroup>
                <TextFieldCardMask
                  control="last4"
                  onChange={(e) => this.handleChange(e)}
                  placeholder="4666 6666 6666 6666"
                  label="Card number"
                  type="text"
                  value={card.last4}
                  validateInput={() => ValidatorService.validateInputCreditCard(card.last4, 19, 11)}
                  required
                />
              </FormGroup>
              <Row>
                <Col md={3}>
                  <FormGroup className="mb-30">
                    <TextField
                      control="exp_month"
                      onChange={(e) => this.handleChange(e)}
                      value={card.exp_month}
                      label="Expiry Month"
                      placeholder="MM"
                      validateInput={() => ValidatorService.validateInputMonthDate(card.exp_month)}
                      maxLength={2}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-30">
                    <TextField
                      control="exp_year"
                      onChange={(e) => this.handleChange(e)}
                      value={card.exp_year}
                      label="Expiry Year"
                      placeholder="YY"
                      validate={false}
                      maxLength={2}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-30">
                    <TextField
                      control="cvc"
                      onChange={(e) => this.handleChange(e)}
                      value={card.cvc}
                      label="CVC"
                      placeholder="123"
                      validate={false}
                      maxLength={4}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <ButtonLoading
                  loading={loadingButton}
                  label="Update details"
                  className="btn-main-shadowless"
                  disabled={loadingButton}
                />
              </Row>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

export default BillingEdit;
