import { isEmpty, parseInt, forEach, head, last, size } from 'lodash';
import React, { Fragment, PureComponent } from 'react';

import Datasheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { Row, Col, Button } from 'reactstrap';

import ButtonLoading from './ButtonLoading';

class CopyPaste extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      grid: [this.props.defaultRow, this.props.newRow({ rowNumber: 1 })],
    };
  }

  generateGrid(file) {
    const grid = [this.props.defaultRow.slice()];
    const array = file.split(/[\n]/);
    for (const index in array) {
      const data = array[index].split(/[\t]/);
      if (isEmpty(data)) {
        break;
      }
      const row = this.props.newRow({ rowNumber: parseInt(index) + 1, data });
      grid.push(row);
    }
    this.setState(() => ({ grid }));
  }

  pushNewRow(cellIndex, changes) {
    const grid = this.state.grid.slice();
    grid[head(changes).row][head(changes).col] = {
      ...grid[head(changes).row][head(changes).col],
      value: head(changes).value,
    };
    const emptyRow = this.props.newRow({ rowNumber: parseInt(cellIndex.value) + 1 });
    if (last(last(grid)).value) {
      grid.push(emptyRow);
    }
    this.setState({ grid });
  }

  handleSubmit() {
    const grid = this.state.grid.slice();
    grid.shift();
    this.props.onSubmit(grid);
  }

  handleChange(changes) {
    const grid = this.state.grid.slice();
    const gridFormatted = grid.map((row) => [...row]);
    if (changes && !isEmpty(changes)) {
      if (head(changes).value && head(changes).col === size(last(grid)) - 1) {
        return this.pushNewRow(head(last(gridFormatted)), changes);
      }
      forEach(changes, ({ row, col, value }) => {
        gridFormatted[row][col] = { ...gridFormatted[row][col], value };
      });
    }
    this.setState(() => ({ grid: gridFormatted }));
  }

  render() {
    const { grid } = this.state;
    const { loading, onBack, buttonText } = this.props;
    return (
      <Fragment>
        <Row className="mt-10">
          <Col sm={12}>
            <Datasheet
              data={grid}
              valueRenderer={(cell) => cell.value}
              onContextMenu={(e, cell) => (cell.readOnly ? e.preventDefault() : null)}
              parsePaste={(file) => this.generateGrid(file)}
              onCellsChanged={(changes) => this.handleChange(changes)}
            />
          </Col>
        </Row>
        <Row className="mt-30">
          <Col sm={6}>
            <Button outline className="btn-secondary" onClick={() => onBack()}>
              Back
            </Button>
          </Col>
          <Col sm={6}>
            <ButtonLoading
              onClick={() => this.handleSubmit()}
              loading={loading}
              label={buttonText}
              className="btn-main-shadowless"
              small
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default CopyPaste;
