import React from 'react';

import { Button, Col, Row, Container } from 'reactstrap';

import noResults from '../../_assets/img/icons/graphics/group@2x.png';
import ButtonLoading from '../commons/ButtonLoading';

const EmptyClientsPage = ({ addNewClientRedirect, importClientListRedirect }) => {
  return (
    <Container>
      <br />
      <Row className="justify-content-center">
        <img src={noResults} alt="No results" width="184" />
        <br />
        <br />
      </Row>
      <br />
      <Row>
        <Col sm={12}>
          <h4 style={{ fontSize: 28 }} className="text-center text-darkblue">
            Add a new client
          </h4>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row className="justify-content-center">
        <Col sm={6}>
          <ButtonLoading
            className="btn-main-shadowless mb-20"
            label="Add a single client"
            onClick={() => addNewClientRedirect()}
          />
        </Col>
      </Row>
      <Row className="text-center padding-20">
        <Col sm={12}>
          <Button
            color="link"
            className="text-center text-darkblue"
            onClick={() => importClientListRedirect()}
          >
            Or import a list
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default EmptyClientsPage;
