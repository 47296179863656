import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';

// replace with actual path
import { emptyInstrument } from 'emptyModels/IInstrumentEmpty';
import { IAdviceOutputAction } from 'interfaces/responses/adviserOutputs/IAdviceOutputAction';
import { IAdviserClientDto } from 'interfaces/responses/clients/IAdviserClientDto';
import { IGraphItem } from 'interfaces/responses/instrument/IGraphItem';
import { IInstrumentDto } from 'interfaces/responses/instrument/IInstrumentDto';
import { ISummaryDto } from 'interfaces/responses/instrument/ISummaryDto';
import { emptySummaryDto } from 'interfaces/responses/instrument/emptySummaryDto';
import { IReason } from 'interfaces/responses/reasons/IReasonsDto';
import { getClient } from 'service/ClientsService';
import { deleteCompareInstrument, addCompare } from 'service/CompareServices';
import { AdviserOutputService } from 'services/AdviserOutputService';
import { ClientsService } from 'services/ClientsService';
import { InstrumentService } from 'services/InstrumentService';
import { ReasonsService } from 'services/ReasonsService';
import { SummaryService } from 'services/SummaryService';

import setting from '../../../_assets/img/icons/coDetails/setting.png';
import chart from '../../../_assets/img/search/chart-simple-thin.png';
import seed from '../../../_assets/img/search/seedling-thin.png';
import { getInstrumentKey } from '../../../_helpers/instrument';
import { InstrumentType } from '../../../model/Instrument';
import {
  // getActionsByOutputId,
  // getLatestOutput,
  markActionForCompanyCard,
} from '../../../service/AdvisorOutputService';
import SustainabilityData from '../../clients/ClientDetails/Results/SustainabilityData';
import About from '../../company/About';
import AdviceOutputActions from '../../company/AdviceOutputActions';
// import EtfFundamentals from '../../company/EtfFundamentals';
// import FundamentalsTable from '../../company/FundamentalsTable';
import HoldingList from '../../company/HoldingList';
import InstrumentStatus from '../../company/InstrumentStatus';
import Personnel from '../../company/Personnel';
import PriceHistoryTable from '../../company/PriceHistoryTable';
import RelatedInvestments from '../../company/RelatedInvestments';
import RelatedIssues from '../../company/RelatedIssues';
import RiskAndReturnChart from '../../company/RiskAndReturnChart';
import Scores from '../../company/Scores';
import '../../research/company.scss';
import LoadingPage, { LoadingPageSmall } from '../LoadingPage';

import './Instrument.scss';

// import TabNavItem from './TabNavItem';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
// type RiskAndReturn = ReturnType<typeof Instrument.prototype.riskAndReturn>;

// type InstrumentsState = {
//   activeTab: string;
//   loading: Boolean;
//   latestOutput?: IAdviceOutput;
//   instrument?: IInstrumentDto;
//   instrumentId: string;
//   latestOutputActions: any;
//   markActionLoading: boolean;
//   disabledMarks: boolean;
//   showMessage: boolean;
//   instrumentGraph?: RiskAndReturn;
//   message?: string;
//   client?: any;
//   compareInstrumentIds: string[];
// };

// interface InstrumentProps {
//   instrumentId: string;
//   clientId: string;
// }

interface Dataset {
  label: string;
  fill: boolean;
  data: number[];
  lineTension: number;
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

interface GraphData {
  labels: string[];
  datasets: Dataset[];
}
type ReactSetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface InstrumentViewProps {
  instrument: IInstrumentDto;
  instrumentGraph?: GraphData;
  loading: boolean;
  clientCoreId: string;
  adviceOutputId?: string;
  compareInstrumentIds: string[];
  setCompareInstrumentIds: ReactSetState<string[]>;
  clientFirstName?: string;
  clientLastName?: string;
  tabTitle: string;
  setTabTitle: ReactSetState<string>;
  client?: IAdviserClientDto;
  setLatestOutputActions: ReactSetState<IAdviceOutputAction | undefined>;
  latestOutputActions?: IAdviceOutputAction;
  disabledMarks?: boolean;
  reasonsLoading: boolean;
  reasons: IReason[];
  summary: string;
  generationDate: string;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const Instrument = () => {
  const { clientId, instrumentId } = useParams();

  const [instrument, setInstrument] = useState<IInstrumentDto>(emptyInstrument);
  const [instrumentGraph, setInstrumentGraph] = useState<GraphData>();
  const [loading, setLoading] = useState(false);
  const [compareInstrumentIds, setCompareInstrumentIds] = useState<string[]>([]);
  const [tabTitle, setTabTitle] = useState<string>('');
  const [client, setClient] = useState<IAdviserClientDto>();
  // const [latestOutput, setLatestOutput] = useState<IAdviceOutput>();
  const [latestOutputActions, setLatestOutputActions] = useState<IAdviceOutputAction>();
  // const [, setMarkActionLoading] = useState<boolean>(false);
  const [disabledMarks] = useState<boolean>(false);
  const [reasons, setReasons] = useState<IReason[]>([]);
  const [reasonsLoading, setReasonsLoading] = useState<boolean>(false);
  const [clientFirstName, setClientFirstName] = useState<string>('');
  const [clientLastName, setClientLastName] = useState<string>('');
  const [adviceOutputId, setAdviceOutputId] = useState<string>('');
  const [summary, setSummary] = useState<ISummaryDto>(emptySummaryDto);
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (!instrumentId || typeof instrumentId !== 'string') {
      return;
    }

    setActiveTab(0);
    // Reset Summary as InstrumentId has changed
    setSummary(emptySummaryDto);

    const controller = new AbortController();
    const { signal } = controller;

    SummaryService.getInstrumentSummary(instrumentId, signal).then((res) => {
      if (res.instrumentId !== instrumentId) {
        return;
      }
      setSummary(res);
    });

    // Cleanup function to abort the fetch on component unmount or instrumentId change
    return () => {
      controller.abort();
    };
  }, [instrumentId]);

  useEffect(() => {
    getClient({ ClientCoreId: clientId }).then((res) => {
      setClient(res);
    });
  }, [clientId]);

  // useEffect(() => {
  //   getLatestOutput({ ClientCoreId: clientId })
  //     .then((response) => {
  //       // this.setState({ latestOutput: response.adviceOutput });
  //       setLatestOutput(response.adviceOutput);
  //       const adviseOutputId = response.adviceOutput.adviceOutputId;
  //       getActionsByOutputId({
  //         OutputId: adviseOutputId,
  //       }).then((actionsResponse) => {
  //         const actions = actionsResponse.adviceOutputActions.filter(
  //           (adviceOutputAction: any) => adviceOutputAction.instrumentId === instrumentId,
  //         );
  //         const latestAction = actions.length ? actions[0] : { adviceOutputActionId: null };
  //         // this.setState({ latestOutputActions: latestAction, markActionLoading: false });
  //         setLatestOutputActions(latestAction);
  //         setMarkActionLoading(false);
  //       });
  //     })
  //     .catch(() => {
  //       setDisabledMarks(true);
  //       setLoading(false);
  //     });
  // }, [instrumentId, clientId]);
  useEffect(() => {
    setLoading(true);

    InstrumentService.getInstance()
      .getInstrumentDetails(instrumentId, clientId)
      .then((res) => {
        setInstrument(res);
        // setTabTitle(res); // You need to implement this function if necessary
        setLoading(false);
      });
  }, [instrumentId, clientId]);

  useEffect(() => {
    setReasonsLoading(true);

    InstrumentService.getInstance()
      .getInstrumentGraph(instrumentId)
      .then((result) => {
        setInstrumentGraph(riskAndReturn(result.graphItems, result.yearRange));
      });

    ReasonsService.getInstance()
      .getReasons(instrumentId)
      .then((result) => {
        setReasons(result.reasons);
        setReasonsLoading(false);
      });
  }, [instrumentId]);

  useEffect(() => {
    ClientsService.getInstance()
      .getClient(clientId)
      .then((result) => {
        setClientFirstName(result.clientFirstName);
        setClientLastName(result.clientLastName);
      });

    AdviserOutputService.getInstance()
      .getLatestOutput(clientId)
      .then((result) => {
        setAdviceOutputId(result.adviceOutputId);
      });
  }, [clientId]);

  const riskAndReturn = (graphItems: IGraphItem[], yearRange: number): GraphData | undefined => {
    if (!graphItems || graphItems.length === 0) {
      return;
    }

    const labels: string[] = [];
    const values: number[] = [];
    const sortedData = [...graphItems].reverse();

    sortedData.forEach((item: IGraphItem) => {
      labels.push(new Date(item.date).toLocaleDateString());
      values.push(item.price);
    });

    const data: GraphData = {
      labels: labels,
      datasets: [
        {
          label: `${yearRange} years`,
          fill: false,
          data: values,
          lineTension: 0.2,
          backgroundColor: 'rgba(123,224,211,0.5)',
          borderColor: '#3E61DF',
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  // Render your component
  return (
    <div>
      <InstrumentView
        instrument={instrument}
        instrumentGraph={instrumentGraph}
        loading={loading}
        adviceOutputId={adviceOutputId}
        clientCoreId={clientId}
        clientFirstName={clientFirstName}
        clientLastName={clientLastName}
        compareInstrumentIds={compareInstrumentIds}
        setCompareInstrumentIds={setCompareInstrumentIds}
        setTabTitle={setTabTitle}
        tabTitle={tabTitle}
        client={client}
        latestOutputActions={latestOutputActions}
        setLatestOutputActions={setLatestOutputActions}
        disabledMarks={disabledMarks}
        reasonsLoading={reasonsLoading}
        reasons={reasons}
        summary={summary.summary}
        generationDate={summary.date}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

export default Instrument;

// const Fundamentals = ({ instrument }: { instrument: IInstrumentDto }) => {
//   if (!instrument) return <></>;
//   switch (instrument.type) {
//     case InstrumentType.Company:
//       return <FundamentalsTable {...instrument.fundamentals} />;
//     case InstrumentType.ETF:
//       return <EtfFundamentals {...instrument.etfFundamentals} />;
//     default:
//       return <></>;
//   }
// };

function InstrumentView({
  instrument,
  instrumentGraph,
  loading,
  clientCoreId,
  adviceOutputId,
  compareInstrumentIds,
  setCompareInstrumentIds,
  clientFirstName,
  clientLastName,
  // tabTitle,
  // setTabTitle,
  // setActiveTab,
  latestOutputActions,
  setLatestOutputActions,
  disabledMarks,
  // client,
  reasonsLoading,
  reasons,
  summary,
  generationDate,
  activeTab,
}: InstrumentViewProps) {
  const history = useHistory();
  // const InstrumentView: React.FC<InstrumentViewProps> = ({ instrument, instrumentGraph, loading }) => {
  // const [activeTab, setActiveTab] = useState<IInstrumentDto>(emptyInstrument);

  // state: InstrumentsState = {
  //   activeTab: tabsData[0].id,
  //   latestOutputActions: {},
  //   markActionLoading: false,
  //   disabledMarks: false,
  //   showMessage: true,
  //   instrumentId: '',
  //   compareInstrumentIds: [],
  // };

  // const [tabTitle, setTabTitle] = useState<string>('');
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  // const [tabsData] = useState([
  //   {
  //     id: '1',
  //     title: 'Sustainability',
  //   },
  //   {
  //     id: '2',
  //     title: 'Holdings',
  //   },
  // ]);

  // useEffect(() => {
  //   setTabsData((prev) => {
  //     return prev.map((item, index) => {
  //       if (index === 0) {
  //         return {
  //           ...item,
  //           title: 'Sustainability',
  //         };
  //       }
  //       if (index === 1) {
  //         return {
  //           ...item,
  //           title:
  //             instrument.type === 'fund' || instrument.type === 'super' || instrument.type === 'etf'
  //               ? 'Holdings'
  //               : 'Financials',
  //         };
  //       }
  //       return (item = { ...item });
  //     });
  //   });
  // }, [instrument.type]);

  type ActionStatus = 'add' | 'retain' | 'remove' | 'review';
  const markAction = (actionStatus: ActionStatus) => {
    setActionLoading(true);
    let actionStatusNumber = 0;
    switch (actionStatus) {
      case 'add':
        actionStatusNumber = 1;
        break;
      case 'retain':
        actionStatusNumber = 2;
        break;
      case 'remove':
        actionStatusNumber = 3;
        break;
      case 'review':
        actionStatusNumber = 4;
        break;
      default:
        break;
    }

    const params = {
      adviceOutputId: adviceOutputId,
      clientCoreId: clientCoreId,
      adviceOutputActionId: latestOutputActions?.adviceOutputActionId,
      InstrumentId: instrument.instrumentId,
      actionStatus: actionStatusNumber,
    };
    markActionForCompanyCard(params)
      .then((response) => {
        setLatestOutputActions(response.adviceOutputAction);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  // const makeAsAdd = () => {
  //   markAction(1);
  // };

  // const makeAsRetain = () => {
  //   markAction(2);
  // };

  // const makeAsRemove = () => {
  //   markAction(3);
  // };

  // const makeAsReview = () => {
  //   markAction(4);
  // };

  const renderContent = () => {
    return (
      <div className="d-inline flex-grow-1">
        {renderBackLink()}
        <div className="instrument pl-4 pr-4">
          {renderHeader()}
          {renderTabs()}
          <div>
            {renderSustainability()}
            {renderFinancialsOrHoldings()}
          </div>
        </div>
      </div>
    );
  };

  // const renderFundamental = () => {
  //   if (!instrument) return;
  //   switch (instrument.type) {
  //     case InstrumentType.Company:
  //       return <FundamentalsTable {...instrument.fundamentals} />;
  //     case InstrumentType.ETF:
  //       return <EtfFundamentals {...instrument.etfFundamentals} />;
  //     default:
  //       return null;
  //   }
  // };

  const toggleCompareClick = () => {
    if (!compareInstrumentIds.includes(instrument.instrumentId)) {
      addCompare(instrument.instrumentId, clientCoreId)
        .then((response: any) => {
          // this.setState({ compareInstrumentIds: response.instrumentIds });
          setCompareInstrumentIds(response.instrumentIds);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      deleteCompareInstrument(instrument.instrumentId, clientCoreId)
        .then((response: any) => {
          // this.setState({ compareInstrumentIds: response.instrumentIds });
          setCompareInstrumentIds(response.instrumentIds);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const renderHeader = () => {
    // const {
    //   instrument,
    //   client,
    //   markActionLoading,
    //   disabledMarks,
    //   latestOutputActions,
    //   compareInstrumentIds,
    // } = this.state;
    const isSelectedForCompare = instrument
      ? compareInstrumentIds.includes(instrument.instrumentId)
      : false;
    const compareCount = compareInstrumentIds.length;
    console.log(instrument);
    return (
      <Row className="pt-3 pb-4">
        <Col className="ml-30">
          <Row>
            <Col className="flex-grow-0">
              <img src={instrument?.logo} className="company-icon" width="67" height="67" />
            </Col>
            <Col>
              <Row>
                <h4>
                  {instrument ? getInstrumentKey(instrument.type) : ''} score card
                  {instrument?.isInApprovedProductList ? (
                    <span style={{ height: '15px', width: '15px' }} className="apl-tag pl-1 ml-3">
                      APL
                    </span>
                  ) : (
                    ''
                  )}
                  {!isEmpty(instrument?.sustainabilityData) ? (
                    <img src={seed} height="15px" className="ml-10" />
                  ) : (
                    ''
                  )}
                  {!isEmpty(instrument?.fundamentals) ? (
                    <img src={chart} height="15px" className="ml-10" />
                  ) : (
                    ''
                  )}
                </h4>
              </Row>
              <Row>
                <h4 className="mb-0">
                  {instrument?.name ?? 'Instrument Name'} &nbsp;
                  {instrument?.symbol ?? 'Instrument Symbol'}
                </h4>
              </Row>
            </Col>
            {instrument?.about?.industry && (
              <Col className="mt-20">
                <img src={setting} alt="laptop" width="40" className="mr-10" />
                {`${instrument.about?.industry} Industry`}
              </Col>
            )}
            <div style={{ marginTop: '25px' }}>
              <p className="checkbox-header" style={{ top: '0' }}>
                Compare
              </p>
              {compareCount !== 0 ? (
                <div className="checkbox-count" style={{ top: '-12px' }}>
                  {compareCount}
                </div>
              ) : null}
              <label className="form-control-checkbox">
                <input
                  type="checkbox"
                  name="checkbox"
                  className="research-checkbox"
                  checked={isSelectedForCompare ? true : false}
                  onClick={toggleCompareClick}
                />
              </label>
            </div>
          </Row>
        </Col>
        <Col className="flex-grow-0">
          {/* {!isEmpty(client) && ( */}
          <AdviceOutputActions
            disabledMarks={disabledMarks}
            makeAsAdd={() => markAction('add')}
            makeAsRetain={() => markAction('retain')}
            makeAsRemove={() => markAction('remove')}
            makeAsReview={() => markAction('review')}
            inPortfolio={latestOutputActions?.isInPortfolio}
            currentActionStatus={latestOutputActions?.actionStatus}
            markActionLoading={actionLoading}
          />
          {/* )} */}
        </Col>
      </Row>
    );
  };

  const renderFinancialsOrHoldings = () => {
    return (
      <div className={activeTab === 1 ? '' : 'd-none'}>{renderFinancialsOrHoldingsContent()}</div>
    );
  };

  const renderFinancialsOrHoldingsContent = () => {
    return (
      <>
        {instrument?.type !== InstrumentType.Fund &&
          instrument?.type !== InstrumentType.Super &&
          instrument?.type !== InstrumentType.ETF && (
            <Row className="mb-30">
              <Col sm={6} className="pr-0 d-flex flex-column">
                <div className={`panel mb-30`}>
                  <About about={instrument?.about} />
                </div>
                <div className="panel mb-30">
                  <Personnel {...instrument?.personnel} />
                </div>
                <div className="panel">
                  <PriceHistoryTable {...instrument?.priceHistory} />
                </div>
              </Col>
              <Col sm={6} className="d-flex flex-column">
                <div
                  style={{
                    height: '120%',
                  }}
                  className="panel mb-30"
                >
                  <RiskAndReturnChart instrumentGraph={instrumentGraph} />
                </div>
                <div className="panel">{/* <Fundamentals instrument={instrument} /> */}</div>
              </Col>
            </Row>
          )}
        <Row className="ml-0 mr-0 pb-4">
          <HoldingList holdings={instrument?.holdings} showMatch={true} />
        </Row>
      </>
    );
  };

  const renderSustainability = () => {
    return (
      <div className={activeTab === 0 ? '' : 'd-none'}>
        <Row className="mr-0 mb-30">
          <Col style={{ paddingLeft: '0' }} className="d-flex flex-column">
            <Scores
              logoUrl={instrument?.logo}
              instrumentName={instrument?.name}
              scores={instrument?.scores}
              grade={instrument?.grade}
              summary={summary}
              generationDate={generationDate}
            />
            <div className="panel">
              <SustainabilityData {...instrument?.sustainabilityData} />
            </div>
          </Col>
          <Col style={{ paddingRight: '0' }} className="d-flex flex-column">
            {instrument
              ? instrument.matchToValuesStatus !== 3 && (
                  <div className="panel mb-30 flex-grow-1">
                    <InstrumentStatus
                      conflicts={instrument.conflicts}
                      reasons={reasons}
                      reasonsLoading={reasonsLoading}
                      matches={instrument.matches}
                      instrumentName={instrument.name}
                      clientFirstName={clientFirstName}
                      matchToValuesStatus={instrument.matchToValuesStatus}
                      dataLastUpdated={instrument.sustainabilityData.dataLastUpdated}
                    />
                  </div>
                )
              : ''}

            <div className="panel related-issues-nodata">
              <h4 className="title-score">Related Issues</h4>
              {reasonsLoading ? (
                <LoadingPageSmall />
              ) : (
                <RelatedIssues
                  reasons={reasons}
                  relatedIssues={instrument?.relatedIssues}
                  instrumentName={instrument?.name}
                  dataLastUpdated={instrument?.sustainabilityData.dataLastUpdated}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row className="ml-0 mr-0 pb-4">
          {instrument.type === 'fund' ||
          instrument.type === 'super' ||
          instrument.type === 'etf' ? (
            <HoldingList holdings={instrument?.holdings} showMatch={true} />
          ) : (
            <RelatedInvestments
              relatedInvestments={instrument?.relatedInvestments}
              clientName={`${clientFirstName} ${clientLastName}`}
              showMatch={true}
            />
          )}
        </Row>
      </div>
    );
  };

  const renderBackLink = () => {
    return (
      <div>
        <Col sm={6}>
          <Button
            color="link"
            onClick={() => history.goBack()}
            className="font-14 text-darkblue pt-3 pb-3 pl-2 no-underline"
          >
            {'< Back to client list'}
          </Button>
        </Col>
      </div>
    );
  };

  const renderTabs = () => {
    // if (instrument.type === 'fund' || instrument.type === 'super' || instrument.type === 'etf') {
    //   return <></>;
    // }
    // return (
    //   <div className="tabs pt-2 pb-2 mb-30">
    //     <ul className="d-flex justify-content-around m-0">
    //       {tabsData.map((item, key) => {
    //         return (
    //           <TabNavItem
    //             key={key}
    //             id={key}
    //             title={item.title}
    //             activeTab={activeTab}
    //             setActiveTab={setActiveTab}
    //           />
    //         );
    //       })}
    //     </ul>
    //   </div>
    // );
    return <></>;
  };

  return <>{loading || isEmpty(instrument) ? <LoadingPage /> : renderContent()}</>;
}
