export enum FundStatus {
  'Profile Match' = 1,
  'Profile Conflict',
  'Non Match',
}

export enum TileType {
  SustainabilityOverview = 'portfolioSustainabilityOverview',
  ProfileMatches = 'profileMatches',
  ProfileConflicts = 'profileConflicts',
  ProfileAlternatives = 'profileAlternatives',
  PortfolioMatches = 'portfolioMatches',
  PortfolioConflicts = 'portfolioConflicts',
  PortfolioAlternatives = 'portfolioAlternatives',
  AplMatches = 'aplMatches',
  AplConflicts = 'aplConflicts',
  AplAlternatives = 'aplAlternatives',
}
