import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Container, Row, Button } from 'reactstrap';

import { resetStatusEnum } from '../../../_constants';
import { resetPasswordWithCode } from '../../../service/PasswordService';
import ValidatorService from '../../../service/validator';

import ResetPasswordForm from './ResetPasswordForm';
import './index.scss';

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      resetStatus: resetStatusEnum.RESET_PASSWORD,
      email: '',
      password: '',
      loading: false,
      code: null,
      passwordError: null,
      status: null,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    const { code, email, password, passwordError } = this.state;
    this.setState(() => ({ loading: true }));
    let params = {
      resetPasswordCode: code,
      newPassword: password,
      email: email,
    };
    if (!passwordError) {
      resetPasswordWithCode(params)
        .then(() => {
          this.setState(() => ({ resetStatus: resetStatusEnum.SUCCEEDED, loading: false }));
        })
        .catch((err) => {
          this.setState({ showMessage: true, message: err.message, loading: false });
          toast.error(err.message);
        });
    }
  }

  onChangeCode({ target: { value } }) {
    this.setState(() => ({ code: value }));
  }

  onChangeEmail({ target: { value } }) {
    this.setState(() => ({ email: value }));
  }

  onChangePassword({ target: { value } }) {
    this.setState(() => ({ password: value }));
    const error = ValidatorService.passwordFeedback(value);
    this.setState(() => ({ passwordError: error }));
  }

  render() {
    const { resetStatus, password, passwordError, loading } = this.state;
    return (
      <Container>
        {resetStatus === resetStatusEnum.RESET_PASSWORD && (
          <Fragment>
            <Row className="wizard-top-title justify-content-center mb-40 mt-40">
              <div>
                <h3 className="font-weight-bold">Reset Password</h3>
              </div>
            </Row>
            <ResetPasswordForm
              onSubmit={(e) => this.handleSubmit(e)}
              onChangePassword={(e) => this.onChangePassword(e)}
              onChangeCode={(e) => this.onChangeCode(e)}
              onChangeEmail={(e) => this.onChangeEmail(e)}
              {...{
                resetStatus,
                password,
                passwordError,
                loading,
              }}
            />
          </Fragment>
        )}
        {resetStatus === resetStatusEnum.SUCCEEDED && (
          <Fragment>
            <Row className="wizard-top-title justify-content-center mb-40">
              <h2>Password updated!.</h2>
            </Row>
            <Row className="justify-content-center">
              <Button className="btn-main" onClick={() => this.props.history.push('/')}>
                Start using Oko Adviser
              </Button>
            </Row>
          </Fragment>
        )}
      </Container>
    );
  }
}

export default ResetPassword;
