import { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { Button, Container, Row, Col } from 'reactstrap';

import { isApiAuthenticated } from 'config/api';
import { postCustomerUrl, getConnectedStatus } from 'service/SendXplanCodeService';
import ValidatorService from 'service/validator';

import cameraIcon from '../../_assets/img/icons/nav/Camera@3x.png';
import tipIcon from '../../_assets/img/icons/profile/Bulb@1.5x.png';
import { PageHeader } from '../commons';
import ButtonLoading from '../commons/ButtonLoading';
import TextField from '../commons/TextField';

const { REACT_APP_XPLAN_CLIENT_ID, REACT_APP_XPLAN_RE_DIRECT_URI } = process.env;

const Settings = (props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [requestBody, setRequestBody] = useState({});

  useEffect(() => {
    if (isApiAuthenticated()) {
      getConnectedStatus()
        .then((response) => {
          setRequestBody({ ...response });
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  const handleChange = (e) => {
    setRequestBody({ ...requestBody, xplanCustomerUrl: e.target.value });
    const isValidate = ValidatorService.validateUrl(e.target.value);
    setIsDisabled(!isValidate);
  };

  const handleSubmit = () => {
    const clientID = REACT_APP_XPLAN_CLIENT_ID;
    const redirectURI = REACT_APP_XPLAN_RE_DIRECT_URI;
    const path = `${requestBody.xplanCustomerUrl}/oauth2/auth?client_id=${clientID}&response_type=code&redirect_uri=${redirectURI}`;
    postCustomerUrl(requestBody)
      .then(() => {
        window.open(path, '_blank');
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="slim-content" style={{ maxWidth: '710px' }}>
      <Container style={{ maxWidth: '710px' }}>
        <PageHeader title="Settings" />
        <Row style={{ marginBottom: '200px' }}>
          <Row style={{ padding: '10px 15px', alignItems: 'center', position: 'relative' }}>
            <p className="subtitle" style={{ margin: '0' }}>
              Paste your unique Xplan URL
            </p>
            <img
              src={cameraIcon}
              onClick={() => window.open('https://www.youtube.com/watch?v=bpqk67vRHE8', '_blank')}
              style={{
                transform: 'scale(0.3)',
                position: 'absolute',
                left: '235px',
                top: '1px',
                cursor: 'pointer',
              }}
            />
          </Row>
          <Row>
            <Col xs={8}>
              <TextField
                onChange={handleChange}
                value={requestBody.xplanCustomerUrl}
                placeholder="https://EXAMPLE.xplan.iress.com.au"
                validateInput={(url) => ValidatorService.validateUrl(url)}
                required
              />
            </Col>
            <Col xs={4}>
              <ButtonLoading
                className="browser-button"
                label="Save and Connect"
                disabled={isDisabled}
                onClick={handleSubmit}
                small
              />
            </Col>
            <Col xs={12}>
              <div className="tips-panel mt-10">
                <img
                  src={tipIcon}
                  alt="icon"
                  className="tip-icon"
                  style={{ marginRight: '10px' }}
                />
                In your browser navigate to your usual Xplan login page. This is your unique URL.
              </div>
            </Col>
          </Row>
        </Row>
        <Row
          className="justify-content-center mt-100"
          style={{ flexDirection: 'column', alignItems: 'center' }}
        >
          <div style={{ border: '1px solid #e7e7e7', width: '90%', marginBottom: '10px' }} />
          <Col sm={11} className="d-flex justify-content-center font-weight-bolder">
            <p style={{ color: '#2e3074', fontSize: '17px', wordSpacing: '1.5px' }}>
              Looking for another integration? <a href="/assistance/support">Contact us</a> and
              we'll work directly with the platform to get this set up.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
