import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container, Button, Col, Row } from 'reactstrap';

import uploadIcon from '../../_assets/img/icons/client/upload@3x.png';

const UploadAPLPortfolio = (props: any) => {
  const history = useHistory();

  return (
    <Container>
      <Row className="justify-content-center mt-80 mb-40">
        <img src={uploadIcon} alt="laptop" width="180" />
      </Row>
      <Row className="justify-content-center mb-40">
        <p style={{ fontSize: '17px' }}>Add an existing APL file to be analysed</p>
        <Col sm={12} className="d-flex justify-content-center mt-20 mb-20">
          <Button onClick={() => props.addAPLRedirect()} className="btn-main-shadowless">
            {`Upload an APL file`}
          </Button>
        </Col>
        <a onClick={() => history.push(`/clients`)} style={{ cursor: 'pointer', fontSize: '17px' }}>
          Back to client list
        </a>
      </Row>
    </Container>
  );
};

export default UploadAPLPortfolio;
