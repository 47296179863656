import { IInstrumentSmallDto } from 'interfaces/responses/instrument/IInstrumentSmallDto';

export interface InstrumentInterface {
  id: string;
  type: string;
  symbol: string;
  name: string;
  logoUrl: string;
  sector: string;
  return: string;
  planetScore: string;
  communityScore: string;
  employeesScore: string;
  ethicsScore: string;
  holdingPercentage: string;
  isInApprovedProductList: boolean;
  matchToValuesStatus: number;
  grade: GradeInterface;
}
export interface GradeInterface {
  gradeValue: string;
  gradeLevel: number;
}
export interface AffectedInstrument {
  communityScore: string | undefined;
  employeeScore: string | undefined;
  ethicsScore: string | undefined;
  grade: {} | null;
  holdingPercentage: string | undefined;
  id: string | undefined;
  isInApprovedProductList: boolean;
  logoUrl: string | undefined;
  matchToValuesStatus: number | undefined;
  name: string | undefined;
  planetScore: string | undefined;
  return: string | undefined;
  sector: string | undefined;
  symbol: string | undefined;
  type: string | undefined;
}
export interface MatchConflictItem {
  code: string;
  comment: string;
  title: string;
  icon: string;
  iconUrl: string;
  reason: string;
  affectedInstruments: IInstrumentSmallDto[] | undefined | null;
  standardDescription: string;
  generationDate: string;
}

export enum InstrumentType {
  Company = 'company',
  ETF = 'etf',
  Fund = 'fund',
  Super = 'super',
}

export interface Instrument {
  code: string;
  comment: string;
  icon: string;
  iconUrl: string;
  id: string;
  instrumentId: string;
  title: string;
}

export interface IToolTip {
  instrumentName: string;
  instrumentId: string;
}
