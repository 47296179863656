export class ApiEndpoints {
  // public static get UpdateAdviserUserPracticeType(): string {
  //   return "/api/accounts/UpdateAdviserUserPracticeType";
  // }

  // Instruments
  public static GetInstrument(instrumentId: string, clientId: string): string {
    return `/v2/Instrument/${instrumentId}/${clientId}`;
  }

  public static GetInstrumentGraph(instrumentId: string): string {
    return `/v2/Instrument/${instrumentId}/Graph`;
  }

  public static GetInstrumentSummary(instrumentId: string): string {
    return `/v1/Summary/${instrumentId}`;
  }

  // Advisor Outputs
  public static get GetAdviserOutputActions(): string {
    return "'/api/AdviserOutput/GetActions'";
  }

  public static get GetAllOutputs(): string {
    return '/api/AdviserOutput/GetAllOutputs';
  }

  public static get CreateAdviserOutput(): string {
    return `/v2/AdviserOutput/CreateOutput`;
  }

  public static CreateAdviceOutput(id: string): string {
    return `/v2/advice/create/${id}`;
  }

  public static AdviceOutput(id: string): string {
    return `/v2/advice/output/${id}`;
  }

  public static get GetOutputById(): string {
    return '/api/AdviserOutput/GetOutputById';
  }

  public static get GetMeetingNotes(): string {
    return '/api/AdviserOutput/GetMeetingNotes';
  }

  public static get GetSupported(): string {
    return '/api/AdviserOutput/GetSupported';
  }

  public static get GetAdviceConflicts(): string {
    return '/api/AdviserOutput/GetConflicts';
  }

  public static get DuplicateAdviceOutput(): string {
    return '/api/AdviserOutput/DuplicateAdviceOutput';
  }

  public static get CompleteAdviceOutput(): string {
    return 'api/AdviserOutput/CompleteAdviceOutput';
  }

  public static GetLatestOutput(clientId: string): string {
    return `/v1/AdviserOutput/GetLatestOutput/${clientId}`;
  }

  public static get GetActions(): string {
    return '/api/AdviserOutput/GetActions';
  }

  public static get CreateOrUpdateActionForOutcome(): string {
    return '/api/AdviserOutput/CreateOrUpdateActionForOutcome';
  }

  // Actions
  public static get CreateOrUpdateActionForCompanyCard(): string {
    return '/api/AdviserOutput/CreateOrUpdateActionForCompanyCard';
  }

  // Results
  public static get GetResultsCount(): string {
    return '/v2/results/count';
  }

  public static get GetResults(): string {
    return '/v2/results/';
  }

  // Settings
  public static get Settings(): string {
    return '/v2/settings';
  }

  // Adviser
  public static get GetAdviserDetails(): string {
    return '/v2/accounts/GetAdviserDetails';
  }

  public static get AccountAdviser(): string {
    return '/v2/account/adviser';
  }

  // Approved Product List
  public static get ApprovedProductList(): string {
    return '/v2/ApprovedProductList';
  }

  public static GetApprovedProductList(id: string): string {
    return `/v2/ApprovedProductList/${id}`;
  }

  // Client Details
  public static get GetAllClients(): string {
    return '/api/Client/GetAllClients';
  }

  public static get GetClient(): string {
    return '/api/Client/GetClient';
  }

  public static get AddClient(): string {
    return '/api/Client/AddClient';
  }

  public static get AddClientBulk(): string {
    return '/api/Client/AddClientsBulk';
  }

  public static get UpdateClient(): string {
    return '/api/Client/UpdateClient';
  }

  public static Client(clientId: string): string {
    return `/v1/Client/${clientId}`;
  }

  // Compare
  public static get Compare(): string {
    return '/v2/compare';
  }

  public static CompareByClientId(clientId: string) {
    return `/v2/compare/${clientId}`;
  }

  public static CompareCountByClientId(clientId: string) {
    return `/v2/compare/count/${clientId}`;
  }

  public static CompareInstrument(clientId: string, instrumentId: string) {
    return `/v2/compare/${clientId}/${instrumentId}`;
  }

  public static DeleteAllCompareInstruments(clientId: string) {
    return `/v2/compare/deleteAll/${clientId}`;
  }

  // Insights
  public static get InsightsTotalPreferences(): string {
    return '/api/insights/totalPreferences';
  }

  public static get InsightsTop5Preferences(): string {
    return '/api/insights/Top5Preferences';
  }

  public static get InsightsTotalExclusions(): string {
    return '/api/insights/totalExclusions';
  }

  public static get InsightsTop5Exclusions(): string {
    return '/api/insights/Top5Exclusions';
  }

  public static get InsightsMostMatchedCompanies(): string {
    return '/api/insights/MostMatchedCompanies';
  }

  public static get InsightsMostConflictCompanies(): string {
    return '/api/insights/MostConflictCompanies';
  }

  public static get InsightsMostRemovedCompanies(): string {
    return '/api/insights/MostRemoved';
  }

  public static get InsightsAdviceActivity(): string {
    return '/api/Insights/AdviceActivityInsights';
  }

  public static get InsightsTotalAverage(): string {
    return '/api/insights/TotalAverages';
  }

  public static get InsightsAverageActions(): string {
    return '/api/insights/AverageActions';
  }

  // Payments
  public static get GetStripeCardAccount(): string {
    return '/api/Payments/GetStripeCardAccount';
  }

  public static get CurrentSubscription(): string {
    return '/api/Payments/CurrentSubscription';
  }

  // Portfolio Service
  public static get GetAllClientPortfolios(): string {
    return '/api/Portfolio/GetAllClientPortfolios';
  }

  public static get DeleteClientPortfolio(): string {
    return '/api/Portfolio/DeleteClientPortfolio';
  }

  public static GetClientPortfolio(clientPortfolioId: string) {
    return `/v2/Portfolio/${clientPortfolioId}`;
  }

  public static get AddClientPortfolioBulk(): string {
    return '/api/Portfolio/AddClientPortfolioBulk';
  }

  public static get AddClientsPortfolioBulk(): string {
    return '/api/Portfolio/AddClientsPortfolioBulk';
  }

  // Sustainability Scores
  public static GetSustainabilityScores(clientId: string) {
    return `/v2/portfolio/sustainabilityscores/${clientId}`;
  }

  // Password
  public static get ForgotPassword(): string {
    return '/api/user/forgotPassword';
  }

  public static get ResetPasswordWithCode(): string {
    return '/api/user/resetpasswordwithcode';
  }

  // Questions
  public static get GetLifeStyleQuestions(): string {
    return '/api/profile/questions/lifestyle';
  }

  public static get GetBehaviourDetails(): string {
    return '/api/Profiling/BehaviourDetails';
  }

  public static get GetCountryDetails(): string {
    return '/api/profile/countries';
  }

  public static get GetSoftQuestions(): string {
    return '/api/profile/questions/soft';
  }

  public static GetReasons(instrumentId: string): string {
    return `/v1/Reasons/${instrumentId}`;
  }

  // Profile
  public static get PostProfile(): string {
    return '/api/profile';
  }

  // Profiling
  public static get GetPreferenceQandA(): string {
    return '/api/Profiling/GetPreferenceQandA';
  }

  public static get GetExclusionQandA(): string {
    return '/api/Profiling/GetExclusionQandA';
  }

  public static get GetChoiceModellingQandA(): string {
    return '/api/Profiling/GetChoiceModellingQandA';
  }

  public static get GetProfileMatches(): string {
    return '/api/Profiling/GetProfileMatches';
  }

  public static get CreateUpdateProfile(): string {
    return '/api/Profiling/CreateUpdateProfile';
  }

  public static get GetActiveProfileByClientId() {
    return `/api/Profiling/GetActiveProfileByClientId`;
  }

  // Share codes

  public static get ValidateShareCode(): string {
    return '/api/user/ValidateShareCode';
  }

  public static get GetMyShareCode(): string {
    return '/api/user/GetMyShareCode';
  }

  public static get CreateShareCodeTransaction(): string {
    return '/api/user/CreateShareCodeTransaction';
  }
}
