import { Bar } from 'react-chartjs-2';
import { Row } from 'reactstrap';

import ChartDataLabels from 'chartjs-plugin-datalabels';

interface MatchesConflictsState {
  isLoading: boolean;
  portfolioConflicts: number[];
  portfolioMatches: number[];
  profileConflicts: number[];
  profileMatches: number[];
  note: string;
}

export default function InsightsMatchesConflicts(props: MatchesConflictsState) {
  const data1 = [
    props.portfolioMatches[0],
    props.portfolioConflicts[0],
    props.profileMatches[0],
    props.profileConflicts[0],
  ];
  const data2 = [
    props.portfolioMatches[1],
    props.portfolioConflicts[1],
    props.profileMatches[1],
    props.profileConflicts[1],
  ];

  const labels = [
    'Portfolio Matches',
    'Portfolio Conflicts',
    'Profile Matches',
    'Profile Conflicts',
  ];
  const data = {
    labels,
    datasets: [
      {
        label: 'Data 1',
        data: labels.map((label, index) => data1[index]),
        backgroundColor: labels.map((label, index) => (index % 2 == 1 ? '#FFE5EA' : '#A7D9C5')),
        barPercentage: 0.8,
      },
      {
        label: 'Data 2',
        data: labels.map((label, index) => data2[index]),
        backgroundColor: labels.map((label, index) => (index % 2 == 1 ? '#FFE5EA' : '#A7D9C5')),
        barPercentage: 0.8,
      },
    ],
  };
  return (
    <div className="advice-panel">
      <Row style={{ marginTop: 17 }}>
        <Bar
          options={{
            plugins: {
              legend: { display: false },
              tooltip: {
                enabled: false,
              },
              datalabels: {
                display: true,
                color: '#fff',
                align: 'bottom',
                anchor: 'end',
                font: {
                  size: 12,
                },
              },
            },
            scales: {
              yAxes: {
                display: false,
                ticks: {
                  maxTicksLimit: 15,
                },
                grid: {
                  display: false,
                },
              },

              xAxes: {
                grid: {
                  display: false,
                },
              },
            },
          }}
          data={data}
          plugins={[ChartDataLabels]}
        />
        <div className="dash-line" />
      </Row>
      <Row>
        <p className="advice-panel-note">
          <b style={{ fontWeight: '900', fontSize: 10 }}>Note:</b> {props.note}
        </p>
      </Row>
    </div>
  );
}
