import React, { PureComponent } from 'react';

import { toast } from 'react-toastify';
import { Row, Col, Button } from 'reactstrap';

import update from 'immutability-helper';

import eye from '../../../../../_assets/img/advice/eye-solid.png';
import {
  completeAdviceOutput,
  createAdviceOutput,
  getAdviceOutput,
} from '../../../../../service/AdvisorOutputService';
import { ButtonLoading, LoadingPage } from '../../../../commons';
import SectionTitle from '../../../../commons/SectionTitle';

import ClientAdviserAction from './ClientAdviserActions';
import ConflictingInstruments from './ConflictingInstruments';
import DateTime from './DateTime';
import MatchingInstrumentsV2 from './MatchingInstrumentsV2';
import PreferencesAndExclusions from './PreferencesAndExclusions';

export default class AdviceOutput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: undefined,
      meetingDate: '',
      meetingTime: '',
      isAm: true,
      meetingNote: '',
      loading: false,
      loadingButton: false,
      isEditable: false,
    };
  }

  async componentDidMount() {
    this.setState(() => ({ loading: true }));
    const { id, outputid } = this.props.match.params;
    console.log(id, outputid);
    if (!outputid) {
      await createAdviceOutput(id)
        .then((res) => {
          console.log(res);
          this.fetchData(res.outputId);
        })
        .catch((err) => {
          toast.error(err.message);
          this.setState({ showMessage: true, message: err.message, loading: false });
        });
    } else {
      this.fetchData(outputid);
    }
  }

  fetchData(outputId) {
    getAdviceOutput(outputId)
      .then((res) => {
        console.log(res);
        this.setState({
          data: res,
          meetingNote: res.meetingNote,
          isEditable: res.isEditable,
          loading: false,
        });
        this.setDateTime(res);
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ showMessage: true, message: err.message, loading: false });
      });
  }

  setDateTime(res) {
    if (!res.meetingDateTime) {
      return;
    }
    const dateTime = new Date(res.meetingDateTime);
    let month = this.convertToDoubleDigit(dateTime.getMonth() + 1);
    let date = this.convertToDoubleDigit(dateTime.getDate());
    this.setState({ meetingDate: date + '/' + month + '/' + dateTime.getFullYear() });
    let hours = dateTime.getHours();
    let minutes = this.convertToDoubleDigit(dateTime.getMinutes());
    if (hours >= 12) {
      hours = hours - 12;
      this.setState({ isAm: false });
    } else {
      this.setState({ isAm: true });
    }
    hours = this.convertToDoubleDigit(hours);
    this.setState({ meetingTime: `${hours}:${minutes}` });
  }

  convertToDoubleDigit(value) {
    if (value < 10) {
      value = `0${value}`;
    }
    return value;
  }

  handleActionsChange = ({ target: { name, value } }) => {
    let index = this.state.data.adviceOutputActions.findIndex(function (c) {
      return c.adviceOutputActionId === name;
    });
    let newState = update(this.state, {
      data: {
        adviceOutputActions: {
          [index]: {
            actionStatus: { $set: parseInt(value) },
          },
        },
      },
    });
    this.setState(newState, () => console.log(this.state.data.adviceOutputActions));
  };

  handleChange = ({ target: { id, value } }) => {
    this.setState({ [id]: value });
  };

  handleAmPmClick = ({ target: { id, value } }) => {
    id === 'am' ? this.setState({ isAm: true }) : this.setState({ isAm: false });
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    const { meetingDate, meetingTime, isAm, data, meetingNote } = this.state;
    if (!meetingDate || !meetingTime) {
      return toast.error('Please enter date and time.');
    }
    let timeIn24HoursFormat = meetingTime;
    if (!isAm) {
      const timeArray = meetingTime.split(':');
      timeIn24HoursFormat = Number.parseInt(timeArray[0]) + 12 + ':' + timeArray[1];
    }

    const meetingDateTime = `${meetingDate
      .split('/')
      .reverse()
      .join('-')}T${timeIn24HoursFormat}:00`;

    const request = {
      meetingDateTime: meetingDateTime,
      adviceOutputActionItems: data.adviceOutputActions,
      outputId: data.outputId,
      meetingNote: meetingNote ?? '',
    };
    this.setState({ loadingButton: true }, () => {
      this.saveAdviceOutput(request);
    });
  };

  saveAdviceOutput(request) {
    completeAdviceOutput(request)
      .then((response) => {
        toast.info('Successful update');
        this.fetchData(request.outputId);
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ showMessage: true, message: err.message, loading: false });
      })
      .finally(() => this.setState(() => ({ loadingButton: false, loading: false })));
  }

  render() {
    const { loading } = this.state;
    return <>{loading ? <LoadingPage /> : this.renderContent()}</>;
  }

  renderContent() {
    const { meetingDate, meetingTime, isAm, data, meetingNote, loadingButton, isEditable } =
      this.state;
    if (data) {
      return (
        <div className="slim-content">
          <Row className="m-0">
            <SectionTitle title="Advice" subTitle="Output" />
            <div style={{ marginTop: '55px' }} className={isEditable ? 'd-none' : ''}>
              <div
                style={{ backgroundColor: '#F8F8F8', borderRadius: '3px' }}
                className="ml-10 pl-2 pr-2"
              >
                <span className="text-link-color">View only mode&nbsp;</span>
                <img src={eye} alt="view only mode" width="20" />
              </div>
            </div>
          </Row>
          <br />
          The following output is based on: actions taken for {data.clientFirstName}, including the
          outcomes of their sustainability profiling and the decisions made for their investments.
          <DateTime
            handleChange={this.handleChange}
            handleAmPmClick={this.handleAmPmClick}
            meetingDate={meetingDate}
            meetingTime={meetingTime}
            isAm={isAm}
            isEditable={isEditable}
          />
          <div className="font-12 text-darkblue">
            Enter date and time you met or spoke with {data.clientFirstName}
          </div>
          <div className="box-advice">
            <div className="mb-20">
              <PreferencesAndExclusions {...data} />
              <MatchingInstrumentsV2 {...data} />
              <ConflictingInstruments {...data} />
              <ClientAdviserAction
                adviceOutputActions={data.adviceOutputActions}
                handleActionsChange={this.handleActionsChange}
                isEditable={isEditable}
              />
              <div className="mt-40">
                Adviser notes that support the conversation had with the client and the agreed
                actions:
              </div>
              <Row>
                <Col sm={12}>
                  <textarea
                    id="meetingNote"
                    name="meetingNote"
                    className="dasshed-box w-100 mt-20"
                    onChange={this.handleChange}
                    value={meetingNote}
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
              <Row className="mt-40">
                <Col md={12} className="d-flex justify-content-center mb-20">
                  <ButtonLoading
                    label={isEditable ? 'Save' : 'Saved'}
                    disabled={!isEditable}
                    className="btn-main-shadowless"
                    loading={loadingButton}
                    onClick={this.handleSaveClick}
                  />
                </Col>
              </Row>
              <p className="text-darkblue font-12 text-center pb-2">Save to download Outputs</p>
            </div>
            <Row className="content-btns">
              <Col sm={4} className="text-center">
                <Button
                  className="btn-main-shadowless lightblue-btn"
                  disabled={isEditable}
                  onClick={() => {
                    window.open(data.emailDocumentUrl, '_blank', 'noopener,noreferrer');
                  }}
                >
                  Client email
                </Button>
              </Col>
              <Col sm={4} className="text-center">
                <Button
                  className="btn-main-shadowless lightblue-btn"
                  disabled={isEditable}
                  onClick={() => {
                    window.open(data.meetingNotesDocumentUrl, '_blank', 'noopener,noreferrer');
                  }}
                >
                  Meeting note
                </Button>
              </Col>
              <Col sm={4} className="text-center">
                <Button
                  className="btn-main-shadowless lightblue-btn"
                  disabled={isEditable}
                  onClick={() => {
                    window.open(data.adviceDocumentUrl, '_blank', 'noopener,noreferrer');
                  }}
                >
                  Advice document
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }
}
