import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Form, Col, Row, Container } from 'reactstrap';

import { getAdviserDetails } from '../../service/AdvisorService';
import { sendmail } from '../../service/SendMailService';
import ValidatorService from '../../service/validator';
import { TextField, TextareaField, PageHeader, ButtonLoading } from '../commons';

class Support extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      problem: '',
      saving: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ loading: true }));
    getAdviserDetails()
      .then((response) => {
        let advisor = response.adviserUser;
        this.setState({
          fullName: advisor.firstName + ' ' + advisor.lastName,
          email: advisor.email,
        });
        this.setState(() => ({ loading: false }));
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.message });
        toast.error(err.message);
        this.setState(() => ({ loading: false }));
      });
  }

  handleChange({ target: { id, value } }) {
    this.setState(() => ({ [id]: value, saving: false }));
  }

  handleSubmit(e) {
    e.preventDefault();
    const params = {
      subject: this.state.problem,
      body: this.state.problem,
    };
    this.setState(() => ({ loading: true }));
    sendmail(params)
      .then(() => this.setState(() => ({ saving: true, fullName: '', email: '', problem: '' })))
      .catch((err) => {
        toast.error(err.message);
        this.setState({ showMessage: true, message: err.message, saving: false });
      })
      .finally(() => this.setState(() => ({ loading: false })));
  }

  render() {
    const { loading, fullName, email, problem, saving } = this.state;
    return (
      <div className="slim-content">
        <Container>
          {saving && (
            <Fragment>
              <div className="wizard-top-title mb-10">
                <h2 className="mt-40 mb-10">Success!</h2>
                Thanks for getting in touch. We'll get back to you as soon as possible.
              </div>
              <hr />
            </Fragment>
          )}
          <PageHeader title="Support" />
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <Row className="form-group">
              <Col sm={12}>
                <TextField
                  onChange={(e) => this.handleChange(e)}
                  control="fullName"
                  label="Full name"
                  value={fullName}
                  cleanAfterSubmit={saving}
                  required
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={12}>
                <TextField
                  onChange={(e) => this.handleChange(e)}
                  validateInput={() => ValidatorService.validateEmail(email)}
                  control="email"
                  label="Email"
                  value={email}
                  cleanAfterSubmit={saving}
                  required
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col sm={12}>
                <TextareaField
                  onChange={(e) => this.handleChange(e)}
                  validateInput={() => ValidatorService.validateText(problem)}
                  control="problem"
                  label="Problem"
                  value={problem}
                  cleanAfterSubmit={saving}
                  required
                />
              </Col>
            </Row>
            <div className="text-center">
              <ButtonLoading
                className="btn-main-shadowless mb-20"
                style={{ margin: 'auto' }}
                label="Contact us"
                loading={loading}
              />
            </div>
          </Form>
        </Container>
      </div>
    );
  }
}

export default Support;
