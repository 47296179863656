import React, { Fragment } from 'react';

import { FormGroup, Label, Input } from 'reactstrap';

import PropTypes from 'prop-types';

const RadioButton = ({ onChange, control, options, disabled, legend, selected }) => (
  <>
    <FormGroup tag="fieldset">
      {legend && <legend>Radio Buttons</legend>}
      {options.map((option) => (
        <FormGroup check key={option}>
          <Label check className="form-check-label">
            <Input
              type="radio"
              name={control}
              className="form-check-input"
              value={option}
              onChange={() => onChange(option)}
              disabled={disabled}
              checked={option === selected}
            />
            <span className="checkmark"></span>
            {option}
          </Label>
        </FormGroup>
      ))}
    </FormGroup>
  </>
);

RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  control: PropTypes.string.isRequired,
  legend: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.string,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  disabled: false,
  value: 0,
  legend: null,
};

export default RadioButton;
