import { Row } from 'reactstrap';
import { Alert } from 'reactstrap';

import './statusPill.scss';

interface StatusProps {
  matchToValuesStatus: number;
  className?: string;
}

export default function StatusPill({ matchToValuesStatus, className }: StatusProps) {
  switch (matchToValuesStatus) {
    case 1: {
      return (
        <Row className="justify-content-center">
          <Alert
            className={`status-pill match ${className || 'table-partial-status-pill'}`}
            style={{ borderRadius: '10px' }}
          >
            Match
          </Alert>
        </Row>
      );
    }
    case 2: {
      return (
        <Row className="justify-content-center">
          <Alert
            color="danger match-status conflict"
            className={`status-pill ${className || 'table-partial-status-pill'}`}
            style={{ borderRadius: '10px' }}
          >
            Conflict
          </Alert>
        </Row>
      );
    }
    case 3: {
      return (
        <Row className="justify-content-center">
          <Alert
            color="warning match-status no-match"
            className={`status-pill ${className || 'table-partial-status-pill'}`}
            style={{ borderRadius: '10px' }}
          >
            No Match
          </Alert>
        </Row>
      );
    }
    case 4: {
      return (
        <Row className="justify-content-center">
          <Alert
            color="warning match-status partial-match"
            className={`status-pill ${className || 'table-partial-status-pill'}`}
            style={{ borderRadius: '10px' }}
          >
            Partial
          </Alert>
        </Row>
      );
    }
    case 5: {
      return (
        <Row className="justify-content-center">
          <Alert
            color="warning match-status partial-match"
            className={`status-pill ${className || 'table-partial-status-pill'}`}
            style={{ borderRadius: '10px' }}
          >
            No Data
          </Alert>
        </Row>
      );
    }
    default:
      return <></>;
  }
}
