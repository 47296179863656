import React from 'react';

interface NoDataViewProps {
  message?: string;
}

function NoDataView(props: NoDataViewProps) {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      {props.message ? (
        <div className="no-data blue-font-color font-12 pt-3">{props.message}</div>
      ) : (
        <div className="no-data blue-font-color">No Data</div>
      )}
    </div>
  );
}

export default NoDataView;
