import React from 'react';

import PasswordMask from 'react-password-mask';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Row, Form, FormFeedback, FormGroup, FormText, Label } from 'reactstrap';
import { Button } from 'reactstrap';

import termsDoc from '../../../../_assets/documents/Oko Adviser Terms of Service.pdf';
import eye from '../../../../_assets/img/icons/forms/eye@3x.png';
import ValidatorService from '../../../../service/validator';
import { TextField, ButtonLoading } from '../../../commons';

const RegisterForm = ({
  user,
  submitted,
  errors,
  onChange,
  onSubmit,
  createAccountEnabled,
  termsAndConditionsChecked,
  onChangeTerms,
  loading,
  ...props
}) => {
  return (
    <Form className="white-form signin-form" onSubmit={(e) => onSubmit(e)} noValidate>
      <FormGroup className="mb-30 mt-40">
        <TextField
          control="firstName"
          label="First name"
          value={user.firstName}
          onChange={onChange}
          feedBackLabel={errors.firstName}
          handleSubmitted={submitted}
        />
      </FormGroup>
      <FormGroup className="mb-30 mt-40">
        <TextField
          control="lastName"
          label="Last name"
          value={user.lastName}
          onChange={onChange}
          feedBackLabel={errors.lastName}
          handleSubmitted={submitted}
        />
      </FormGroup>
      <FormGroup className="mb-30 mt-40">
        <TextField
          control="email"
          label="Email"
          type="email"
          validateInput={() => ValidatorService.validateEmail(user.email)}
          value={user.email}
          onChange={onChange}
          feedBackLabel={errors.email}
          handleSubmitted={submitted}
        />
      </FormGroup>
      <FormGroup className="mb-30 mt-40">
        <Label for="password">Password</Label>
        <div
          className={errors && errors.password && user.password.length < 8 ? 'invalid-input' : null}
        >
          <PasswordMask
            maxLength={30}
            id="password"
            name="password"
            placeholder="********"
            inputClassName="form-control"
            buttonClassName="input-toggle"
            showButtonContent={<img alt="eye-slash" className="eye-password" src={eye} />}
            hideButtonContent={<img alt="eye" className="eye-password" src={eye} />}
            value={user.password}
            onChange={onChange}
          />
          <span className="clarification">Password should be at least 8 characters</span>
          {errors.password && user.password.length < 8 && (
            <FormFeedback className="force-invalid">{errors.password}</FormFeedback>
          )}
        </div>
        <FormText color="muted" />
      </FormGroup>
      {/* <FormGroup check className="mb-30 mt-40 form-inline">
        <Row>
          <Label check className="register-agree">
            <TextField
              control="terms"
              onChange={onChangeTerms}
              type="checkbox"
              value={termsAndConditionsChecked}
            />
            <span>&nbsp;I agree to the&nbsp;</span>
            <a href={termsDoc} target="_blank" alt="terms and conditions">
              terms and conditions
            </a>
          </Label>
        </Row>
      </FormGroup> */}
      <div className="text-center margin-v-40 btn-blue">
        <ButtonLoading className=" sign-in-btn btn-main-shadowless" label="Create my account"
        loading={loading}
         />
        <small className=" form-text don-t-have-an-accoun mt-40">
          I already have an account, &nbsp;
          <Link className='sign-in-link' to="/">sign in</Link>
        </small>
      </div>
    </Form>
  );
};

export default withRouter(RegisterForm);
