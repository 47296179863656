import { isEmpty } from 'lodash';
import React, { PureComponent, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap';

import Auth, { isPasswordStrong, register } from 'service/AuthService';
import { ProfileContext } from 'store/ProfileContext';

import OkoLogo from '../../../../_assets/img/logo/oko-logo-no-background-padding.svg';
import AdviserLogo from '../../../../_assets/img/logo/adviser-logo-small.svg';
import { User } from '../../../../model';
import { setSessionToken } from '../../../../service/LocalStorageService';
import ValidatorService from '../../../../service/validator';
import Lottie from 'react-lottie-player';
import animationData from '../../../../_assets/lotties/sign-in.json';

import RegisterForm from './RegisterForm';

const Register = (props) => {
  const profileContext = useContext(ProfileContext);
  const history = useHistory();
  const [createAccountEnabled, setCreateAccountEnabled] = useState(false);
  const { saving } = props;
  const [user, setUser] = useState(new User());
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(new User());
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { id, value } }) => {
    setUser((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  useEffect(() => {
    if (
      user &&
      user.firstName &&
      user.lastName &&
      user.email &&
      user.password &&
      termsAndConditionsChecked
    ) {
      setCreateAccountEnabled(true);
    } else {
      setCreateAccountEnabled(false);
    }
  }, [user, termsAndConditionsChecked]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // this.setState({ errors: {} });
    // const { errors, user } = this.state;
    let feedbackResult = ValidatorService.validateRegisterForm(user, errors);
    // setSubmitted(true);
    // this.setState({ errors: feedbackResult });
    // this.setState(() => ({ submitted: true }));
    // if (isEmpty(feedbackResult)) {

    const passwordParams = { password: user.password };

    isPasswordStrong(passwordParams)
      .then((data) => {
        register(user)
          .then((data) => {
            if (data.httpStatusCode === 409) {
              toast.error(data.message);
            }
            if (data.httpStatusCode === 200) {
              const params = {
                Email: user.email,
                Password: user.password,
              };
              try {
                Auth.login(params)
                  .then((response) => {
                    if (response.data.adviserUser) {
                      setSessionToken(response.data.userToken.success_token);
                      profileContext.setProfile(response.data.adviserUser);
                      // history.push(`/register/inviteCode`);
                      history.push(`/register/basicInformation`);
                      // history.push(`/`);
                    }
                  })
                  .catch((exception) => {
                    console.log(exception);
                  }).finally(() => {
                    setLoading(false);
                  });
              } catch (err) {
                console.log(err);
              }
            }
          })
          .catch((exception) => {
            // toast.error(err.message);
          })
          .finally(() => { });
      })
      .catch((exception) => {
        // toast.error(err.message);
      })
      .finally(() => {
        // setSubmitted(false);
      });
    // }
  };

  const handleChangeTerms = () => {
    setTermsAndConditionsChecked(!termsAndConditionsChecked);

    // this.setState(
    //   (prevState) => ({ termsAndConditionsChecked: !prevState.termsAndConditionsChecked }),
    //   this.enableDisableCreateAccount,
    // );
  };

  return (
    <div className='full-height full-width' style={{ backgroundColor: 'black' }}>
      <Row className="layout window-in-two full-height centered">
        <Col md={{ size: 6, offset: 1 }} xs="12" className="pl-0 pr-0">
          <div className="login-left-side">
            <div className="wizard-top-title text-left">
              <div style={{
                gap: '8px'
              }} className='d-inline-flex align-items-end'>
                <img src={OkoLogo} height="39" alt="Oko Adviser Logo" />
                <img src={AdviserLogo} height="20" alt="Adviser Logo" />
              </div>
              <h2 className="sign-up-title mt-40">Sign up</h2>
            </div>
            <RegisterForm
              onChange={(e) => handleChange(e)}
              onSubmit={(e) => handleSubmit(e)}
              onChangeTerms={() => handleChangeTerms()}
              loading={loading}
              {...{ user, errors, submitted, termsAndConditionsChecked, createAccountEnabled }}
            />
          </div>
        </Col>
        <Col md="5" xs="12" className="pl-0 pr-0 d-none d-sm-block">
          <div className='d-flex flex-column'>
            <Lottie
              animationData={animationData} loop={true} play={true} rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }} className='sign-in-lottie' />
            <p className='register-page-tagline'>The only full service platform for sustainability values Advice. </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
