import React from 'react';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row, Button } from 'reactstrap';

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  rectIntersection,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { LoadingPage } from '_components/commons';

import { getBehaviourDetails } from '../../../service/ProfileService';

import tipIcon from './../../../_assets/img/icons/global/tip.png';
import bookmark from './../../../_assets/img/icons/profileIntro/bookmark@3x.png';
import CardContainer from './components/CardContainer';
import { Item } from './components/SortableItem';
import './styles.scss';

{
  /* <button className="tips-button">Start here</button> */
}
export default function ProfileCauses() {
  const history = useHistory();

  const [behaviours, setBehaviours] = useState({
    causes: [],
    support: [],
    avoid: [],
    unsure: [],
  });

  const [activeId, setActiveId] = useState();
  const [showModal, setShowModal] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleButtonShow = () => {
    if (behaviours.support.length !== 0 && behaviours.avoid.length !== 0) {
      setShowModal(true);
      localStorage.setItem('behaviours', JSON.stringify(behaviours));
    } else {
      return toast.error('Please drag at least one to Support and Avoid');
    }
  };

  function findContainer(id) {
    if (id in behaviours) {
      return id;
    }
    return Object.keys(behaviours).find((key) => behaviours[key].some((obj) => obj.id === id));
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over || {};

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setBehaviours((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      //   Find the indexes for the items
      const activeObj = activeItems.find((obj) => obj.id === id);
      const activeIndex = activeItems.indexOf(activeObj);
      const overObj = overItems.find((obj) => obj.id === overId);
      const overIndex = overItems.indexOf(overObj);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          behaviours[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over || {};
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = behaviours[activeContainer].findIndex((item) => item.id === active.id);
    const overIndex = behaviours[overContainer].findIndex((item) => item.id === over.id);

    if (active.id !== over.id) {
      setBehaviours((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      }));
    }

    setActiveId(null);
  }

  useEffect(() => {
    getBehaviourDetails()
      .then((response) => {
        const behavioursDetails = response.profileQandAWithBehaviourDetails;
        const newBehavioursDetails = behavioursDetails.map((behaviour, index) => {
          return {
            ...behaviour,
            id: index.toString(),
          };
        });
        setBehaviours((prev) => ({
          ...prev,
          causes: newBehavioursDetails,
        }));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  const sustainabilityModal = () => {
    return (
      <>
        <Container className="profile-feature">
          <Row className="mt-5 justify-content-center">
            <p className="text-center profile-header">{`Allocate your important sustainability causes.`}</p>
          </Row>
          <div style={{ marginTop: '42px' }} className="d-flex justify-content-center">
            <div
              style={{ gap: '12px', paddingLeft: '15px', paddingRight: '17px' }}
              className="d-flex"
            >
              <div className="draggable-area-grid">
                <p className="category-title">Causes &rarr;</p>
                <p className="category-title">Causes</p>
                <p className="category-title">{`Support`}</p>
                <p className="category-title">{`Avoid`}</p>
                <p className="category-title">Neutral</p>
                <div style={{ gap: '40px', gridRow: 'span 2' }} className="d-flex flex-column">
                  <Container style={{ marginTop: '1rem' }} className="tips-panel">
                    <div className="tips-icon">
                      <img src={tipIcon} />
                    </div>
                    <div className="tips-text">
                      <p className="category-subtitle">
                        Drag and drop <b style={{ color: '#3e61DF' }}>at least</b> item into the
                        column that is most appropriate. Typically customers select 3-5 of the more
                        important to them.
                      </p>
                    </div>
                  </Container>
                  <Row className="justify-content-center">
                    <Button
                      onClick={() => handleButtonShow()}
                      className="btn-main-shadowless confirm-button"
                    >
                      <div className="d-flex justify-content-center align-items-center"> Done</div>
                    </Button>
                  </Row>
                </div>
                <p className="category-subtitle">Causes for you to drag and drop.</p>
                <p className="category-subtitle">Causes you support</p>
                <p className="category-subtitle">Behaviours you want to avoid</p>
                <p className="category-subtitle">Behaviours not on your radar right now</p>
                <DndContext
                  sensors={sensors}
                  collisionDetection={rectIntersection}
                  onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}
                >
                  <CardContainer className="causes" id="causes" items={behaviours.causes} />

                  <CardContainer className="support" id="support" items={behaviours.support} />

                  <CardContainer className="avoid" id="avoid" items={behaviours.avoid} />
                  <CardContainer className="unsure" id="unsure" items={behaviours.unsure} />
                </DndContext>
              </div>
              <DragOverlay>
                {activeId ? <Item id={activeId} item={behaviours[activeId]} /> : null}
              </DragOverlay>
            </div>
          </div>
        </Container>
      </>
    );
  };

  const loadingModal = () => {
    return <LoadingPage />;
  };

  const confirmationModal = () => {
    return (
      <div className="overlay">
        <Container className="showup-modal">
          <Row className="mt-10 mb-10 justify-content-center">
            <img src={bookmark} width="150" />
          </Row>
          <Row className="text-center justify-content-center">
            <div className="header">
              <p>{`Nice work!`}</p>
            </div>
          </Row>
          <Row className="mt-20">
            <p className="d-block text-center w-100 comment">{`Now prioritise which of these causes is most 
            and least important to you. `}</p>
          </Row>
          <Row className="justify-content-center mt-10">
            <Col sm={5} className="mb-40">
              <div className="text-center">
                <Button
                  className="btn-blue btn-main-shadowless"
                  onClick={() => {
                    history.push('prioritise');
                  }}
                >
                  {`Let's go`} &rarr;
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
  return (
    <>
      {behaviours.causes.length !== 0 ||
      behaviours.support.length !== 0 ||
      behaviours.avoid.length !== 0 ||
      behaviours.unsure.length !== 0
        ? sustainabilityModal()
        : loadingModal()}
      {showModal && confirmationModal()}
    </>
  );
}
