import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Container, Row, Button, Col } from 'reactstrap';

import { getClient, deleteClient } from '../../../../../service/ClientsService';
import { ButtonModal, LoadingPage, PageHeader } from '../../../../commons';

import DeleteClientModal from './DeleteClientModal';

class Details extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      loading: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState(() => ({ loading: true }));
    if (id) {
      const params = { ClientCoreId: id };
      getClient(params)
        .then((response) => {
          this.setState({ client: response.adviserClient, loading: false });
        })
        .catch((err) => {
          this.setState({ showMessage: true, message: err.message, loading: false });
          toast.error(err.message);
        });
    }
  }

  handleDeleteClient() {
    const { id } = this.props.match.params;
    this.setState(() => ({ loading: true }));
    if (id) {
      const params = { ClientCoreId: id };
      deleteClient(params)
        .then((response) => {
          this.props.history.push(`/clients`);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }

  handleEditClient() {
    const { id } = this.props.match.params;
    this.props.history.push(`/clients/${id}/profile/edit`);
  }

  render() {
    const { client, loading } = this.state;
    return (
      <div className="slim-content">
        <Container>
          <PageHeader title="Client Details" margin="mt-50 mb-20" />
          {loading && <LoadingPage />}
          {!loading && (
            <Fragment>
              <Row className="mb-10">
                <Col>
                  {client.clientFirstName}
                  &nbsp;
                  {client.clientLastName}
                </Col>
              </Row>
              <Row className="mb-30">
                <Col>{client.clientEmailAddress}</Col>
              </Row>
              <br />
              <Row className="content-buttons-profile-detail">
                <Col xs={6} className="d-flex justify-content-end">
                  <ButtonModal
                    component={DeleteClientModal}
                    className="btn-modal btn-profile-detail mb-2"
                    onSubmit={() => this.handleDeleteClient()}
                    name="Delete client"
                  >
                    Delete Client
                  </ButtonModal>
                </Col>
                <Col xs={6} className="d-flex justify-content-start">
                  <Button
                    className="btn-modal btn-profile-detail"
                    onClick={() => this.handleEditClient()}
                  >
                    Edit client
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}
        </Container>
      </div>
    );
  }
}

export default Details;
