export default class Portfolio {
  AdviserClientPortfolioItems = [];
  PortfolioName = '';
  error = null;
  loading = false;
  Clients = [];

  constructor(obj) {
    Object.assign(this, obj);
  }
}
