import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import DateUtilsService from '../../../../../service/DateUtils';

interface PreferencesAndExclusionsProps {
  preferences: [string];
  exclusions: [string];
  clientFirstName: string;
  profileCreatedDate: string;
}

export default function PreferencesAndExclusions(props: PreferencesAndExclusionsProps) {
  const { preferences, exclusions, clientFirstName, profileCreatedDate } = props;
  const [showFullList, setShowFullList] = useState(false);

  const showFullOrCondensedList = (arrayToDisplay: [string]) => {
    if (isEmpty(arrayToDisplay)) {
      return null;
    }

    let displayLength = 0;
    displayLength = arrayToDisplay.length;
    if (arrayToDisplay.length > 3 && !showFullList) {
      displayLength = 3;
    }

    const liArray = [];

    for (let i = 0; i < displayLength; i++) {
      liArray.push(
        <li key={i} className="font-14 pb-1">
          {arrayToDisplay[i]}
        </li>,
      );
    }
    return liArray;
  };

  const renderShowHideLink = () => {
    if (exclusions.length > 3 || preferences.length > 3) {
      return (
        <span
          className="cursor-hand text-link-color"
          onClick={() => setShowFullList((prevState) => !prevState)}
        >
          {showFullList ? 'Condense List' : 'Show full list'}
        </span>
      );
    }
  };

  if (isEmpty(preferences) && isEmpty(exclusions)) {
    return (
      <div className="mt-30 mb-30">
        No sustainability profile was completed for {clientFirstName}
      </div>
    );
  }

  return (
    <>
      <p>
        The following selections were made by {clientFirstName} when completing their sustainability
        profile completed on {DateUtilsService.longFormatDate(profileCreatedDate)}
      </p>
      <div className="dasshed-box">
        <b>
          <div className="pb-1">{`In total ${preferences.length} of preferences were selected, including:`}</div>
        </b>
        <ul>{showFullOrCondensedList(preferences)}</ul>
        <b>
          <div className="pb-1">{`In total ${exclusions.length} of exclusions were selected, including:`}</div>
        </b>
        <ul>{showFullOrCondensedList(exclusions)}</ul>
        {renderShowHideLink()}
      </div>
      <br />
    </>
  );
}
