import { isEmpty, map, trim, get } from 'lodash';
import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';

import { fileEnums } from '../../../_constants';
import { Portfolio } from '../../../model';
import { getAllClients } from '../../../service/ClientsService';
import FileService from '../../../service/FileService';
import { addClientsPortfolioBulk } from '../../../service/PortfolioService';
import {
  EmptyClientsPage,
  LoadingPage,
  PageHeader,
  PortfolioUpload,
  UploadSelectMethod, // PortfolioCopyPaste
} from '../../commons';

import ClientListSelector from './ClientListSelector';
import SuccessUpload from './SuccessUpload';

class UploadPortfolio extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploaderStep: 0,
      prevStep: 0,
      loading: false,
      loadingPage: false,
      filename: '',
      clientList: [],
      portfolio: new Portfolio(),
    };
  }

  componentDidMount() {
    this.setState(() => ({ loadingPage: true }));
    getAllClients()
      .then((response) => {
        this.setState(() => ({
          clientsList: map(response.adviserClients, (client) => ({ ...client, checked: false })),
        }));
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.message });
        toast.error(err.message);
      })
      .finally(() => {
        this.setState(() => ({ loadingPage: false }));
      });
  }

  handleChangeUploader(step) {
    this.setState((state) => ({
      uploaderStep: step,
      prevStep: step > state.uploaderStep ? state.uploaderStep : 0,
    }));
  }

  async handleDrop(file) {
    if (file) {
      this.setState(() => ({ filename: file && file.path }));
      FileService.parsePortfolioCsv(file).then((res) => {
        if (res.error) {
          return toast.error(res.error);
        }
        this.setState((state) => ({
          portfolio: {
            ...state.portfolio,
            AdviserClientPortfolioItems: res.AdviserClientPortfolioItems,
          },
        }));
      });
    }
  }

  handleChange({ target: { id, value } }) {
    this.setState((state) => ({ portfolio: { ...state.portfolio, [id]: trim(value) } }));
  }

  handleDropRejected() {
    this.setState(() => ({ portfolios: { error: fileEnums.WRONG_FORMAT } }));
  }

  handleSubmitPortfolio(e) {
    e.preventDefault();
    const { portfolio } = this.state;
    if (!isEmpty(portfolio.AdviserClientPortfolioItems) || portfolio.PortfolioName) {
      this.handleChangeUploader(3);
    } else {
      toast.error('Check portfolio name or csv');
    }
  }

  handleSubmit(clientsList) {
    const { portfolioId, error, loading, ...portfolio } = this.state.portfolio;
    const id = this.props.router.match.params.id;
    const clientsIds = map(clientsList, (client) => get(client, 'clientCoreId'));

    if (
      portfolio &&
      !isEmpty(portfolio.AdviserClientPortfolioItems) &&
      !error &&
      !isEmpty(clientsIds)
    ) {
      portfolio.Clients = map(clientsIds, (client) => ({ ClientCoreId: client }));
      this.setState(() => ({ loading: true }));
      addClientsPortfolioBulk(portfolio)
        .then((data) => {
          const defaultClientList = map(this.state.clientsList, (client) => ({
            ...client,
            checked: false,
          }));
          const portfolioId = data.clientsAdded[0].clientPortfolioId;
          this.setState(() => ({
            portfolio: new Portfolio(),
            filename: '',
            clientsList: defaultClientList,
          }));
          this.props.history.push(`/portfolioUpload/review/${portfolioId}/bulk`);
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          this.setState(() => ({ loading: false }));
        });
    } else {
      toast.error('Error process');
    }
  }

  handleSubmitCopyPaste(AdviserClientPortfolioItems) {
    const { portfolio } = this.state;
    if (!portfolio.PortfolioName || isEmpty(AdviserClientPortfolioItems)) {
      return toast.error('Check portfolio name or grid');
    }
    this.setState((state) => ({ portfolio: { ...portfolio, AdviserClientPortfolioItems } }));
    this.handleChangeUploader(3);
  }

  handleChecked(indexClient) {
    const clientsList = this.state.clientsList.slice();
    clientsList[indexClient].checked = !clientsList[indexClient].checked;
    this.setState(() => ({ clientsList }));
  }

  cleanUploader() {
    const defaultClientList = map(this.state.clientsList, (client) => ({
      ...client,
      checked: false,
    }));
    this.setState(() => ({
      uploaderStep: 0,
      prevStep: 0,
      portfolio: new Portfolio(),
      filename: '',
      clientsList: defaultClientList,
    }));
  }

  render() {
    const { uploaderStep, filename, portfolio, loading, loadingPage, clientsList, prevStep } =
      this.state;
    return (
      <div className="slim-content" style={{ maxWidth: '900px' }}>
        <Container style={{ maxWidth: '900px' }}>
          {loadingPage && <LoadingPage />}
          {!loadingPage && isEmpty(clientsList) && (
            <EmptyClientsPage
              addNewClientRedirect={() => this.props.history.push('/clients/add')}
              importClientListRedirect={() => this.props.history.push('/clients/importClients')}
            />
          )}
          {/* {!loadingPage && !isEmpty(clientsList) && uploaderStep === 0 && (
            <Fragment>
              <PageHeader title="Upload existing portfolio" />
              <br />
              <Row>
                <p>
                  For best results, you can upload your client's portfolio to Oko Adviser, so that
                  you can compare current investments with results from this questionnaire.
                </p>
              </Row>
              <UploadSelectMethod
                onClickCopyPaste={() => this.handleChangeUploader(2)}
                onClickCSV={() => this.handleChangeUploader(1)}
              />
            </Fragment>
          )} */}
          {!loadingPage && !isEmpty(clientsList) && uploaderStep === 0 && (
            <Fragment>
              <PageHeader title="Upload a portfolio" />
              <PortfolioUpload
                onDrop={(files) => this.handleDrop(files[0])}
                onDropRejected={(e) => this.handleDropRejected(e)}
                onBack={() => this.handleChangeUploader(prevStep)}
                onSubmit={(e) => this.handleSubmitPortfolio(e)}
                onChange={(e) => this.handleChange(e)}
                {...{ filename, portfolio, loading }}
              />
            </Fragment>
          )}
          {/* {!loadingPage && !isEmpty(clientsList) && uploaderStep === 2 && (
                        <Fragment>
                            <PageHeader title="Add a new portfolio" subTitle="Copy / paste from file"/
                            // <PortfolioCopyPaste
                                onBack={() => this.handleChangeUploader(prevStep)}
                                onSubmit={data => this.handleSubmitCopyPaste(data)}
                                onChange={e => this.handleChange(e)}
                                {...{portfolio, loading}}
                            />
                        </Fragment>
                    )} */}
          {!loadingPage && !isEmpty(clientsList) && uploaderStep === 3 && (
            <Fragment>
              <PageHeader title="Upload portfolios" />
              <ClientListSelector
                onSubmit={(clientsList) => this.handleSubmit(clientsList)}
                onBack={() => this.handleChangeUploader(prevStep)}
                clientsList={clientsList}
                onChecked={(indexClient) => this.handleChecked(indexClient)}
                loading={loading}
              />
            </Fragment>
          )}
          {/* {!loadingPage && uploaderStep === 4 && (
            <SuccessUpload onSubmit={() => this.cleanUploader()} />
          )} */}
          <Row className="mt-40 mb-40">&nbsp;</Row>
        </Container>
      </div>
    );
  }
}

export default UploadPortfolio;
