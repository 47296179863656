import api, { apiSetup } from '../config/api';

export const cancellablePromise = (promise: Promise<any | void>) => {
  const isCancelled = { value: false };

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((data) => {
        if (isCancelled.value) {
          reject(isCancelled);
        } else {
          resolve(data);
        }
      })
      .catch((error) => {
        if (isCancelled.value) {
          reject(isCancelled);
        } else {
          reject(error);
        }
      });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true;
    },
  };
};

export function search(
  searchTerm: string,
  isDataFull: boolean,
  filters: string[],
  sortBy: string,
  clientId: string,
) {
  const request = {
    searchTerm: searchTerm,
    isDataFull: isDataFull,
    instrumentsSortingOptionAdviser: sortBy,
    filters: filters,
    clientId,
  };
  apiSetup();
  const apiPromise = api.post('/v2/Search', request);
  const cancellable = cancellablePromise(apiPromise);

  return {
    promise: cancellable.promise,
    cancel: cancellable.cancel,
  };
}
