import { head, isEmpty, round, sum, isNaN, parseInt } from 'lodash';

import { toast } from 'react-toastify';

// import { seeAll } from '../service/InstrumentsService';

const getPercentage = (value, total, precision = 0) => {
  const percentage = round((value * 100) / total, precision);
  return !percentage ? 0 : percentage;
};

const getTooltipLabel = (tooltipItem, data) => {
  const dataset = head(data.datasets);
  const label = data.labels[tooltipItem.index];
  const count = dataset.data[tooltipItem.index];
  const percent = getPercentage(count, sum(dataset.data), 2);
  return ` ${label}: ${count} (${percent}%)`;
};

export { getPercentage };
export { getTooltipLabel };
