import { isEmpty } from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Row, Col } from 'reactstrap';

import { LoadingPage } from '_components/commons';
import { portfolioDefaultRow } from '_constants';

import checkIcon from '../../../_assets/img/icons/global/check@2x.png';
import notification from '../../../_assets/img/icons/global/notification@3x.png';
import tipIcon from '../../../_assets/img/icons/profile/Bulb@1.5x.png';
import DateUtilsService from '../../../service/DateUtils';
import { getClientPortfolio } from '../../../service/PortfolioService';
import { ButtonLoading } from '../../commons';
import '../client.scss';

const ClientProfilePortfolioSummary = (props) => {
  const portfolioId = props.router.match.params.portfolioId;
  const history = useHistory();

  const [portfolios, setPortfolios] = useState({});
  const [loadingPortfolio, setLoadingPortfolio] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [clientCoreId, setClientCoreId] = useState('');

  useEffect(() => {
    if (portfolioId !== null) {
      async function getPortfolio() {
        await getClientPortfolio(portfolioId)
          .then((response) => {
            console.log(response);
            setPortfolios(response.portfolio);
            setClientCoreId(response.portfolio.clientCoreId);
          })
          .catch((err) => {
            toast.error(err.message);
            setShowMessage(true);
            setMessage(err.message);
          })
          .finally(() => {
            setLoadingPortfolio(false);
          });
      }
      getPortfolio();
    }
  }, []);
  if (loadingPortfolio) {
    return <LoadingPage />;
  }

  const icon = portfolios.unMatchedinstruments.length == 0 ? checkIcon : notification;
  const matchCountColor =
    portfolios.unMatchedinstruments.length > 0 ? { color: '#AC003C' } : { color: '#2AB564' };
  const portfolio = portfolios.matchedInstruments.concat(portfolios.unMatchedinstruments);
  const uploadDate = DateUtilsService.formatDate(portfolios.updatedWhen);

  return (
    <Fragment>
      <div>
        <div className="ml-40">
          <div className="d-flex mt-50">
            <div className="justify-content-start align-items-start p-1">
              <img src={icon} alt="tip" width="30" />
            </div>
            <div className="justify-content-center align-items-center p-2 flex-grow-1 justify-content-between">
              <h3>Uploaded file review</h3>
            </div>
          </div>
          <div>
            <p className="portfolio-heading">
              Portfolio Name: {portfolios.portfolioName} | Uploaded on {uploadDate}
            </p>
            <p className="instrument-match">
              <span style={matchCountColor}>{portfolios.matchedInstruments.length}</span> of the{' '}
              <span style={{ color: '#111111' }}>{portfolio.length}</span> instruments uploaded have
              been successfully matched
            </p>
            <br />
            <br />
          </div>
        </div>
        <div>
          {portfolios.unMatchedinstruments.length > 0 && (
            <div>
              <br />
              <div className="tips-panel tip-panel-text ml-40 mb-5">
                <img src={tipIcon} alt="icon" className="tip-icon" />
                For unmatched data please check spelling and codes are correct and re-upload file.
              </div>
            </div>
          )}
        </div>
        {portfolio &&
          portfolio.map((details, index) => {
            const portfolioFailed = details.instrumentId === null || !details.percentage;
            const matchText =
              portfolioFailed ? 'Data match failed' : 'Data match succesful';
            // const matchedName = details.instrumentId === null ? 'Unmatched Name' : 'Matched Name';
            const color = portfolioFailed ? '#AC003C' : '#2AB564';
            return (
              <div key={index} className="ml-30">
                <Row>
                  <Col sm={6}>Name:</Col>
                  <Col sm={6}>{details.instrumentName}</Col>
                </Row>
                <Row>
                  <Col sm={6}>Ticker:</Col>
                  <Col sm={6}>{details.ticker}</Col>
                </Row>
                <Row>
                  <Col sm={6}>Portfolio %:</Col>
                  <Col sm={6}><span style={{ color: details?.percentage ? 'black' : '#AC003C' }}>{details?.percentage ? `${details.percentage}%` : 'No % recorded'}</span></Col>
                </Row>
                <Row>
                  <Col sm={6}>Exchange:</Col>
                  <Col sm={6}>{details.exchange}</Col>
                </Row>

                <Row>
                  <Col sm={6}>ISIN:</Col>
                  <Col sm={6}>{details.isin}</Col>
                </Row>

                <Row>
                  <Col sm={6}>Status:</Col>
                  <Col sm={6} style={{ color: color }}>
                    {matchText}
                  </Col>
                </Row>
                <br />
              </div>
            );
          })}
        <Row className="ml-5 mt-50">
          <Col sm={6}>
            <Button
              outline
              className="btn-secondary"
              onClick={() => {
                history.push(`/clients/portfoliosUpload`);
              }}
            >
              Close and re-upload
            </Button>
          </Col>
          {!history.location.pathname.match(/bulk/) && (
            <Col sm={6}>
              <ButtonLoading
                label="See results"
                className="btn-main-shadowless"
                disabled={!portfolios.isSelected}
                small
                onClick={() => {
                  history.push(`/clients/${clientCoreId}/results`);
                }}
              />
            </Col>
          )}
        </Row>
      </div>
    </Fragment>
  );
};

export default ClientProfilePortfolioSummary;
