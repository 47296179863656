import { isEmpty } from 'lodash';
import React from 'react';
import { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Table, Button } from 'reactstrap';
import { Row } from 'reactstrap';

import Printer from '_components/app/Printer';
import Grade from '_components/commons/Grade';
import StatusPill from '_components/commons/StatusPill';

import { redirectToInstrumentPage } from '../../_helpers/instrument';
import { InstrumentInterface } from '../../model/Instrument';
import NoDataView from '../commons/Instrument/NoDataView';

import './HoldingList.scss';

// import RiskAndReturnChart from './RiskAndReturnChart';

interface HoldingListProps {
  holdings?: InstrumentInterface[];
  showMatch: boolean;
}
export default function HoldingList(props: HoldingListProps) {
  const history = useHistory();
  const [filters, setFilters] = useState(0);

  const handleFilters = (match: any) => {
    if (match === filters) {
      setFilters(0);
    } else {
      setFilters(match);
    }
  };

  if (props.holdings) {
    props.holdings.sort(function (x: any, y: any) {
      return (
        y.holdingPercentage &&
        x.holdingPercentage &&
        Number(y.holdingPercentage.replace('%', '')) - Number(x.holdingPercentage.replace('%', ''))
      );
    });
  }

  if (!props.holdings) {
    return null;
  }
  const renderContent = () => {
    if (isEmpty(props.holdings)) {
      return (
        <div>
          <NoDataView />
        </div>
      );
    }
    return (
      <>
        <Table
          style={{
            minHeight: '10vh',
          }}
          borderless
          hover
          className="custom-table text-center"
        >
          <thead>
            <tr>
              <th></th>
              <th style={{ position: 'relative', width: '350px' }}>{`Name`}</th>
              <th>{`Symbol`}</th>
              <th style={{ width: '200px' }}>{`Sector`}</th>
              <th>{`Type`}</th>
              <th style={{ width: '110px' }}>{`Grade`}</th>
              {props.showMatch ? <th>{`Match`}</th> : ''}
              <th>{`Holding%`}</th>
            </tr>
          </thead>
          <tbody>
            {props?.holdings
              ? props.holdings
                  .filter((value) => filters === 0 || value.matchToValuesStatus === filters)
                  .map((value) => {
                    return (
                      <tr key={value.id} onClick={redirectToInstrumentPage(value, history)}>
                        <td>
                          <img
                            className="company-icon"
                            alt="logo"
                            src={value.logoUrl}
                            width="42"
                            height="42"
                          />
                        </td>
                        <td style={{ position: 'relative' }}>{value.name}</td>
                        <td>{value.symbol}</td>
                        <td>{value.sector}</td>
                        <td className={value.type.length === 3 ? 'uppercase' : 'capitalize'}>
                          {value.type}
                        </td>
                        <td>
                          <Grade className="grades-related-investment" grade={value.grade} />
                        </td>
                        {props.showMatch ? (
                          <td>
                            <StatusPill matchToValuesStatus={value.matchToValuesStatus} />
                          </td>
                        ) : (
                          ''
                        )}
                        <td>{value.holdingPercentage}</td>
                      </tr>
                    );
                  })
              : ''}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <>
      <Printer>
        <div className="panel w-100">
          <div>
            <h4>Fund Holdings {props.holdings.length}</h4>
          </div>
          {props.holdings.length !== 0 && (
            <Row className="align-items-center">
              <p className="mr-30">{`Scored fund holdings`}</p>
              {['All', 'Match', 'Conflict', 'No Match', 'Partial'].map((item, index) => (
                <Button
                  style={{ fontSize: '17px' }}
                  key={index}
                  className={`btn-filters mb-30 ${filters === index ? 'selected' : 'unselected'}`}
                  onClick={() => handleFilters(index)}
                >
                  {item}
                </Button>
              ))}
            </Row>
          )}
          {renderContent()}
        </div>
      </Printer>
    </>
  );
}
