import { Row } from 'reactstrap';
import { Container } from 'reactstrap';

import tipIcon from '_assets/img/icons/profile/Bulb@1.5x.png';
import PortfolioWeighting from '_components/clients/ClientDetails/Results/PortfolioWeighting';
import { IResultsSummaryResponse } from '_components/clients/ClientDetails/Results/Results';

export default function PortfolioExposure({
  resultsSummary,
  clientFirstName,
}: {
  resultsSummary: IResultsSummaryResponse;
  clientFirstName: string;
}) {
  return (
    <div className="box-advice" style={{ marginTop: '20px' }}>
      <h5>Portfolio Exposure</h5>
      <p>
        The Portfolio Exposure shows your clients sustainability profile selections (positive and
        negative) weighted to the asset allocation of the portfolio. This is a powerful feature that
        will give you one of the truest views of how well aligned a client&apos;s portfolio is to
        their sustainability values. This section is based on your client&apos;s sustainability
        profile selections and does not include exposure to unselected profile options.
      </p>
      <Container className="sustainability-overview-container dasshed-box">
        <div
          style={{
            padding: '12px',
            borderRadius: '15px',
            border: '1px solid #e5e5e5',
            display: 'flex',
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '350px',
            gap: '20px',
          }}
        >
          <img src={tipIcon} alt="tip-icon" />
          This section is based on {clientFirstName} sustainability profile selections and does not
          include exposure to unselected profile options
        </div>
        <Row className="portfolio-weighting-row">
          <div className="portfolio-left-div" style={{ padding: '3px', width: '240px' }}></div>
          {resultsSummary && <PortfolioWeighting resultsSummary={resultsSummary} />}
          <div
            style={{
              padding: '3px',
              display: 'flex',
              width: '240px',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
              <div
                style={{
                  borderRadius: '100%',
                  backgroundColor: '#7be0d3',
                  width: '20px',
                  height: '20px',
                  gap: '3px',
                  alignItems: 'center',
                }}
              ></div>
              <span>Profile Selection &quot;Support&quot;</span>
            </div>
            <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
              <div
                style={{
                  borderRadius: '100%',
                  backgroundColor: '#bd5f80',
                  width: '20px',
                  height: '20px',
                }}
              ></div>
              <span>Profile Selection &quot;Avoid&quot;</span>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}
