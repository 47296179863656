import React from 'react';

import { Switch, Route } from 'react-router-dom';

import PropTypes from 'prop-types';

import Layout from '../ui/Layout';
import Presets from '../ui/Presets';

import Faqs from './Faqs';
import Support from './Support';

const Assistance = ({ match: { path } }) => (
  <Switch>
    <Route
      path={`${path}/support`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.assistance}
          component={Support}
          sidebarSelect="support"
        />
      )}
    />
    <Route
      path={`${path}/faqs`}
      render={() => (
        <Layout
          header={Presets.main}
          sidebar={Presets.assistance}
          component={Faqs}
          sidebarSelect="faqs"
        />
      )}
    />
  </Switch>
);

Assistance.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }).isRequired,
};

export default Assistance;
