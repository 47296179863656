import React, { useRef } from 'react';

import { useReactToPrint } from 'react-to-print';

import printer from '../../_assets/img/icons/global/printer-colored@3x.png';

import './buttonPrinter.scss';

const Printer = (props: {
  children?: React.ReactNode;
  text?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const { className } = props;
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const style = {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties;
  if (props.text !== undefined && props.text !== '') {
    style.padding = '10px';
    style.borderRadius = '15px';
    style.boxShadow = '0px 0px 5px 4px rgba(0,0,0,0.05)';
    style.backgroundColor = 'white';
    style.gap = '10px';
    style.right = '0px';
  }

  return (
    <div style={{ width: '100%', height: 'auto', position: 'relative' }}>
      <div ref={componentRef}>{props.children}</div>
      <button
        style={{
          ...style,
          ...props.style,
        }}
        className={`print-button hidePrint ${className}`}
        onClick={handlePrint}
      >
        <img src={printer} alt="printer" width="25px"></img>
        <span style={{ fontSize: '13px' }}>{props.text}</span>
      </button>
    </div>
  );
};

export default Printer;
