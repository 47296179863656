import { IInstrumentDto } from '../interfaces/responses/instrument/IInstrumentDto';
import { IInstrumentGraph } from '../interfaces/responses/instrument/IInstrumentGraph';

import { ApiEndpoints } from './ApiEndpoints';
import { BaseService } from './BaseService';

export abstract class InstrumentService extends BaseService {
  static getInstance() {
    return this;
  }

  public static getInstrumentGraph(instrumentId: string) {
    const instrumentGraph = this.callGet<IInstrumentGraph>(
      ApiEndpoints.GetInstrumentGraph(instrumentId),
    );
    return instrumentGraph;
  }

  public static getInstrumentDetails(instrumentId: string, clientId: string) {
    const url = ApiEndpoints.GetInstrument(instrumentId, clientId);

    const request = this.callGet<IInstrumentDto>(url);
    return request;
  }
}
