import api, { apiSetup } from '../config/api';

import { getSessionToken } from './LocalStorageService';

export function getLifestyleDetails() {
  const getLifestyleDetailsRequest = (resolve, reject) => {
    apiSetup();
    api
      .get('/api/profile/questions/lifestyle')
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getLifestyleDetailsRequest);
}

export function getBehaviourDetails(params) {
  const getBehaviourDetailsRequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Profiling/BehaviourDetails', params)
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getBehaviourDetailsRequest);
}

export function getCountryDetails() {
  const getCountryDetailsRequest = (resolve, reject) => {
    apiSetup();
    api
      .get('/api/profile/countries')
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getCountryDetailsRequest);
}

export function getFinalQuestions() {
  const getFinalQuestionsRequest = (resolve, reject) => {
    apiSetup();
    api
      .get('/api/profile/questions/soft')
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getFinalQuestionsRequest);
}

export function postUpdatedProfile(
  clientId,
  lifestyles,
  behaviours,
  avoidStates,
  finalQuestions,
  myDate,
) {
  const lifestyleMatchesId = lifestyles.thatsMe.map((thatsMe) => thatsMe.id);
  const lifestyleConflictsId = lifestyles.notMe.map((notMe) => notMe.id);
  const lifestyleNoneId = lifestyles.unsure.map((unsure) => unsure.id);
  const behavioursSupportId = behaviours.support.map((support) => support.answer);
  const behavioursAvoidId = behaviours.avoid.map((avoid) => avoid.answer);
  const behavioursUnsureId = behaviours.unsure.map((unsure) => unsure.answer);
  const avoidCountriesId = avoidStates.map((state) => state.id);
  const finalQuestionsId = finalQuestions.map((question) => question.id);

  const requestBody = {
    clientId: clientId,
    matches: behavioursSupportId,
    conflicts: behavioursAvoidId,
    none: behavioursUnsureId,
    avoidCountries: avoidCountriesId,
    soft: finalQuestionsId,
    lifestyleMatches: lifestyleMatchesId,
    lifestyleConflicts: lifestyleConflictsId,
    lifestyleNone: lifestyleNoneId,
    myDate: myDate,
  };
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/profile', requestBody)
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'network error' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
