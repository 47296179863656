import { identity, find, size, isNil } from 'lodash';
import React from 'react';

import Select from 'react-select';
import { Label, FormGroup } from 'reactstrap';

import PropTypes from 'prop-types';

const handleInputChange = (term, onLoadOptions) => {
  if (size(term) > 1) {
    onLoadOptions(term);
  }
};

const Typeahead = ({
  control,
  onChange,
  onLoadOptions,
  disabled,
  options,
  label,
  value,
  placeholder,
  getOptionValue,
  getOptionLabel,
  isLoading,
  ...props
}) => (
  <FormGroup controlid={control} style={{ display: 'flex', justifyContent: 'center' }}>
    <Label>{label}</Label>
    <Select
      id={control}
      name={control}
      value={find(options, (option) => getOptionValue(option) === value) || null}
      filterOption={identity}
      onChange={(option) =>
        onChange({
          target: { id: control, value: isNil(option) ? option : getOptionValue(option) },
        })
      }
      onInputChange={onLoadOptions && ((term) => handleInputChange(term, onLoadOptions))}
      {...{
        options,
        placeholder,
        disabled,
        getOptionValue,
        getOptionLabel,
        isLoading,
      }}
      {...props}
    />
  </FormGroup>
);

Typeahead.propTypes = {
  control: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onLoadOptions: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
};

Typeahead.defaultProps = {
  label: '',
  value: '',
  placeholder: 'Type a text...',
  disabled: false,
  options: [],
  getOptionValue: (option) => option._id,
  getOptionLabel: (option) => option.name,
};

export default Typeahead;
