import React from 'react';

import Lottie from 'react-lottie-player';
import { Container, Row, Col } from 'reactstrap';

import spinner from '../../_assets/lotties/spinner.json';

const LoadingPage = () => {
  return (
    <Container>
      <Row style={{ marginTop: '-80px' }}>
        <Col sm={12} className="text-center">
          {/* <Spinner color="primary" /> */}
          <Lottie
            animationData={spinner}
            loop={true}
            play={true}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </Col>
      </Row>
    </Container>
  );
};
export const LoadingPageSmall = () => {
  return (
    <div style={{ width: '250px', height: '250px', margin: 'auto' }}>
      <Lottie animationData={spinner} />
    </div>
  );
};

export default LoadingPage;
