import { ICompareDto } from 'interfaces/responses/compare/ICompareDto';

import api, { apiSetup } from '../config/api';

export interface CompareResponseInterface {
  numberOfComparisons: string;
  instrumentIds: string[];
}

export function addCompare(instrumentId: string, clientId: string) {
  const request = (resolve: Function, reject: Function) => {
    const requestBody = {
      clientId: clientId,
      instrumentId: instrumentId,
    };

    apiSetup();
    api
      .post('/v2/compare', requestBody)
      .then((res: any) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data as CompareResponseInterface);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'network error' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
export function getCompare(clientId: string): Promise<ICompareDto> {
  const request = (resolve: Function, reject: Function) => {
    apiSetup();
    api
      .get(`/v2/compare/${clientId}`)
      .then((res: any) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data as CompareResponseInterface);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'network error' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getCompareCount(clientId: string) {
  const request = (resolve: Function, reject: Function) => {
    apiSetup();
    api
      .get(`/v2/compare/count/${clientId}`)
      .then((res: any) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data as CompareResponseInterface);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'network error' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function deleteCompareInstrument(instrumentId: string, clientId: string) {
  const deleteCompareRequest = (resolve: Function, reject: Function) => {
    const requestBody = {
      clientId: clientId,
      instrumentId: instrumentId,
    };
    apiSetup();
    api
      .post('/v2/compare/delete', requestBody)
      .then((res: any) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data as CompareResponseInterface);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(deleteCompareRequest);
}

export function deleteAllInstrument(clientId: string) {
  const deleteCompareRequest = (resolve: Function, reject: Function) => {
    apiSetup();
    api
      .delete(`/v2/compare/deleteAll/${clientId}`)
      .then((res: any) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(deleteCompareRequest);
}
