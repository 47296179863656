import { isEmpty } from 'lodash';

import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';

import { Behaviour, SelectedAnswers } from '../../../model/InvestingProfile';
import { ButtonModal } from '../../commons';

import InfoModal from './InfoModal';

interface BehaviourListProps {
  behaviours: Behaviour[];
  selectedOptions: SelectedAnswers;
  toggleChoice: (answer: string) => void;
  imgLocation: string;
}

export default function BehaviourList(props: BehaviourListProps) {
  return (
    <>
      <Row className="justify-content-center">
        <ListGroup className="px-2 w-100" style={{ margin: '0 31px' }}>
          {!isEmpty(props.behaviours) &&
            props.behaviours.map((item: Behaviour) => (
              <Row key={item.answer}>
                <Col sm={12}>
                  <ListGroupItem
                    key={item.answer}
                    className={`p-3 mb-10 big-list-item ${
                      props.selectedOptions[item.answer]
                        ? item.isForMatch
                          ? 'support-selected'
                          : 'avoid-selected'
                        : ''
                    }`}
                    onClick={() => props.toggleChoice(item.answer)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="list-item-layout w-100">
                      <div className="list-item-img d-flex justify-content-center align-items-center p-1">
                        <img src={item.iconUrl} alt="questionnaire" width="70" />
                      </div>
                      <div className="list-item-description d-flex justify-content-start align-items-center flex-grow-1 p-1">
                        <span>{item.questionDesc}</span>
                      </div>
                      <div className="list-item-info d-flex justify-content-center align-items-start p-1">
                        <ButtonModal
                          component={InfoModal}
                          className="link hide-button"
                          name=""
                          params={{ item }}
                          onSubmit={undefined}
                          header={undefined}
                          showImage={undefined}
                          handleClick={undefined}
                          isColorIcon={true}
                          totalSelectedFilters={undefined}
                          isSearchModal={true}
                        />
                        <img
                          src="https://cdn.okoadviser.com/portal/icons/global/i_icon@3x.png"
                          alt="Info"
                          width="22"
                        />
                      </div>
                    </div>
                  </ListGroupItem>
                </Col>
              </Row>
            ))}
        </ListGroup>
      </Row>
    </>
  );
}
