import api from '../config/api';

import { getSessionToken } from './LocalStorageService';

export function getTotalPreferences(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/insights/totalPreferences', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getTop5Preferences(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/insights/Top5Preferences', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getTotalExclusions(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/insights/totalExclusions', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getTop5Exclusions(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/insights/Top5Exclusions', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getMostMatches(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/insights/MostMatchedCompanies', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getMostConflict(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/insights/MostConflictCompanies', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getMostRemoved(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/insights/MostRemoved', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getPortfolioMatches() {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .get('/api/insights/PortfolioMatches')
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getPortfolioConflict() {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .get('api/Insights/PortfolioConflicts')
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getProfileMatches() {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .get('/api/insights/ProfileMatches')
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getProfileConflict() {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .get('/api/insights/ProfileConflict')
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getAdviceActivityInsights(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/Insights/AdviceActivityInsights')
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getTotalAverages(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/insights/TotalAverages', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getAverageActions(params) {
  const request = (resolve, reject) => {
    api.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      Authorization: 'Bearer ' + getSessionToken(),
    });
    api
      .post('/api/insights/AverageActions', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else if (!res.ok && res.status === 409) {
          reject({ message: res.data.message });
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
