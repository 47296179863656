import React, { Fragment, PureComponent } from 'react';

import { toast } from 'react-toastify';
import { Container } from 'reactstrap';

import { getClient, updateClients } from '../../../../../service/ClientsService';
import { PageHeader, LoadingPage } from '../../../../commons';

import ClientEditorForm from './ClientEditorForm';

class ClientEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      client: {
        clientCoreId: '',
        adviserCoreId: '',
        clientFirstName: '',
        clientLastName: '',
        clientEmailAddress: '',
      },
      loading: false,
      loadingData: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      const params = { ClientCoreId: id };
      this.setState(() => ({ loadingData: true }));
      getClient(params)
        .then((response) => {
          this.setState(() => ({ client: response.adviserClient }));
        })
        .catch((err) => {
          this.setState({ showMessage: true, message: err.message });
          toast.error(err.message);
        })
        .finally(() => this.setState(() => ({ loadingData: false })));
    }
  }

  handleCancel() {
    const { id } = this.props.match.params;
    this.props.history.push(`/clients/${id}/clientDetails`);
  }

  handleSubmit(e) {
    const { id } = this.props.match.params;
    e.preventDefault();
    this.setState(() => ({ loading: true }));
    updateClients(this.state.client)
      .then(() => {
        this.props.history.push(`/clients/${id}/clientDetails`);

        // this.props.history.push('/clients');
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.message });
        toast.error(err.message);
      })
      .finally(() => this.setState(() => ({ loading: false })));
  }

  handleChange({ target: { id, value } }) {
    this.setState((state) => ({ client: { ...state.client, [id]: value } }));
  }

  render() {
    const { client, loading, loadingData } = this.state;
    return (
      <div className="slim-content">
        <Container>
          {loadingData && <LoadingPage />}
          {!loadingData && (
            <Fragment>
              <PageHeader subTitle="Details - edit" title="Profile" className="mb-10" />
              <ClientEditorForm
                onSubmit={(e) => this.handleSubmit(e)}
                onCancel={() => this.handleCancel()}
                onChange={(e) => this.handleChange(e)}
                {...{ client, loading }}
              />
            </Fragment>
          )}
        </Container>
      </div>
    );
  }
}

export default ClientEditor;
