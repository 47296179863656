import { IReasonResponse } from 'interfaces/responses/reasons/IReasonsDto';

import { ApiEndpoints } from './ApiEndpoints';
import { BaseService } from './BaseService';

export abstract class ReasonsService extends BaseService {
  static getInstance() {
    return this;
  }

  public static getReasons(instrumentId: string) {
    return this.callGet<IReasonResponse>(ApiEndpoints.GetReasons(instrumentId));
  }
}
