import { isEmpty } from 'lodash';
import { useEffect, useState, useContext } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';

import FilterAndSortBy from '_components/clients/ClientDetails/Results/FilterAndSortBy';

import close from '../../_assets/img/icons/global/closeBtn_ui@3x.png';
import filter from '../../_assets/img/icons/global/filter@3x.png';
import { search } from '../../service/SearchService';
import { FilterContext } from '../../store/FiltersContext';
import { LoadingPage } from '../commons';

import NoInstrumentView from './NoInstrumentView';
import './Search.scss';
import SearchResultList, { searchResultInterface } from './SearchResultList';

type SelectType = 'instrumentName' | 'symbol' | 'provider';
interface PlaceHolderMap {
  [key: string]: string;
}

const Search = () => {
  const { id: clientId } = useParams();
  const history = useHistory();
  const filterContext = useContext(FilterContext);
  const [selected, setSelected] = useState<SelectType>('instrumentName');
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [results, setResults] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const isFullData = true;
  const placeholderMap: PlaceHolderMap = {
    instrumentName: 'Type name',
    symbol: 'Type symbol',
    provider: 'Type provider',
  };
  const [placeholder, setPlaceholder] = useState<string>(placeholderMap['instrumentName']);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      setResults([]);
      setLoadingSearch(true);
      const currentSearchTerm = searchTerm;
      const { promise, cancel } = search(
        searchTerm,
        isFullData,
        filterContext.selectedFilters,
        filterContext.selectedSortBy,
        clientId,
      );
      promise
        .then((response: any) => {
          if (currentSearchTerm === searchTerm) {
            setResults(response.data.result);
          }
          setOpenFilters(true);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          if (currentSearchTerm === searchTerm) {
            setLoadingSearch(false);
          }
        });
      return () => {
        cancel();
      };
    }
  }, [
    searchTerm,
    isFullData,
    filterContext.selectedFilters,
    filterContext.selectedSortBy,
    clientId,
  ]);

  const handleSelectType = (selectType: SelectType) => {
    setSelected((currentState) => (currentState === selectType ? currentState : selectType));
    setPlaceholder(placeholderMap[selectType]);
    setSearchTerm('');
    setResults([]);
  };

  const redirectToDetailsPage = (value: searchResultInterface) => {
    if (value) {
      return history.push(`/clients/${clientId}/instruments/${value.id}/results`);
    }
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const renderContent = () => {
    if (loadingSearch) {
      return <LoadingPage />;
    }
    if (!searchTerm) {
      return <NoInstrumentView message="Search by Instrument Name, Symbol or Provider" />;
    }
    if (isEmpty(results)) {
      return <NoInstrumentView message={`Sorry, we can't find any data for ${searchTerm}`} />;
    }
    return (
      <SearchResultList
        clientId={clientId}
        results={results}
        redirectToDetailsPage={redirectToDetailsPage}
      />
    );
  };

  return (
    <div className="search-page">
      <h3 className="mt-40 mb-20 search-heading">Search</h3>
      <Row className="d-flex">
        <div
          style={{ width: '11em' }}
          className={`search-type symbol ${selected === 'instrumentName' && 'selected'}`}
          onClick={() => handleSelectType('instrumentName')}
        >
          Instrument name
        </div>
        <div
          className={`search-type symbol ${selected === 'symbol' && 'selected'}`}
          onClick={() => handleSelectType('symbol')}
        >
          Symbol
        </div>
        <div
          className={`search-type symbol ${selected === 'provider' && 'selected'}`}
          onClick={() => handleSelectType('provider')}
        >
          Provider
        </div>
      </Row>
      <Row className="search-container">
        <div className="search-box d-flex">
          <input
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
            className="search-input"
            placeholder={placeholder}
          />

          <div className="search d-flex" onClick={clearSearchTerm}>
            <div className="search-clear d-flex">
              <div className="clear-text">Clear</div>
              <div>
                <img className="clear-image" src={close} width="14" alt="close"></img>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex filter-box"
          onClick={(e) => {
            e.stopPropagation();
            setOpenFilters(true);
          }}
        >
          {!openFilters ? (
            <div className="filter d-flex">
              <div className="filter-icon">
                <img className="search-image" src={filter} width="25" alt="search-printer"></img>
              </div>
              <div className="filter">Filters</div>
            </div>
          ) : (
            <FilterAndSortBy isColorIcon={true} isSearchModal={true} />
          )}
        </div>
      </Row>
      {renderContent()}
    </div>
  );
};

export default Search;
