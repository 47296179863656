import api, { apiSetup } from '../config/api';

import {
  getSessionToken,
  getAdviserCore,
  setClientSelect,
  getClientSelect,
} from './LocalStorageService';
/**
 * @typedef {Object} Client
 * @property {string} clientCoreId
 * @property {string} adviserCoreId
 * @property {string} clientFirstName
 * @property {string} clientLastName
 * @property {string} clientEmailAddress
 * @property {boolean} hasActiveProfile
 * @property {string} updatedWhen
 * @property {number} profileRunId
 * @property {boolean} isViewed
 */
/**
 * @typedef {Object} GetAllClientsResponse
 * @property {number} httpStatusCode
 * @property {string} httpStatusMessage
 * @property {string} message
 * @property {boolean} isSuccess
 * @property {Array<Client>} adviserClients
 */
/**
 * 
 * @returns {Promise<GetAllClientsResponse>}
 */
export function getAllClients() {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Client/GetAllClients')
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
/** 
 * @param {{ClientCoreId: string}} params
*/
export function getClient(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Client/GetClient', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          setClientSelect(res.data.adviserClient);
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getClientSelected() {
  return getClientSelect();
}

export function addClients(params) {
  params.AdviserCoreId = getAdviserCore();
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Client/AddClient', params)
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          }
        } else {
          if (res.status === 400) {
            reject({ message: res.data.message });
          }
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function updateClients(params) {
  params.AdviserCoreId = getAdviserCore();
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Client/UpdateClient', params)
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          }
        } else {
          if (res.status === 400) {
            reject({ message: res.data.message });
          }
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function deleteClient(params) {
  params.AdviserCoreId = getAdviserCore();
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Client/DeleteClient', params)
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          }
        } else {
          if (res.status === 400) {
            reject({ message: res.data.message });
          }
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function addClientsBulk(clients) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Client/AddClientsBulk', { Clients: clients })
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          }
        } else {
          if (res.status === 400) {
            reject({ message: res.data.message });
          }
          if (res.problem === 'NETWORK_ERROR') {
            reject({ message: 'Network error' });
          }
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
