import React from 'react';

import { Form, FormGroup, Input, InputGroup, InputGroupAddon, Row, Col } from 'reactstrap';

import { ButtonLoading } from '../../../commons';

import './InviteCodeButton.scss';

const InviteCodeButton = ({ onSubmit, loading, inviteCode, onChange }) => (
  <Form onSubmit={onSubmit}>
    <FormGroup className="content-invite-code">
      <div className="ml-15">
        <label>Invite code</label>
      </div>
      <Row>
        <Col md={8} className="pl-0">
          <Input
            maxLength={7}
            type="text"
            name="inviteCode"
            id="inviteCode"
            value={inviteCode}
            onChange={onChange}
            required
          />
        </Col>
        <Col className="pt-3 pl-1">
          <div className="btn-addon">
            <ButtonLoading small loading={loading} label="Apply code" />
          </div>
        </Col>
      </Row>
      <Row className="text-center pt-2">
        Got an invite code from another Adviser? Enter it here to receive a discount on your plan.
      </Row>
    </FormGroup>
  </Form>
);

export default InviteCodeButton;
