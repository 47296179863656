export default class DateUtilsService {
  static formatDate(input) {
    if (!input) {
      return '';
    }
    const date = new Date(input);
    return date.toISOString().substring(0, 10);
  }

  static timestampToDate(input) {
    if (!input) {
      return '';
    }
    var timestamp = new Date(input * 1000);
    var date = timestamp.getDate();
    var month = timestamp.getMonth();
    var year = timestamp.getFullYear();
    var dateString = date + '-' + (month + 1) + '-' + year;
    return dateString;
  }

  static longFormatDate(input) {
    if (!input) {
      return '';
    }
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const dateTime = new Date(input);
    const date = dateTime.getDate();
    const month = months[dateTime.getMonth()];
    const year = dateTime.getFullYear();
    const dateString = date + ' ' + month + ' ' + year;
    return dateString;
  }
}
