import React, { Fragment } from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import uploadIcon from '../../_assets/img/icons/client/upload@3x.png';

const UploadPortfolio = ({ addPortfolioRedirect }) => {
  const history = useHistory();

  return (
    <Fragment>
      <Row className="justify-content-center mt-80 mb-30">
        <img src={uploadIcon} alt="laptop" width="180" />
      </Row>
      <Row>
        <Col className="text-center mb-20">
          <p style={{ fontSize: '17px' }}>Add an existing investment to be </p>
        </Col>
      </Row>
      <Row className="justify-content-center mb-20">
        <Col sm={6}>
          <Button onClick={() => addPortfolioRedirect()} className="btn-main-shadowless">
            Upload a portfolio
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <a onClick={() => history.push(`/clients`)} style={{ cursor: 'pointer', fontSize: '17px' }}>
          Back to client list
        </a>
      </Row>
    </Fragment>
  );
};

export default UploadPortfolio;
