import add from '../../../../_assets/img/results/add.png';

interface ProfilePortfolioTileProps {
  imageSrc: string;
  heading: string;
  subHeading: string;
  count: number | undefined;
  showAddIcon: boolean | null;
  isSelected: boolean;
  loadingResults: boolean;
  onClick: () => void;
}

export default function ProfilePortfolioTile(props: ProfilePortfolioTileProps) {
  const countDisplay = props.count ? props.count : '-';
  return (
    <div
      className={`tile ${props.loadingResults ? '' : 'cursor-hand'} ${
        props.isSelected ? 'isSelected' : ''
      }`}
      onClick={props.onClick}
      style={{ padding: '0' }}
    >
      <div className="custom-row" style={{ padding: '15px 10px' }}>
        <div className="mr-10 ml-10">
          {props.heading === 'APL' && props.subHeading === 'Alternatives' ? (
            <img
              className="img"
              src={props.imageSrc}
              alt="add"
              style={{ transform: 'scale(1.3)' }}
            />
          ) : (
            <img className="img" src={props.imageSrc} alt="add" />
          )}
        </div>
        <div className="text-col mr-10 ml-10">
          <span>{props.heading}</span>
          <br />
          {props.subHeading}
          <br />
          {(props.count !== 0 || props.subHeading !== 'Sustainability Overview') &&
            `(${countDisplay})`}
        </div>
      </div>
      <div className="add-col">
        <div>{props.showAddIcon && <img className="img" src={add} alt="add" />}</div>
      </div>
    </div>
  );
}
