import Select from 'react-select';
import { Label, FormGroup } from 'reactstrap';

interface DropdownInterface {
  onChange: Function;
  control?: string;
  value?: string | number;
  getOptionValue?: Function;
  getOptionLabel?: Function;
  label?: string;
  placeholder: string;
  options?: any;
  disabled?: boolean;
  isClearable?: boolean;
  className: string;
}
const Dropdown = ({
  control = '',
  label = '',
  placeholder = '',
  options,
  disabled = false,
  isClearable = false,
}: DropdownInterface) => {
  // const optionValue = getOptionValue();
  // const optionLabel = getOptionLabel();
  return (
    <FormGroup controlid={control}>
      {label && <Label>{label}</Label>}
      <Select
        // value={
        //   !isNil(value) ? find(options, (option: any) => getOptionValue(option) === value) : null
        // }
        name={control}
        isDisabled={disabled}
        // onChange={(option: any) =>
        //   onChange({
        //     target: { id: control, value: !isNil(option) ? getOptionValue(option) : null },
        //   })
        // }
        menuPlacement="auto"
        {...{
          options,
          // optionValue,
          // optionLabel,
          isClearable,
          placeholder,
          label,
        }}
      />
    </FormGroup>
  );
};

export default Dropdown;
