import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { Table } from 'reactstrap';

import Printer from '_components/app/Printer';
import Grade from '_components/commons/Grade';
import StartQuestionnaire from '_components/commons/StartQuestionnaire';
import StatusPill from '_components/commons/StatusPill';
import { GradeInterface } from 'model/Instrument';
import { getAllClients } from 'service/ClientsService';
import { deleteCompareInstrument, addCompare, getCompareCount } from 'service/CompareServices';

import triangle from '../../_assets/img/icons/global/Triangle@3x.png';

import './company.scss';

export interface searchResultInterface {
  matchToValueStatus: number;
  id: string;
  name: string;
  code: string;
  type: string;
  hasSustainabilityData: boolean;
  hasFundamentalData: boolean;
  isInApprovedProductList: boolean;
  grade: GradeInterface;
  sector: string;
  logo: string;
}

interface searchResultListProps {
  results: searchResultInterface[];
  clientId: string;
  redirectToDetailsPage: (param: searchResultInterface) => {};
}

type FilterPopup = 'name' | 'symbol' | 'sector' | 'type' | 'grade' | 'match';
const SearchResultList = (props: searchResultListProps) => {
  const { id } = useParams();
  const { results } = props;
  const filteredResults = results.filter((item) => Boolean(item.grade.gradeValue));

  const [compareInstrumentIds, setCompareInstrumentIds] = useState<string[]>([]);
  const [openFilterPopup, setOpenFilterPopup] = useState<FilterPopup>();
  const [isQuestionnaireVisible, setIsQuestionnaireVisible] = useState<boolean | null>(null);

  const sortList = [
    { id: '0', category: 'client list' },
    { id: '1', category: 'client list' },
  ];
  const tableHeaders = [
    { label: 'Name', key: 'name', sortKey: 'name' },
    { label: 'Symbol', key: 'symbol', sortKey: 'code' },
    { label: 'Sector', key: 'sector', sortKey: 'sector' },
    { label: 'Type', key: 'type', sortKey: 'type' },
    { label: 'Grade', key: 'grade', sortKey: 'gradeValue' },
    { label: 'Match', key: 'match', sortKey: 'matchToValueStatus' },
    { label: 'Compare', key: 'compare', sortKey: null },
  ];

  const classNames: any = {
    match: 'match',
    name: 'search-name',
    type: 'type',
    grade: 'grade',
    symbol: 'symbol',
  };

  useEffect(() => {
    getAllClients().then((response) => {
      const hasActiveProfile = response?.adviserClients.find((client) => {
        return client.clientCoreId === id;
      })?.hasActiveProfile;
      setIsQuestionnaireVisible(!hasActiveProfile);
    });

    getCompareCount(id).then((response: any) => {
      if (response.data?.instrumentIds.length === 0 || !response.data?.instrumentIds) {
        return;
      }
      setCompareInstrumentIds(response.data.instrumentIds);
    });
  }, [id]);

  const handleClick = (instrumentId: string) => {
    if (!compareInstrumentIds.includes(instrumentId)) {
      addCompare(instrumentId, id)
        .then((response: any) => {
          setCompareInstrumentIds(response.instrumentIds);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      deleteCompareInstrument(instrumentId, id)
        .then((response: any) => {
          setCompareInstrumentIds(response.instrumentIds);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const sortName = (id: any, popup: any) => {
    if (id === '0') {
      results?.sort((x: any, y: any) => (x[popup] > y[popup] ? 1 : -1));
    } else {
      results?.sort((x: any, y: any) => (y[popup] > x[popup] ? 1 : -1));
    }
  };

  const sortGrade = (id: any) => {
    const sortFunction = (a: any, b: any) => {
      const gradeA = a.grade.gradeValue.toUpperCase();
      const gradeB = b.grade.gradeValue.toUpperCase();
      return id === '0' ? gradeA.localeCompare(gradeB) : gradeB.localeCompare(gradeA);
    };
    results.sort(sortFunction);
  };

  const handleFilterPopupChange = (key: any) => {
    setOpenFilterPopup((currentState) => (currentState === key ? undefined : key));
  };

  const renderTableHeaders = () => {
    return tableHeaders.map((header, index) => {
      const { label, key, sortKey } = header;
      return (
        <th key={index}>
          {key !== 'compare' ? (
            <div
              className={`search-popup ${key === 'match' && 'match-text'}`}
              onClick={() => handleFilterPopupChange(key)}
            >
              {label}
              <span>
                <img
                  src={triangle}
                  alt="triangle"
                  width="13.5"
                  className={`popup-image ${openFilterPopup === key && 'open'}`}
                />
              </span>
              {sortKey && (
                <div
                  className={`dropdown-body ${classNames[key] || null} ${
                    openFilterPopup === key && 'open'
                  }`}
                >
                  {sortList.map((item) => (
                    <div
                      key={item.id}
                      className="dropdown-item"
                      onClick={() => {
                        sortKey === 'gradeValue' ? sortGrade(item.id) : sortName(item.id, sortKey);
                      }}
                      id={item.id}
                    >
                      {item.id === '0' ? (
                        <span>
                          {'A'}&nbsp;{'-'}&nbsp;{'Z'}
                        </span>
                      ) : (
                        <span>
                          {'Z'}&nbsp;{'-'}&nbsp;{'A'}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="search-popup compare d-flex">
              <div className="compare-text">Compare </div>
              {compareInstrumentIds.length !== 0 ? (
                <div className="compare-count">
                  <div className="client-count-result circle d-flex">
                    {compareInstrumentIds.length}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </th>
      );
    });
  };
  if (isQuestionnaireVisible === null) return <></>;
  if (isQuestionnaireVisible) {
    return (
      <StartQuestionnaire
        startInvestingProfile={() =>
          (window.location.pathname = `/clients/${props.clientId}/investing-profile/start`)
        }
      />
    );
  }
  if (filteredResults?.length !== 0) {
    return (
      <Printer style={{ right: '0', top: '-9.2em' }}>
        <Table borderless hover className="text-center">
          <tbody>
            <tr className="search-popup-heading mt-20">{renderTableHeaders()}</tr>

            {filteredResults.map((value) => {
              return (
                <tr key={value.id} className="cursor-hand">
                  <div
                    onClick={() => props.redirectToDetailsPage(value)}
                    className="search-result-list mt-10"
                  >
                    <td className="search-result-name" title={value.name}>
                      <img
                        className="company-icon"
                        src={value.logo}
                        alt="logo"
                        width="42"
                        height="42"
                      />
                      {value.isInApprovedProductList ? (
                        <span className="apl-tag-results" style={{ top: '-3.5em' }}>
                          APL
                        </span>
                      ) : (
                        <span className="mr-20"></span>
                      )}
                      {value.name}
                    </td>

                    <td className="search-result">
                      {!value.code || value.code === ':' ? '-' : value.code}
                    </td>
                    <td className="search-result">{value.sector}</td>
                    <td className="search-result">{value.type}</td>
                    {value.grade.gradeValue ? (
                      <td className="search-result">
                        <Grade className="grades-related-search" grade={value.grade} />
                      </td>
                    ) : (
                      <td></td>
                    )}
                    <td style={{ zIndex: '0' }} className="search-result">
                      <StatusPill
                        className="search-status-pill"
                        matchToValuesStatus={value.matchToValueStatus}
                      />
                    </td>
                    <td className="compare" onClick={(e) => e.stopPropagation()}>
                      <label className="form-control-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          className="research-checkbox search"
                          defaultChecked={compareInstrumentIds.includes(value.id) ? true : false}
                          onClick={() => handleClick(value.id)}
                        />
                      </label>
                    </td>
                  </div>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Printer>
    );
  }

  return <></>;
};

export default SearchResultList;
