import React from 'react';

import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import PropTypes from 'prop-types';

import StatesSortableItem from './StatesSortableItem';

const containerStyle = {
  padding: 15,
  margin: 10,
  flex: 1,
};

export default function StatesCardContainer(props) {
  const { id, items, className } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext
      id={id}
      items={items.map((item) => item.id)}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} style={containerStyle} className={`a-list ${className}`}>
        {items.map((item) => (
          <StatesSortableItem key={item.id} id={item.id} item={item} />
        ))}
      </div>
    </SortableContext>
  );
}

StatesCardContainer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      logoUrl: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};
