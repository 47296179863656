import { useHistory } from 'react-router-dom';
import { Row, Button } from 'reactstrap';

import info from '../../_assets/img/icons/client/Group 5@3x.png';
import upload from '../../_assets/img/icons/client/Group 6@3x.png';
import linkIcon from '../../_assets/img/icons/client/Group 7@3x.png';
import plus from '../../_assets/img/icons/client/Group 19@3x.png';
import portfolioIcon from '../../_assets/img/icons/client/import-portfolio-apl.svg';
import profileIcon from '../../_assets/img/icons/client/profile-with-background.svg';

import './client.scss';

const CLientListShortcuts = ({ list }: any) => {
  const history = useHistory();

  const listLength = list.length;
  const listArray = ['list-0', 'list-1', 'list-2', 'list-3'];
  const shortcutsClassName =
    listLength > 4 || listLength === 4 ? listArray[3] : listArray[listLength];

  return (
    <div className={`shortcut-container ${shortcutsClassName}`}>
      <Row className="client-list-shortcut">
        <div className="shortcut-item">
          <img src={profileIcon} alt="import a list" className="shortcut-image-left" />
          <Button color="link" onClick={() => history.push('/clients/importClients')}>
            <h5 className="shortcut-text">
              Import a <br /> Client List
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              <img src={upload} alt="upload" className="shortcut-image-right" />
            </div>
          </div>
        </div>
        <div className="shortcut-item">
          <img src={profileIcon} alt="import a list" className="shortcut-image-left" />
          <Button color="link" onClick={() => history.push('/clients/add')}>
            <h5 className="shortcut-text">
              Add a <br /> Client
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              <img src={plus} alt="plus" className="shortcut-image-right" />
            </div>
          </div>
        </div>
        <div className="shortcut-item">
          <img src={linkIcon} alt="import a list" className="shortcut-image-left link" />
          <a
            href={`https://okoadviser.com/category/help-and-guides/`}
            target="_blank"
            rel="noreferrer"
            style={{ margin: 'auto' }}
          >
            <h5 className="shortcut-text">
              Additional <br /> Resources
            </h5>
          </a>
          <div className="shortcut-image">
            <div>
              {' '}
              <img src={info} alt="info" className="shortcut-image-right" />
            </div>
          </div>
        </div>
        <div className="shortcut-item">
          <img src={portfolioIcon} alt="import a list" className="shortcut-image-left" />
          <Button color="link" onClick={() => history.push('/clients/portfoliosUpload')}>
            <h5 className="shortcut-text">
              Import a <br /> Portfolio
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              {' '}
              <img src={upload} alt="upload" className="shortcut-image-right" />
            </div>
          </div>
        </div>
        <div className="shortcut-item">
          <img src={portfolioIcon} alt="import a list" className="shortcut-image-left" />
          <Button color="link" onClick={() => history.push('/aplUpload')}>
            <h5 className="shortcut-text">
              Import a <br /> APL
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              <img src={upload} alt="upload" className="shortcut-image-right" />
            </div>
          </div>
        </div>
        <div className="shortcut-item">
          <img src={profileIcon} alt="import a list" className="shortcut-image-left link" />
          <Button color="link" onClick={() => history.push('/account')}>
            <h5 className="shortcut-text">
              Adviser <br /> Center
            </h5>
          </Button>
          <div>
            <div className="shortcut-image">
              <img src={info} alt="info" className="shortcut-image-right" />
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};
export default CLientListShortcuts;
