import React from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

import copy from '../../_assets/img/icons/client/copy.png';
import send from '../../_assets/img/icons/client/send.png';
import ButtonLoading from '../commons/ButtonLoading';

interface SendModalProp {
  params: string;
}

const SendModal = (props: SendModalProp) => {
  const history = useHistory();
  const host = `${window.location.protocol}//${window.location.host}`;
  const link = `${host}/clients/${props.params}/investing-profile/profile-builder`;
  const path = `/clients/${props.params}/investing-profile/start`;

  // We change the entry URL for the clients so that it is different to the Adviser
  // Due to the inability to see the Feature Flags
  // if (profileContext.featureFlags.profileQuestionsV2) {
  //   link = `${host}/clients/${props.params}/investing-profile/welcome`;
  // }

  const copyToClipboard = async () => {
    const modalText = `
I am pleased to provide you with the link to your responsible investing profile.
This is an interactive questionnaire that will help us to define your responsible investing parameters, which we can then use to analyze your investments, conduct research and make recommendations.
Please click the link below and follow the instructions to complete your profile. This should take around 5 minutes. We will be notified when it's complete.
${link}

Let us know if you have any questions.`;
    await navigator.clipboard.writeText(modalText);
  };

  const modalText1 = `I am pleased to provide you with the link to your responsible investing profile.

  This is an interactive questionnaire that will help us to define your responsible investing parameters, which we can then use to analyze your investments, conduct research and make recommendations.

  Please click the link below and follow the instructions to complete your profile. This should take around 5 minutes. We will be notified when it's complete.
  
  `;

  const modalText2 = `

Let us know if you have any questions.`;

  const handleProfileClick = () => {
    history.push(path);
  };

  return (
    <Container className="container-body-modal text-center pr-5 pl-5 pb-5">
      <img src={send} width="150" />
      <h2 className="mt-3">Send Profile</h2>
      <p className="mt-3 font-weight-bolder font-22">
        Copy and paste the below into an email to your client
      </p>
      <div
        className="mt-3 text-left pt-3 pb-3 pl-4 pr-4 send-modal-text"
        style={{ whiteSpace: 'pre-line' }}
      >
        {modalText1}
        <Button color="link" onClick={() => handleProfileClick()}>
          profile link
        </Button>
        {modalText2}
      </div>
      <div className="d-flex">
        <Button
          className="btn-main-shadowless ml-3 mt-3 mr-3"
          style={{ maxWidth: '250px' }}
          onClick={copyToClipboard}
        >
          Copy&nbsp;
          <img src={copy} width="20" />
        </Button>
        <ButtonLoading
          className="btn-main-shadowless mt-3"
          style={{ maxWidth: '250px' }}
          label="Complete now"
          onClick={() => history.push(path)}
        />
      </div>
    </Container>
  );
};

export default SendModal;
