import { MouseEventHandler, useRef } from 'react';

import { useReactToPrint } from 'react-to-print';

import printer from '../../_assets/img/icons/global/printer.svg';
import '../app/buttonPrinter.scss';

type ModalProps = {
  isVisible: boolean;
  children: React.ReactNode;
  onClose: MouseEventHandler<HTMLButtonElement>;
  hasPrintButton?: boolean;
};
const Modal = (props: ModalProps) => {
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { isVisible, children, onClose } = props;
  if (!isVisible) return null;
  return (
    <div
      style={{
        borderRadius: '10px',
        padding: '20px',
        paddingRight: '80px',
        paddingLeft: '80px',
        boxShadow: '0 5px 7px rgba(0,0,0,0.3)',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '100',
        backgroundColor: 'white',
        margin: '100px',
        overflowY: 'scroll',
        maxHeight: '78%',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <button
          style={{ border: '0', padding: '0', backgroundColor: 'transparent' }}
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#3e61df"
            style={{ width: '40px', height: '40px' }}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {props.hasPrintButton && (
          <button
            className={`print-button hidePrint`}
            style={{ position: 'initial' }}
            onClick={handlePrint}
          >
            <img src={printer} alt="printer" width="25px"></img>
          </button>
        )}
      </div>
      <div style={{ padding: '10px' }}></div>
      <div ref={componentRef} style={{ width: '100%' }}>
        {children}
      </div>
    </div>
  );
};
export default Modal;
