import React, { useRef } from 'react';

// import Parser from 'html-react-parser';
import { UncontrolledPopover } from 'reactstrap';

import { IInstrumentSmallDto } from 'interfaces/responses/instrument/IInstrumentSmallDto';
import { IIssueDto } from 'interfaces/responses/instrument/IIssueDto';

// import { nanoid } from 'nanoid';
import BusinessActivityInfo from './BusinessActivityInfo';
import './BusinessActivityWithPopup.scss';

interface BusinessActivityWithPopupProps {
  item: IIssueDto;
  className?: string;
  instrumentName?: string;
  dataLastUpdated: string | undefined;
  affectedInstruments?: IInstrumentSmallDto[] | undefined | null;
  reason: string;
}

function BusinessActivityWithPopup(props: BusinessActivityWithPopupProps) {
  const { item, className, instrumentName, dataLastUpdated, affectedInstruments, reason } = props;
  // const key = nanoid();
  const ref = useRef(null);

  return (
    <>
      <div className="mr-3 mb-3">
        <img
          src={item.iconUrl}
          alt={`${item.icon}`}
          className={`business-activity-icons cursor-hand ${className}`}
          // id={`item-${key}`}
          ref={ref}
        />
      </div>
      <UncontrolledPopover
        placement="top"
        // target={`item-${key}`}
        target={ref}
        trigger="legacy"
        className="my-custom-popover"
        instrumentName={instrumentName}
        dataLastUpdated={dataLastUpdated}
      >
        <h6 className="text-center">{item.title}</h6>
        {/* {Parser(item.comment)} */}
        <BusinessActivityInfo
          title={item.title}
          reason={reason}
          dataLastUpdated={dataLastUpdated}
          affectedInstruments={affectedInstruments}
        />
      </UncontrolledPopover>
    </>
  );
}

export default BusinessActivityWithPopup;
