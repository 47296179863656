import api, { apiSetup } from '../config/api';

import { getSessionToken } from './LocalStorageService';

export function getSubscriptionTypes() {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .get('/v2/subscriptions')
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function createSubscription(subscriptionId, stripeToken) {
  const request = (resolve, reject) => {
    apiSetup();
    const params = {
      subscriptionId: subscriptionId,
      stripeToken: stripeToken,
    };
    api
      .post('/api/Payments/CreateSubscription', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else {
          reject({ message: res.data.message });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function cancelStripeSubcriptions() {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Payments/CancelStripeSubscriptions')
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
