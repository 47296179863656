import api, { apiSetup } from '../config/api';

import { getSessionToken } from './LocalStorageService';

export function updateAdviserUser(params) {
  const updateAdviserUserRequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/accounts/UpdateAdviserUser', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(updateAdviserUserRequest);
}

export function updateCompany(params) {
  const companyUpdateRequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/v2/accounts/updateCompany', params)
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          }
        } else {
          if (res.status === 409) {
            reject({ message: res.data.message });
          }
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(companyUpdateRequest);
}

export function getAdviserDetails() {
  const getAdviserDetailsRequest = (resolve, reject) => {
    apiSetup();
    api
      .get('/v2/accounts/GetAdviserDetails')
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: 'something went wrong' });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getAdviserDetailsRequest);
}

export function updateAdviser(params) {
  const updateAdviserUserRequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/v2/account/adviser', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(updateAdviserUserRequest);
}

export function getAdviser(params) {
  const updateAdviserUserRequest = (resolve, reject) => {
    apiSetup();
    api
      .get('/v2/account/adviser')
      .then((res) => {
        if (res.ok && res.status === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(updateAdviserUserRequest);
}
