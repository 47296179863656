/* eslint-disable prettier/prettier */
// import { useState } from 'react';
import { useEffect, useState, useContext } from 'react';

import Lottie from 'react-lottie-player';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row, Button } from 'reactstrap';

import { LoadingPage } from '_components/commons';
import { getFinalQuestions, postUpdatedProfile } from 'service/ProfileService';
import { ProfileContext } from 'store/ProfileContext';

import animationData from '../../../_assets/lotties/generating_results.json';

import tipIcon from './../../../_assets/img/icons/global/tip.png';
import arrowIcon from './../../../_assets/img/icons/graphics/values-arrow@3x.png';
import './styles.scss';

export default function Final() {
  const history = useHistory();
  const params = useParams();
  const profileContext = useContext(ProfileContext);
  const [showModal, setShowModal] = useState(false);
  const [submittingResults, setSubmittingResults] = useState(false);
  const [finalQuestions, setFinalQuestions] = useState([]);

  const handleChange = (e) => {
    const id = e.target.id;
    const currentCheckbox = finalQuestions.find((question) => question.id === id);

    currentCheckbox.checked = !currentCheckbox.checked;
    let newFinalQuestions = [...finalQuestions];

    newFinalQuestions.map((obj) => obj.id === id && { ...obj, checked: currentCheckbox.checked });
    setFinalQuestions(newFinalQuestions);
  };

  const handleSubmit = () => {
    const clientId = params.id;
    const lifestyles = JSON.parse(localStorage.getItem('lifestyles'));
    const behaviours = JSON.parse(localStorage.getItem('behaviours'));
    const selectedFinalQuestions = finalQuestions.filter((question) => question.checked);
    const avoidStates = JSON.parse(localStorage.getItem('avoidStates'));
    const myDate = new Date().toLocaleDateString();

    setSubmittingResults(true);
    postUpdatedProfile(
      clientId,
      lifestyles,
      behaviours,
      avoidStates,
      selectedFinalQuestions,
      myDate,
    ).then(() => {
      setTimeout(function () {
        if (profileContext.isLoggedIn) {
          history.push(`/clients/${clientId}/results`);
        } else {
          window.location.href = 'https://okoadviser.com';
        }
      }, 5000);
    });
  };

  useEffect(() => {
    getFinalQuestions()
      .then((response) => {
        let finalQuestions = response.softQuestions;
        finalQuestions = finalQuestions.map((obj) => ({ ...obj, checked: false }));
        setFinalQuestions(finalQuestions);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  const mainModal = () => {
    return (
      <>
        <Container className="profile-feature">
          <Row className="mt-5 justify-content-center">
            <p className="text-center profile-header" style={{ width: '100%' }}>
              Finally, what are you most interested in achieving with your investments?
            </p>
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col sm={4} className="category-panel">
              <Row className="pl-3 mb-10">
                <p className="category-title" style={{ marginLeft: '60px' }}>
                  Your goals &rarr;
                </p>
              </Row>
              <Row className="mb-40">
                <Container className="tips-panel" style={{ width: '70%', padding: '20px' }}>
                  <div className="tips-icon">
                    <img src={tipIcon} />
                  </div>
                  <div className="tips-text">
                    <p className="category-subtitle" style={{ fontSize: '16px' }}>
                      What are you most interested in achieving with your investments? Select{' '}
                      <b style={{ color: '#3e61DF' }}>any</b> that apply.
                      <br />
                      <br />
                      Tap done when you’ve finalised your selections
                    </p>
                  </div>
                </Container>
              </Row>
              <Row className="justify-content-center">
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="btn-main-shadowless confirm-button"
                >
                  <div className='d-flex align-items-center justify-content-center'>Done</div>
                </Button>
              </Row>
            </Col>
            <Col sm={8} className="category-panel">
              <div className="check-box-wrapper">
                {finalQuestions.map((item, index) => (
                  <li key={index} className="checkbox-item">
                    <div>
                      <label className="check-box">
                        <span className="check-box-input">
                          <input
                            type="checkbox"
                            id={item.id}
                            checked={item.checked}
                            className="check-box-inner"
                            onChange={handleChange}
                          />
                          <span className="customised-checkbox"></span>
                        </span>
                        <span className="check-box-label">
                          <span className="options-content">{item.question}</span>
                        </span>
                      </label>
                    </div>
                  </li>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const loadingModal = () => {
    return <LoadingPage />;
  };

  const confirmationModal = () => {
    return (
      <div className="overlay">
        <Container className="showup-modal">
          <Row className="mt-10 mb-10 justify-content-center">
            <img src={arrowIcon} width="150" />
          </Row>
          <Row className="text-center justify-content-center">
            <div className="header">
              <p>Nice work, you’re all done!</p>
            </div>
          </Row>
          {profileContext.isLoggedIn ? (
            <Row className="mt-20">
              <p
                style={{ position: 'relative', left: 80, width: '75%' }}
                className="d-block text-center comment analysis"
              >
                Now submit your profile and your Adviser will complete their analysis.
              </p>
            </Row>
          ) : (
            <Row className="mt-20">
              <p className="d-block text-center w-100 comment">
                Now submit your profile and we’ll send your answers back to your Adviser. Once
                received, your Adviser will complete their analysis and contact you.
              </p>
            </Row>
          )}
          <Row className="justify-content-center mt-10">
            <Col sm={5} className="mb-40">
              <div className="text-center">
                <Button className="btn-blue btn-main-shadowless" onClick={() => handleSubmit()}>Submit</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const renderLottie = () => {
    return (
      <div className="client-steps">
        <div className="text-center justify-content-center d-flex header">
          <p className="mt-5">{`Analysing your responses. Generating your results.`}</p>
        </div>
        <Lottie animationData={animationData} loop={true} play={true} rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }} />
      </div>
    );
  };

  return (
    <>
      {finalQuestions.length === 0 && loadingModal()}
      {finalQuestions.length !== 0 && submittingResults === false && mainModal()}
      {showModal && !submittingResults && confirmationModal()}
      {submittingResults && profileContext.isLoggedIn && renderLottie()}
    </>
  );
}
