import React from 'react';

import { Button, Col, Form, FormGroup, Row } from 'reactstrap';

import { ButtonLoading, EmailField, TextField } from '../../../../commons';

const ClientEditorForm = ({ client, loading, onSubmit, onCancel, onChange }) => (
  <Form onSubmit={onSubmit}>
    <FormGroup>
      <TextField
        label="First name"
        control="clientFirstName"
        onChange={onChange}
        value={client.clientFirstName}
        required
      />
    </FormGroup>
    <FormGroup>
      <TextField
        label="Last name"
        control="clientLastName"
        onChange={onChange}
        value={client.clientLastName}
        required
      />
    </FormGroup>
    <FormGroup>
      <EmailField
        label="Email"
        control="clientEmailAddress"
        onChange={onChange}
        value={client.clientEmailAddress}
        required
      />
    </FormGroup>
    <Row className="mb-40 mt-30">
      <Col sm={12}>
        <p className="text-comment">
          We'll never contact your client, we just require this information for security reasons.
        </p>
      </Col>
    </Row>
    <Row>
      <Col sm={6} className="mb-20">
        <Button className="btn-modal" onClick={onCancel}>
          Cancel
        </Button>
      </Col>
      <Col sm={6}>
        <ButtonLoading small className="btn-modal" loading={loading} label="Save client details" />
      </Col>
    </Row>
  </Form>
);

export default ClientEditorForm;
