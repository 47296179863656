import { getAllOutputs } from '../service/AdvisorOutputService';
import { createUpdateProfile, getProfileMatches } from '../service/ProfilingService';

export const InvestingProfile = {
  choice: {
    avoid: [],
    support: [],
    important1: [],
    important2: [],
    important3: [],
    important4: [],
  },

  data: {
    clientId: null,
    conflicts: [],
    matches: [],
    alternatives: [],
    outputs: [],
    adviceOutputs: [],
    isResultsFromPortfolio: false,
  },

  load(clientId, filters = [], forceReload = false) {
    return new Promise((resolve, reject) => {
      if (this.data.clientId === clientId && !forceReload) {
        return resolve();
      }

      getProfileMatches({ ClientCoreId: clientId })
        .then((response) => {
          let { conflicts, matches, alternatives, isResultsFromPortfolio } = response;
          getAllOutputs({ ClientCoreId: clientId })
            .then((response) => {
              let { adviceOutputs } = response;
              this.data = {
                clientId,
                conflicts,
                matches,
                alternatives,
                adviceOutputs,
                isResultsFromPortfolio,
              };
              resolve();
            })
            .catch(reject);
        })
        .catch(reject);
    });
  },

  save(clientId) {
    return createUpdateProfile({
      ClientCoreId: clientId,
      profileQuestions: [
        {
          question: '001',
          answers: this.choice.support,
        },
        {
          question: '002',
          answers: this.choice.important1,
        },
        {
          question: '003',
          answers: this.choice.important2,
        },
        {
          question: '004',
          answers: this.choice.important3,
        },
        {
          question: '005',
          answers: this.choice.important4,
        },
        {
          question: '006',
          answers: this.choice.avoid,
        },
      ],
    });
  },

  avoid(ids) {
    this.choice.avoid = ids;
    return this;
  },

  support(ids) {
    this.choice.support = ids;
    return this;
  },

  important1(ids) {
    this.choice.important1 = ids;
    return this;
  },
  important2(ids) {
    this.choice.important2 = ids;
    return this;
  },
  important3(ids) {
    this.choice.important3 = ids;
    return this;
  },
  important4(ids) {
    this.choice.important4 = ids;
    return this;
  },
  getConflicts() {
    return this.data.conflicts || [];
  },
  getMatches() {
    return this.data.matches || [];
  },
  getAlternatives() {
    return this.data.alternatives || [];
  },

  getOutputs() {
    return this.data.adviceOutputs || [];
  },

  getOutcomes() {
    return this.data.adviceOutputs || [];
  },

  getIsResultsFromPortfolio() {
    return this.data.isResultsFromPortfolio;
  },
};
