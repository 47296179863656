import React from 'react';

import { Table } from 'reactstrap';

import { showTableData } from '../../../../_helpers/instrument';

interface SustainabilityDataProps {
  dataLastUpdated?: string;
  numberOfActiveDataSources?: string;
}
export default function SustainabilityData(props: SustainabilityDataProps) {
  return (
    <div style={{ padding: '0 30px' }}>
      <br />
      <div className="table-rounded">
        <Table responsive striped bordered className="text-center m-0">
          <thead>
            <tr>
              <th>{showTableData(props.dataLastUpdated)}</th>
              <th>{showTableData(props.numberOfActiveDataSources)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="font-12 blue-font-color">{`Sustainability data last updated`}</td>
              <td className="font-12 blue-font-color">{`Active data source available`}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <br />
    </div>
  );
}
