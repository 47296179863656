import { useState, useEffect, useMemo } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';
import { Button } from 'reactstrap';

import Modal from '_components/commons/Modal';

import percentageIcon from '../../../../_assets/img/icons/profile/PercentageIcon.svg';
import infoModalIcon from '../../../../_assets/img/icons/profile/infoModalIcon.png';
import { getBehaviourDetails } from '../../../../service/ProfileService';
import { getActiveProfileByClientId } from '../../../../service/ProfilingService';
// import { TipCard } from '../../../commons';
import { match, conflict } from '../Advice/Outputs/PrioritisedMatchesConflicts';

import ExposureChart from './ExposureChart';
import PortfolioWeighting from './PortfolioWeighting';
import { IResultsSummaryResponse, SustainabilityResultsInterface } from './Results';
import './sustainabilityOverview.scss';

interface IBehavior {
  id: string;
  question: string;
  questionDesc: string;
  answer: string;
  imageFileName: string;
  iconUrl: string;
  category: string;
  isForMatch: boolean;
  isForConflict: boolean;
  behaviourDetail: string;
}
interface ISustainabilityOverview {
  sustainabilityResults: SustainabilityResultsInterface;
  resultsSummary: IResultsSummaryResponse;
}
const SustainabilityOverview = ({
  sustainabilityResults,
  resultsSummary,
}: ISustainabilityOverview) => {
  const [matches, setMatches] = useState<match[]>([]);
  const [conflicts, setConflicts] = useState<match[]>([]);
  const [isPortfolioExposureVisible, setIsPortfolioExposureVisible] = useState(false);
  const [isExposureBreakdownVisible, setIsExposureBreakdownVisible] = useState(false);

  const [behaviors, setBehaviors] = useState<IBehavior[]>();
  const history = useHistory();
  const params = useParams();
  const clientId = params.id;

  useEffect(() => {
    getActiveProfileByClientId(clientId)
      .then((response) => {
        setMatches(response.matches);
        setConflicts(response.conflicts);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [clientId]);

  useEffect(() => {
    getBehaviourDetails()
      .then((response) => {
        setBehaviors(response.profileQandAWithBehaviourDetails);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  const selectedBehaviors = useMemo(() => [...matches, ...conflicts], [matches, conflicts]);

  const unselectedBehaviors = useMemo(
    () =>
      behaviors?.filter(
        (behavior) =>
          !selectedBehaviors.some((matchAndConflict) => behavior.answer == matchAndConflict.id),
      ),
    [behaviors, selectedBehaviors],
  );

  if (sustainabilityResults) {
    return (
      <>
        <div style={{ padding: '20px' }}></div>
        <div className="sustainability-container">
          <div>
            <div>
              <div
                style={{
                  display: 'inline-flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <h4 style={{ marginBottom: '0' }}>Portfolio Exposure</h4>
                <button
                  style={{ border: '0', padding: '0', backgroundColor: 'transparent' }}
                  onClick={() =>
                    void setIsPortfolioExposureVisible((prevIsVisible) => !prevIsVisible)
                  }
                >
                  <img src={infoModalIcon} alt="info" width={20} />
                </button>
              </div>
              <p>
                The Portfolio Exposure shows your clients sustainability profile selections
                (positive and negative) weighted to the asset allocation of the portfolio.
              </p>
            </div>
            <Modal
              onClose={() => void setIsPortfolioExposureVisible(false)}
              isVisible={isPortfolioExposureVisible}
            >
              <h5>Portfolio Exposure</h5>
              <p>
                The Portfolio Exposure shows your clients sustainability profile selections
                (positive and negative) weighted to the asset allocation of the portfolio. This is a
                powerful feature that will give you one of the truest views of how well aligned a
                client&apos;s portfolio is to their sustainability values. This section is based on
                your client&apos;s sustainability profile selections and does not include exposure
                to unselected profile options.
              </p>
              <h6>How is it calculated?</h6>
              <p>
                The pie chart is based on the exposure to each of the profile selections made by
                your client. To create the chart, Oko systems look through to all the holdings
                (including Funds and Fund of Funds etc) to calculate the exposure to each profile
                selection. As this data is based on your client’s sustainability profile selections
                and does not include exposure to unselected profile options, it is weighted to the
                asset allocation of the portfolio and then normalised to create a visual and easily
                digestible chart.
              </p>
              <p style={{ fontStyle: 'italic', fontSize: '11px' }}>
                The data across all of Oko Adviser, including the Portfolio results is based on data
                provided by external suppliers. The charts and percentages created are based on the
                available the available holding data as provided. Oko Adviser does not guarantee the
                accuracy of any information provided by a third party service provider does not
                exert any editorial control over such information. Oko Adviser does not guarantee
                the timeliness, sequence, accuracy or completeness of any information provided by a
                service provider.
              </p>
            </Modal>
          </div>
          {resultsSummary && !resultsSummary?.hasPercentageInPortfolio ? (
            <Container className="mt-30 sustainability-overview-container">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src={percentageIcon} alt="percentage" width={250} />
                <p style={{ textAlign: 'center', lineHeight: '30px' }}>
                  Add percentages to your portfolio upload and see complete<br></br> portfolio
                  allocation to your clients profile.
                </p>
                <Button
                  className="blue-btn"
                  onClick={() => (window.location.pathname = '/clients/portfoliosUpload')}
                >
                  Edit Portfolio Upload
                </Button>
              </div>
              <Row>
                <div className="mt-60 ml-20">
                  <h4>{`Portfolio Exposure: "Support" ${
                    matches.length > 1 ? 'selections' : 'selection'
                  }`}</h4>
                  <div className="ml-10">
                    {matches.map((match: match) => {
                      return (
                        <img
                          src={match.imageFileName}
                          className="icon"
                          alt="icon"
                          height="20"
                          width="20"
                          key={match.id}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="mt-60 ml-20">
                  <h4>{`Portfolio Exposure: "Avoid" ${
                    matches.length > 1 ? 'selections' : 'selection'
                  }`}</h4>
                  <div className="ml-10">
                    {conflicts.map((conflict: conflict) => {
                      return (
                        <img
                          src={conflict.imageFileName}
                          className="icon avoid"
                          alt="icon"
                          height="20"
                          width="20"
                          key={conflict.id}
                        />
                      );
                    })}
                  </div>
                </div>

                <div className="mt-60 ml-20">
                  <h4>{`Portfolio Exposure: Unselected`}</h4>
                  <div className="ml-10">
                    {unselectedBehaviors?.map((behavior: any) => {
                      return (
                        <img
                          src={behavior.iconUrl}
                          className="icon unselected"
                          alt="icon"
                          height="20"
                          width="20"
                          key={behavior.answer}
                        />
                      );
                    })}
                  </div>
                </div>
              </Row>
            </Container>
          ) : (
            <>
              <Container className="sustainability-overview-container">
                <Row className="portfolio-weighting-row">
                  <div
                    className="portfolio-left-div"
                    style={{ padding: '3px', width: '240px' }}
                  ></div>
                  {resultsSummary && <PortfolioWeighting resultsSummary={resultsSummary} />}
                  <div
                    style={{
                      padding: '3px',
                      display: 'flex',
                      width: '240px',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                      <div
                        style={{
                          borderRadius: '100%',
                          backgroundColor: '#7be0d3',
                          width: '20px',
                          height: '20px',
                          gap: '3px',
                          alignItems: 'center',
                        }}
                      ></div>
                      <span>Profile Selection &quot;Support&quot;</span>
                    </div>
                    <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                      <div
                        style={{
                          borderRadius: '100%',
                          backgroundColor: '#bd5f80',
                          width: '20px',
                          height: '20px',
                        }}
                      ></div>
                      <span>Profile Selection &quot;Avoid&quot;</span>
                    </div>
                  </div>
                </Row>
              </Container>
            </>
          )}
        </div>
        <div style={{ padding: '20px' }}></div>
        {!(resultsSummary && !resultsSummary?.hasPercentageInPortfolio) ? (
          <Container style={{ width: '100%', maxWidth: '1100px', margin: '0px auto' }}>
            {/* <Row className="portfolio-weight-tipcard">
                  <TipCard
                    weighting="weight-tipcard"
                    message={`This section is based on ${sustainabilityResults.portfolioName} sustainability profile selections and does not include exposure to unselected profile options.`}
                  />
                </Row> */}
            <div>
              <div style={{ marginLeft: '30px', marginRight: '30px' }}>
                <div
                  style={{
                    display: 'inline-flex',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <h4 style={{ marginBottom: '0' }}>Exposure Breakdown</h4>
                  <button
                    style={{ border: '0', padding: '0', backgroundColor: 'transparent' }}
                    onClick={() =>
                      void setIsExposureBreakdownVisible((prevIsVisible) => !prevIsVisible)
                    }
                  >
                    <img src={infoModalIcon} alt="info" width={20} />
                  </button>
                </div>
                <p>
                  The Exposure Breakdown shows the breakdown of each instrument and the associated
                  underlying holdings based on each of your client’s sustainability profile
                  selections. Giving you a line by line detail of how your client is exposure to
                  each area.
                </p>
              </div>
              <Modal
                onClose={() => void setIsExposureBreakdownVisible(false)}
                isVisible={isExposureBreakdownVisible}
              >
                <h5>Exposure Breakdown</h5>
                <p>
                  The Exposure Breakdown shows the breakdown of each instrument and the associated
                  underlying holdings based on each of your client’s sustainability profile
                  selections. Giving you a line by line detail of how your client is exposure to
                  each area. This section is based on your client’s sustainability profile
                  selections and does not include exposure to unselected profile options and is not
                  weighted to the asset allocation of the portfolio.
                </p>
                <h6>How is it calculated?</h6>
                <p>
                  The breakdown is based on the exposure to each of the profile selections made by
                  your client. Oko systems look through to all the holdings (including Funds and
                  Fund of Funds etc) to calculate the exposure to each profile selection.
                </p>
                <p style={{ fontStyle: 'italic', fontSize: '11px' }}>
                  The data across all of Oko Adviser, including the Portfolio results is based on
                  data provided by external suppliers. The charts and percentages created are based
                  on the available the available holding data as provided. Oko Adviser does not
                  guarantee the accuracy of any information provided by a third party service
                  provider does not exert any editorial control over such information. Oko Adviser
                  does not guarantee the timeliness, sequence, accuracy or completeness of any
                  information provided by a service provider.
                </p>
              </Modal>
            </div>
            {resultsSummary && <ExposureChart resultsSummary={resultsSummary} />}
          </Container>
        ) : null}
        <Container className="sustainability-overview-container">
          <Row className="justify-content-center mt-80">
            <div className="tips-panel tipcard">
              {`Portfolio Name: ${
                sustainabilityResults.portfolioName
              } | Uploaded on ${sustainabilityResults.uploadDate.slice(0, 10)}`}
              <a
                className="tipcard-link"
                onClick={() => history.push(`/clients/${clientId}/portfolios`)}
              >
                Edit
              </a>
            </div>
          </Row>
        </Container>
      </>
    );
  }
  return <div>Loading...</div>;
};

export default SustainabilityOverview;
