import { IAddClientBulkRequest } from '../interfaces/requests/clients/IAddClientBulkRequest';
import { IAddClientRequest } from '../interfaces/requests/clients/IAddClientRequest';
import { IUpdateClientRequest } from '../interfaces/requests/clients/IUpdateClientRequest';
import { IAdviserClientDto } from '../interfaces/responses/clients/IAdviserClientDto';

import { ApiEndpoints } from './ApiEndpoints';
import { BaseService } from './BaseService';

export abstract class ClientsService extends BaseService {
  static getInstance() {
    return this;
  }

  public static getAllClients() {
    return this.callGet<IAdviserClientDto[]>(ApiEndpoints.GetAllClients);
  }

  public static getClient(clientId: string) {
    return this.callGet<IAdviserClientDto>(ApiEndpoints.Client(clientId));
  }

  public static addClient(params: IAddClientRequest) {
    return this.callPost<IAdviserClientDto>(ApiEndpoints.AddClient, params);
  }

  public static updateClients(params: IUpdateClientRequest) {
    return this.callPost<IAdviserClientDto>(ApiEndpoints.UpdateClient, params);
  }

  public static deleteClient(clientId: string) {
    return this.callDelete(ApiEndpoints.Client(clientId));
  }

  public static addClientsBulk(clients: IAddClientBulkRequest) {
    return this.callPost<IAdviserClientDto[]>(ApiEndpoints.AddClientBulk, clients);
  }
}
