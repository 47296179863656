import { useContext } from 'react';

import { Button, Col, Row } from 'reactstrap';

import { FilterContext } from 'store/FiltersContext';

import { LoadingPage } from '../commons';

import './commonFilters.scss';

interface CommonFiltersComponentProps {
  isInApl?: boolean;
  showAllOption?: boolean;
  className?: string | undefined;
}

const CommonFiltersComponent = (props: CommonFiltersComponentProps) => {
  const filterContext = useContext(FilterContext);
  const { className } = props;

  const commonFilters = props.isInApl
    ? [...filterContext.commonFilters.filter((i) => i !== 'APL')]
    : [...filterContext.commonFilters];

  if (props.showAllOption) {
    commonFilters.splice(0, 0, 'All');
  }

  function handleButtonClick(item: string) {
    if (props.showAllOption && item === 'All') {
      filterContext.clearFilters();
    } else if (item !== 'All') {
      filterContext.toggleFilter(item);
    }
  }
  if (filterContext.loading) {
    return <LoadingPage />;
  }

  const updatedClassName = !className
    ? 'common-filters-result justify-content-center slim-content'
    : `slim-content ${className}`;

  return (
    <>
      <Row className={updatedClassName}>
        {commonFilters.map((item) => {
          const isSelected = filterContext.selectedFilters.includes(item);
          return (
            <Col className="d-flex pr-1 pl-1" key={item}>
              <Button
                style={{
                  boxShadow: '1px 2px 2px 2px rgba(175, 173, 173, 0.29)',
                  borderColor: 'white',
                  padding: 0,
                  marginBottom: '5px',
                }}
                className={`types-btn btn-item modal-common-filters-item ${
                  isSelected ? 'selected' : 'unselected'
                }`}
                onClick={() => handleButtonClick(item)}
              >
                {item}
              </Button>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default CommonFiltersComponent;
