import { size } from 'lodash';
import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import TextField from './TextField';

const TextAreaField = ({ maxLength, onChange, control, value, ...props }) => (
  <Fragment>
    <TextField type="textarea" {...{ onChange, control, value, maxLength }} {...props} />
    <small className="result-text">
      {`There're ${maxLength - size(value)} character(s) available.`}
    </small>
  </Fragment>
);

TextAreaField.propTypes = {
  onChange: PropTypes.func.isRequired,
  control: PropTypes.string.isRequired,
  label: PropTypes.string,
  feedBackLabel: PropTypes.string,
  value: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
};

TextAreaField.defaultProps = {
  label: null,
  maxLength: 250,
  minLength: 2,
  disabled: false,
  value: '',
  feedBackLabel: 'Invalid entry',
};

export default TextAreaField;
