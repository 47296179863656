import React from 'react';

import { Row, Col } from 'reactstrap';

interface PageHeaderInterface {
  title: string;
  subTitle?: string | null;
  name?: string;
  className: string;
  rightButton: React.ReactNode;
  center?: boolean;
  margin?: string;
}

const PageHeader = ({
  title = '',
  subTitle = null,
  name = '',
  className = '',
  rightButton = null,
  center = false,
  margin = '',
}: PageHeaderInterface) => {
  return (
    <Row className={center ? `justify-content-center ${className}` : className}>
      <Col sm={rightButton ? 9 : 12} className={margin ? margin : 'mt-50 mb-40'}>
        {subTitle ? (
          <h5
            className={
              center
                ? `text-darkblue font-weight-bold d-flex justify-content-center`
                : 'text-darkblue font-weight-bold'
            }
          >
            {title}
          </h5>
        ) : (
          <h2
            className={
              center
                ? `text-darkblue font-weight-bold d-flex justify-content-center title-pages`
                : 'text-darkblue font-weight-bold title-pages'
            }
          >
            {title} {name ? `${name}!` : ''}
          </h2>
        )}
        {subTitle && <h2 className="text-darkblue font-weight-bold title-pages">{subTitle}</h2>}
      </Col>
      {rightButton && (
        <Col sm={3} className="mt-50 mb-40 sign-out-content">
          {rightButton}
        </Col>
      )}
    </Row>
  );
};

export default PageHeader;
