import { filter } from 'lodash';
import React, { Fragment } from 'react';

import { Row, Col, CardBody, Input, Button } from 'reactstrap';

import profileMarkIcon from '../../../_assets/img/icons/profile/Complete.png';
import profileUnmarkIcon from '../../../_assets/img/icons/profile/Incomplete.png';
import { ButtonLoading } from '../../commons';

const ClientListSelector = ({ onSubmit, onBack, clientsList, onChecked, loading }) => (
  <Fragment>
    <Row>
      <p>Which client/s would you like to attach this portfolio to?</p>
    </Row>
    {clientsList.map((client, index) => (
      <CardBody key={client.clientCoreId} className="no-padding">
        <Row className="row-card">
          <Col sm={1} className="text-right">
            <Input
              type="checkbox"
              name="clientSelected"
              id="clientSelected"
              value={client.checked}
              onChange={() => onChecked(index)}
            />
          </Col>
          <Col sm={10}>
            {client.clientFirstName} - {client.clientLastName}
          </Col>
          <Col sm={1}>
            {client.hasActiveProfile ? (
              <img src={profileMarkIcon} alt="mark" />
            ) : (
              <img src={profileUnmarkIcon} alt="unmark" />
            )}
          </Col>
        </Row>
      </CardBody>
    ))}
    <Row>
      <Col sm={6}>
        <Button outline className="btn-secondary" onClick={() => onBack()}>
          Back
        </Button>
      </Col>
      <Col sm={6}>
        <ButtonLoading
          label="Upload and review"
          className="btn-main-shadowless"
          loading={loading}
          onClick={() => onSubmit(filter(clientsList, (c) => c.checked === true))}
          small
        />
      </Col>
    </Row>
  </Fragment>
);

export default ClientListSelector;
