import React, { PureComponent, Fragment } from 'react';

import { Button, Col, Container, Row } from 'reactstrap';

import Support from '../../../_assets/img/icons/profileIntro/support@3x.png';
import { getClient } from '../../../service/ClientsService';
import { LoadingPage } from '../../commons';

class ClientAddSuccess extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      adviserClient: {},
    };
  }

  async componentDidMount() {
    const id = this.props.router.match.params.id;
    this.setState(() => ({ loading: true }));
    await getClient({ ClientCoreId: id }).then((res) =>
      this.setState(() => ({ adviserClient: res.adviserClient, loading: false })),
    );
  }

  render() {
    const id = this.props.router.match.params.id;
    const { loading, adviserClient } = this.state;
    return (
      <div className="slim-content">
        <Container>
          {loading && <LoadingPage />}
          {!loading && (
            <Fragment>
              <br />
              <Row className="justify-content-center mt-50">
                <img src={Support} alt="icon" width="130" />
              </Row>
              <Row className="justify-content-center mt-100">
                <h2>Success!</h2>
              </Row>
              <Row className="justify-content-center mt-20">
                <p style={{ fontSize: '17px' }}>
                  You've created a profile for {adviserClient.clientFirstName}{' '}
                  {adviserClient.clientLastName}.
                </p>
              </Row>
              <Row className="justify-content-center mt-40">
                <Col sm={6}>
                  <Button
                    className="btn-main-shadowless"
                    onClick={() => this.props.history.push(`/clients/${id}/portfolios`)}
                    style={{ letterSpacing: 'initial' }}
                  >
                    Upload a portfolio
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-center mt-40">
                <a
                  onClick={() => this.props.history.push(`/clients`)}
                  style={{ cursor: 'pointer' }}
                >
                  Back to client list
                </a>
              </Row>
            </Fragment>
          )}
        </Container>
      </div>
    );
  }
}

export default ClientAddSuccess;
