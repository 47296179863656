import React, { useState } from 'react';

import { Label, Input, FormGroup, FormFeedback } from 'reactstrap';

import ValidatorService from '../../service/validator';

interface TextFieldInterface {
  onChange: Function;
  onBlur?: Function | null;
  handleKeyPress?: Function | null;
  control?: string;
  label?: string | Function | null; //PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  value?: string | number | symbol | boolean; //PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.symbol, PropTypes.bool]),
  validateInput: Function | null;
  placeholder?: string;
  feedBackLabel?: string | null;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  required: boolean;
  hasValid?: boolean | null;
  row?: boolean | null;
  invalid?: boolean | null;
  validate?: boolean;
  handleSubmitted?: boolean;
  cleanAfterSubmit?: boolean;
  className?: string;
  style?: string;
}

const TextField = ({
  onChange,
  onBlur = null,
  handleKeyPress = null,
  control,
  label = null,
  value = '',
  validateInput = null,
  placeholder = '',
  feedBackLabel = null,
  minLength = 1,
  maxLength = 100,
  disabled = false,
  required = false,
  hasValid = null,
  row = null,
  invalid = null,
  validate = true,
  handleSubmitted = false,
  cleanAfterSubmit = false,
  className = '',
  ...props
}: TextFieldInterface) => {
  const [valid, setValid] = useState<any>(null);

  const validateTextInput = (value: string) => {
    if (!validate) {
      return null;
    }
    if (validateInput) {
      const validValue = validateInput(value);
      return setValid(validValue);
      // this.setState(() => ({ valid }));
    }
    const valid2Value = ValidatorService.validateText(value, maxLength, minLength);
    return setValid(valid2Value);
  };

  const handleChange = async (incomingValue: any) => {
    const newValue = incomingValue.target.value;
    if (value === newValue.target) {
      return;
    }
    await onChange({ target: { value: newValue, id: control } });
    validateTextInput(newValue);
  };

  const handleBlur = (value: string) => {
    if (!value || !onBlur) {
      return;
    }
    validateTextInput(value);
    onBlur(value);
  };

  const cleanValid = () => {
    setValid(null);
  };

  if (cleanAfterSubmit) {
    cleanValid();
    ('');
  }
  return (
    <FormGroup row={row} className="d-block">
      {label && <Label>{label}</Label>}
      <Input
        type="text"
        className={className}
        onKeyPress={handleKeyPress}
        onBlur={(e: any) => handleBlur(e.target.value)}
        invalid={!handleSubmitted ? !hasValid && (valid === false || invalid) : !valid}
        name={control}
        valid={!handleSubmitted ? hasValid || valid : valid}
        value={value}
        required={required}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        placeholder={placeholder}
        // {...{
        //   required,
        //   value,
        //   maxLength,
        //   minLength,
        //   disabled,
        //   placeholder,
        // }}
        // {...props}
        {...props}
        onChange={(e: any) => handleChange(e)}
      />
      {feedBackLabel && <FormFeedback>{feedBackLabel}</FormFeedback>}
    </FormGroup>
  );
};

export default TextField;
