import React, { PureComponent, useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Button, Container, Row } from 'reactstrap';

import Auth from 'service/AuthService';
import { ProfileContext } from 'store/ProfileContext';

import { getMyShareCode } from '../../../../service/ShareCodeService';

import './Step3.scss';
import Welcome from './Welcome';

export default function SuccessScreen(props) {
  const profileContext = useContext(ProfileContext);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [copyCodeText, setCopyCodeText] = useState('Copy invite code');

  useEffect(() => {
    getMyShareCode()
      .then((response) => {
        setCode(response.shareCode);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleJumpForward = () => {
    // load profile
    Auth.setWelcomeScreen().then(() => {
      Auth.getUser().then((params) => {
        if (params.ok && params.data != null) {
          profileContext.setProfile(params.data.adviserUser);
          props.history.push('/');
        }
      });
    });
  };

  const copyCode = () => {
    const copyText = document.getElementById('codeText');
    const textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    setCopyCodeText('Code copied!');
    setTimeout(() => {
      setCopyCodeText('Copy invite code');
    }, 1700);
  };

  return (
    <div>
      <Welcome code={code} next={handleJumpForward} />
    </div>
  );
}
