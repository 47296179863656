import { Switch, Route } from 'react-router-dom';

import PropTypes from 'prop-types';
import { FilterContextProvider } from 'store/FiltersContext';

import Instrument from '../commons/Instrument/Instrument';
import Layout from '../ui/Layout';
import Presets from '../ui/Presets';

import Search from './Search';
import './company.scss';

const Research = ({ match: { path } }) => (
  <Switch>
    <Route
      path={`${path}/:clientId/instruments/:instrumentId/results`}
      render={() => <Instrument />}
    />
    <Route path={path} render={() => <Search />} />
  </Switch>
);

Research.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }).isRequired,
};

export default Research;
