/* eslint-disable @typescript-eslint/no-unused-vars */
import api, { apiSetup } from '../config/api';

interface IBaseResponse<T> {
  ok: boolean;
  status: number;
  result: T;
}

export abstract class BaseService {
  protected static callGet<T>(url: string, signal?: AbortSignal) {
    return new Promise<T>((resolve, reject) => {
      apiSetup();
      api
        .get<IBaseResponse<T>>(url, { signal })
        .then((res) => {
          if (res.ok && res.status === 200 && res.data !== undefined) {
            resolve(res.data.result);
          } else {
            //todo:- Show Error Message Here
            reject({ error: 'Something went wrong' });
          }
        })
        .catch((err) => {
          reject({ error: err.message });
        });
    });
  }

  protected static callPost<T>(url: string, data: any) {
    return new Promise<T>((resolve, reject) => {
      apiSetup();
      api
        .post<IBaseResponse<T>>(url, data)
        .then((res) => {
          if (res.ok && res.status === 200 && res.data !== undefined) {
            resolve(res.data.result);
          } else {
            //todo:- Show Error Message Here
            reject({ error: 'Something went wrong' });
          }
        })
        .catch((err) => {
          reject({ error: err.message });
        });
    });
  }

  protected static callDelete(url: string) {
    return new Promise((resolve) => {
      apiSetup();
      api
        .delete(url)
        .then((res) => {
          if (res.ok && res.status === 200) {
            resolve(true);
          } else if (!res.ok && res.status === 409) {
            // reject({ message: res.data.message });
          } else {
            // reject({ message: 'something went wrong' });
          }
        })
        .catch((err) => {
          // reject({ message: err });
        });
    });
  }

  protected static callDeleteWithReturn<T>(url: string) {
    return new Promise<T>((resolve) => {
      apiSetup();
      api
        .delete<IBaseResponse<T>>(url)
        .then((res) => {
          if (res.ok && res.status === 200 && res.data !== undefined) {
            resolve(res.data.result);
          } else {
            //Todo:- Show Error Message Here
            //reject({ message: 'something went wrong' });
          }
        })
        .catch((err) => {
          //todo:- Show Error Message Here
          //reject({ message: err });
        });
    });
  }
}
