import api, { apiSetup } from '../config/api';

import { getSessionToken } from './LocalStorageService';

export function getAllOutputs(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/GetAllOutputs ', params)
      .then((res) => {
        if (
          (res.ok && res.status === 200 && res.data.httpStatusCode === 200) ||
          (!res.ok && res.status === 400 && res.data.httpStatusCode === 400)
        ) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function createOutput(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/CreateOutput ', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getOutputById(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/GetOutputById ', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getMeetingNotes(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/GetMeetingNotes ', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getActionsByOutputId(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/GetActions', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getSupportedByOutputId(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/GetSupported ', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getConflictsByOutputId(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/GetConflicts ', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function duplicateAdviceOutput(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/DuplicateAdviceOutput', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function completeAdviceOutput(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/CompleteAdviceOutput', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getLatestOutput(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/GetLatestOutput', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getActions(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/GetActions', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function markActionForCompanyCard(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/CreateOrUpdateActionForCompanyCard', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function markActionForOutcomes(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/AdviserOutput/CreateOrUpdateActionForOutcome', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function createAdviceOutput(id) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post(`/api/advice/create/${id}`)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getAdviceOutput(id) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .get(`/api/advice/output/${id}`)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          resolve(res.data);
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
