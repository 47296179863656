import { isEmpty } from 'lodash';
import { Fragment, useState, useContext } from 'react';

import { Button, Row, Col, Container } from 'reactstrap';

import { FilterContext } from 'store/FiltersContext';

import { RadioButton } from '.';

const SortByModal = (props: any) => {
  const filterContext = useContext(FilterContext);
  const [selectedOption, setSelectedOption] = useState('');
  const { onSubmit } = props;

  const sortByOptions = filterContext.sortOptions;

  const applySubmit = () => {
    filterContext.setSelectedSortBy(selectedOption);
    return onSubmit();
  };

  const handleChange = (selected: string) => {
    if (selectedOption === selected) {
      setSelectedOption('');
    } else {
      setSelectedOption(selected);
    }
  };

  return (
    <Container>
      {!isEmpty(sortByOptions) ? (
        <Fragment>
          <Row>
            <Col sm={12} className="sort-modal-content-input">
              {!isEmpty(sortByOptions) && (
                <div className="radio-custom">
                  <RadioButton
                    control="sortBy"
                    onChange={(selected) => handleChange(selected)}
                    options={sortByOptions}
                    selected={selectedOption}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={6}>
              <Button
                className="btn-submit-secondary btn-modal-filter"
                onClick={() => applySubmit()}
              >
                Apply and close
              </Button>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <p>Empty Options</p>
      )}
    </Container>
  );
};

export default SortByModal;
