import { isEmpty } from 'lodash';
import { Fragment, useContext, useEffect } from 'react';
import { useState } from 'react';

import { Col, Collapse, Input, Label, ListGroup, ListGroupItem, Row, Container } from 'reactstrap';

import CommonFiltersComponent from '_components/research/CommonFiltersComponent';
import { FilterContext, FilterInterface } from 'store/FiltersContext';

import chevronDown from '../../_assets/img/icons/global/chevron/down@3x.png';
import chevronUp from '../../_assets/img/icons/global/chevron/up@3x.png';

import ButtonLoading from './ButtonLoading';
import './FiltersModal.scss';
import LoadingPage from './LoadingPage';

function FiltersModal(props: any) {
  const filtersContext = useContext(FilterContext);

  const [expandedFilters, setExpandedFilters] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>(filtersContext.selectedFilters);

  const loading = filtersContext.loading;
  const filters = filtersContext.filters;

  const { isSearchModal, onSubmit } = props;

  useEffect(() => {
    setSelectedFilters(filtersContext.selectedFilters);
  }, [filtersContext.selectedFilters]);

  const expandedFilter = (filter: FilterInterface) => {
    const filters = [...expandedFilters];
    if (expandedFilters.includes(filter.key)) {
      filters.splice(filters.indexOf(filter.key), 1);
    } else {
      filters.push(filter.key);
    }
    setExpandedFilters(filters);
  };

  const handleChangeOption = (option: string) => {
    const filters = [...selectedFilters];
    if (filters.includes(option)) {
      filters.splice(filters.indexOf(option), 1);
    } else {
      filters.push(option);
    }
    // setSelectedFilters(filters);
    filtersContext.setSelectedFilters(filters);
  };

  const handleReset = () => {
    filtersContext.setSelectedFilters([]);
  };

  const applySubmit = () => {
    filtersContext.setSelectedFilters(selectedFilters);
    return onSubmit();
  };

  return (
    <Container>
      {loading && <LoadingPage />}
      {!loading &&
        (!isEmpty(filters) ? (
          isSearchModal ? (
            <Fragment>
              <div className="search-common-filters">
                <CommonFiltersComponent className="modal-common-filters" />
              </div>
              <div className="advanced-filters">Advanced Filters:</div>
              <ListGroup style={{ margin: '0px -18px' }}>
                {filters.map((filter, filterIdx) => {
                  const isExpanded = expandedFilters?.includes(filter.key);
                  return (
                    <ListGroupItem
                      key={filter.key}
                      className="border-0"
                      style={{ margin: '4px 0' }}
                    >
                      <div className="list-item-content d-flex flex-column w-100">
                        <div
                          className="list-item-header d-flex"
                          onClick={() => expandedFilter(filter)}
                        >
                          <div className="filter-name flex-grow-1 count-filter-applied">
                            {filter.key}
                          </div>
                          <div className="filter-icon">
                            {isExpanded ? (
                              <img src={chevronUp} alt="up" width="20" />
                            ) : (
                              <img src={chevronDown} alt="down" width="20" />
                            )}
                          </div>
                        </div>

                        <Collapse
                          isOpen={isExpanded}
                          style={{ clear: 'both', marginTop: 20, width: '100%' }}
                        >
                          {filter.value.map((option: string, filterOptionIdx: number) => (
                            <div className="check-item d-flex w-100" key={filterOptionIdx}>
                              <Label
                                key={option}
                                className="d-flex w-100"
                                onClick={(e: any) => e.stopPropagation}
                              >
                                <span className="d-flex flex-grow-1 modal-label">{option}</span>
                                <Input
                                  type="checkbox"
                                  data-idx={filterOptionIdx}
                                  data-index={filterIdx}
                                  data-option={option}
                                  title={filter.key}
                                  // checked={!!option.checked}
                                  checked={selectedFilters.includes(option)}
                                  onChange={() => handleChangeOption(option)}
                                  className="modal-input"
                                />
                              </Label>
                            </div>
                          ))}
                        </Collapse>
                      </div>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
              <Row className="d-flex" style={{ margin: '20px -18px' }}>
                <ButtonLoading
                  className="btn-main-shadowless"
                  style={{
                    maxHeight: '40px',
                    maxWidth: '180px',
                    fontSize: '16px',
                  }}
                  label="Apply and close"
                  onClick={() => applySubmit()}
                />
                <button onClick={() => handleReset()} className="reset-filter">
                  Clear all
                </button>
              </Row>
            </Fragment>
          ) : (
            <Fragment>
              <button onClick={() => handleReset()} className="reset-filter portfolio-screen">
                Clear all filters
              </button>
              <ListGroup>
                {filters.map((filter, filterIdx) => {
                  const isExpanded = expandedFilters?.includes(filter.key);
                  return (
                    <ListGroupItem key={filter.key} className="border-0">
                      <div className="list-item-content d-flex flex-column w-100">
                        <div
                          className="list-item-header d-flex"
                          onClick={() => expandedFilter(filter)}
                        >
                          <div className="filter-name flex-grow-1 count-filter-applied">
                            {filter.key}
                          </div>
                          <div className="filter-icon">
                            {isExpanded ? (
                              <img src={chevronUp} alt="up" width="20" />
                            ) : (
                              <img src={chevronDown} alt="down" width="20" />
                            )}
                          </div>
                        </div>
                        <Collapse
                          isOpen={isExpanded}
                          style={{ clear: 'both', marginTop: 20, width: '100%' }}
                        >
                          {filter.value.map((option: string, filterOptionIdx: number) => (
                            <div className="check-item d-flex w-100" key={filterOptionIdx}>
                              <Label
                                key={option}
                                className="d-flex w-100"
                                onClick={(e: any) => e.stopPropagation}
                              >
                                <span className="d-flex flex-grow-1">{option}</span>
                                <Input
                                  type="checkbox"
                                  data-idx={filterOptionIdx}
                                  data-index={filterIdx}
                                  data-option={option}
                                  title={filter.key}
                                  // checked={!!option.checked}
                                  checked={selectedFilters.includes(option)}
                                  style={{ position: 'relative', width: '32px' }}
                                  onChange={() => handleChangeOption(option)}
                                />
                              </Label>
                            </div>
                          ))}
                        </Collapse>
                      </div>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
              <Row className="justify-content-center">
                <Col sm={6}>
                  <ButtonLoading
                    className="btn-main-shadowless mt-3 mb-2"
                    style={{ margin: 'auto' }}
                    label="Apply and close"
                    onClick={() => applySubmit()}
                  />
                </Col>
              </Row>
            </Fragment>
          )
        ) : (
          <p>Empty Filters</p>
        ))}
    </Container>
  );
}

export default FiltersModal;
