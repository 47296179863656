import { useHistory } from 'react-router-dom';
import { Card, Button } from 'reactstrap';

import Grade from '_components/commons/Grade';

import unsureIcon from '../../../_assets/img/icons/coDetails/Group 2@4x.png';
import conflictsIcon from '../../../_assets/img/icons/coDetails/conflict/focus@2x.png';
import portfolionIcon from '../../../_assets/img/icons/nav/side/Group 3@4x_v2.png';
import tipIcon from '../../../_assets/img/icons/profile/Bulb@1.5x.png';
import checkIcon from '../../../_assets/img/icons/profile/Complete@3x.png';
import tickIcon from '../../../_assets/img/results/tick@3x.png';

const ComparisonOverview = (props) => {
  const { showInvolvement, setShowInvolvement, portfolioScores, getStatus, id, hasTip, hasViewDetails = true } = props;

  const planetScore = portfolioScores?.portfolio?.scores?.planetScore;
  const ethicsScore = portfolioScores?.portfolio?.scores?.ethicsScore;
  const employeesScore = portfolioScores?.portfolio?.scores?.employeesScore;
  const communityScore = portfolioScores?.portfolio?.scores?.communityScore;
  const involvements = portfolioScores?.involvements;
  const grade = portfolioScores?.grade;
  const history = useHistory();

  return (
    <>
      <div className="title">
        {hasTip && <Card className="tips-panel-compare">
          <img src={tipIcon} />
          <p style={{ fontSize: '13px' }}>
            Compare the sustainability of any investment from anywhere on the Oko Adviser
            platform. Simply select the Compare button from Results or Search and it will appear
            here. You can compare unlimited investments.
          </p>
        </Card>}

        <Card className="overview">
          <div className="highlight">
            {!hasTip && <div style={{ padding: '72px' }}></div>}
            <div className="profile-item heading">
              <h5>Overview</h5>
            </div>
            <div className="profile-item">
              <span>Profile Match Result</span>
            </div>
            <div className="profile-item">
              <span># of linked Profile Preferences</span>
            </div>
            <div className="profile-item">
              <span># of linked Profile Exclusions</span>
            </div>
            <div className="profile-item">
              <span>Sector</span>
            </div>
            <div className="profile-item">
              <span>Instrument Type</span>
            </div>
          </div>
          <div className="profile-item heading">
            <h5>Sustainability Review</h5>
          </div>
          <div className="profile-item">
            <span>Overall Score</span>
          </div>

          <div className="profile-item">
            <span>Planet Score</span>
          </div>
          <div className="profile-item">
            <span> Ethics Score</span>
          </div>
          <div className="profile-item">
            <span> Community Score</span>
          </div>
          <div className="profile-item">
            <span> Employee Score</span>
          </div>
          <div className="profile-item heading">
            <h5>Involvement</h5>
            <div className="profile-selection">
              <p className="profile-selection-text">Profile Selection</p>
              <a
                href="#"
                style={{ textDecoration: 'underline', fontSize: '12px', color: '#3e61df' }}
                onClick={() => setShowInvolvement(!showInvolvement)}
              >
                {showInvolvement ? 'Hide' : 'Show'}
              </a>
            </div>
          </div>
          {portfolioScores?.involvements?.map((item, index) => (
            <div className="profile-item" key={index}>
              <span>{item.title.substring(0, 40)}</span>
              {showInvolvement && getStatus(item.involvementType)}
            </div>
          ))}
        </Card>
      </div>
      {/* <Col className="detail"> */}
      <div className="description detail">
        <Card className="overview text-center">
          <div className="overview-title">
            <div className="highlight title-logo">
              <img src={portfolionIcon} width="74px" height="74px" alt="title-logo" />
            </div>

            <h4>Current Portfolio</h4>
          </div>

          <div className="highlight">
            <div className="profile-item heading">
              <h5></h5>
            </div>

            <div className="profile-item">
              <span>-</span>
            </div>

            <div className="profile-item">
              <span>
                {!portfolioScores?.portfolio
                  ? '-'
                  : portfolioScores.portfolio?.numberOfLinkedProfilePreferences}
              </span>
            </div>

            <div className="profile-item">
              <span>
                {!portfolioScores?.portfolio
                  ? '-'
                  : portfolioScores.portfolio.numberOfLinkedProfileExclusions}
              </span>
            </div>

            <div className="profile-item">
              <span>{!portfolioScores?.portfolio ? '-' : portfolioScores.portfolio.sector}</span>
            </div>

            <div className="profile-item">
              <span>
                {!portfolioScores?.portfolio ? '-' : portfolioScores.portfolio.instrumentType}
              </span>
            </div>
          </div>

          <div className="profile-item heading">
            <h5></h5>
          </div>

          <div className="profile-item">
            <span>{grade ? Math.round(grade) : '-'}</span>
          </div>

          <div className="profile-item">
            <span>{planetScore ? Math.round(planetScore) : '-'}%</span>
          </div>

          <div className="profile-item">
            <span>{ethicsScore ? Math.round(ethicsScore) : '-'}%</span>
          </div>

          <div className="profile-item">
            <span>{communityScore ? Math.round(communityScore) : '-'}%</span>
          </div>

          <div className="profile-item">
            <span>{employeesScore ? Math.round(employeesScore) : '-'}%</span>
          </div>

          <div className="profile-item heading">
            <h5></h5>
          </div>

          {portfolioScores?.involvements?.map((involvement, index) => {
            <div className="profile-item" key={index}>
              <span>{getStatus(involvement.involvementType)}</span>
            </div>;
          })}

          {/* Involvements Included */}
          {portfolioScores?.portfolio?.involvementIncluded.map((isTrue, index) => (
            <div className="profile-item" key={index} style={{ height: '45px' }}>
              {!isTrue ? <span>{'-'}</span> : <img src={tickIcon} width={20} />}
            </div>
          ))}
        </Card>
        {hasViewDetails && <Button
          id="btn-view-detail"
          className="mt-2"
          onClick={() => {
            history.push(`/clients/${id}/results/portfolioResults`);
          }}
        >
          View Details
        </Button>}
      </div>
    </>
  );
};

export default ComparisonOverview;
