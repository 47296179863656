import { isEmpty } from 'lodash';

import { IInstrumentSmallDto } from 'interfaces/responses/instrument/IInstrumentSmallDto';

import './BusinessActivityWithPopup.scss';
import HoldingsLinkedWithIssue from './Instrument/HoldingsLinkedWithIssues';

interface BusinessActivityInfoProps {
  title: string;
  reason: string;
  dataLastUpdated: string | undefined;
  affectedInstruments: IInstrumentSmallDto[] | undefined | null;
}

export default function BusinessActivityInfo({
  title,
  reason,
  affectedInstruments,
  dataLastUpdated,
}: BusinessActivityInfoProps) {
  let aiDisclaimer = <></>;

  if (reason !== '') {
    aiDisclaimer = (
      <p className="text-left popover-note font-12">
        *Some content has been generated by an artificial intelligence language model. While we
        strive for accuracy and quality, please note that the information provided may not be
        entirely error-free or up-to-date. We recommend independently verifying the content and
        consulting with professionals for specific advice or information. We do not assume any
        responsibility or liability for the use or interpretation of this content
      </p>
    );
  }

  const dataUpdatedDate = (
    <p className="text-left popover-note">
      <b className="heading-color">Data last reported</b>:&nbsp;
      {dataLastUpdated}
    </p>
  );

  const holdingsWithIssues = (
    <>
      {!isEmpty(affectedInstruments) && (
        <p className="text-left popover-note">
          <b className="heading-color">Holdings linked with this issue:</b>
        </p>
      )}
      {affectedInstruments &&
        affectedInstruments.map((instrument) => (
          <div key={instrument.id}>
            <HoldingsLinkedWithIssue instrument={instrument} />
          </div>
        ))}
    </>
  );

  switch (title) {
    case 'Animal testing': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}

          <p className="text-left">
            <b>Sources used:</b>&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/american-anti-vivisection-society`}
              target="_blank"
              rel="noreferrer"
            >
              American Anti-Vivisection Society,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/better-world-companies`}
              target="_blank"
              rel="noreferrer"
            >
              Better World Companies,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/caring-consumer`}
              target="_blank"
              rel="noreferrer"
            >
              Caring Consumer,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/understanding-animal-research`}
              target="_blank"
              rel="noreferrer"
            >
              Understanding Animal Research
            </a>
          </p>
        </>
      );
    }
    case 'Child Labour': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}

          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/msci-esg-controversies`}
              target="_blank"
              rel="noreferrer"
            >
              MSCI ESG Controversies.
            </a>
          </p>
        </>
      );
    }
    case 'Genetically modified plants and seeds': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/non-gmo-project`}
              target="_blank"
              rel="noreferrer"
            >
              Non-GMO Project.
            </a>
          </p>
        </>
      );
    }
    case 'Military and weapons': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/top-100-military-friendly-employers-2017`}
              target="_blank"
              rel="noreferrer"
            >
              Top 100 Military Friendly Employers 2017,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/top-100-military-friendly-employers-2018`}
              target="_blank"
              rel="noreferrer"
            >
              Top 100 Military Friendly Employers 2018.
            </a>
          </p>
        </>
      );
    }
    case 'Nuclear power': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/eia-nuclear-list`}
              target="_blank"
              rel="noreferrer"
            >
              EIA Nuclear List,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings.
            </a>
          </p>
        </>
      );
    }
    case 'Pesticides': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/msci-esg-controversieshttps://www.csrhub.com/datasource/top-100-military-friendly-employers-2018`}
              target="_blank"
              rel="noreferrer"
            >
              MSCI ESG Controversies,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/msci-esg-intangible-value-assessment/`}
              target="_blank"
              rel="noreferrer"
            >
              MSCI ESG Intangible Value Assessment,
            </a>
            &nbsp;
            <a href={`https://www.csrhub.com/datasource/peri`} target="_blank" rel="noreferrer">
              PERI.
            </a>
          </p>
        </>
      );
    }
    case 'Sustainable forestry supporter': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/forest-500`}
              target="_blank"
              rel="noreferrer"
            >
              Forest 500,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/forestdisclosure`}
              target="_blank"
              rel="noreferrer"
            >
              Forestdisclosure,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/spott-sustainability-policy-transparency-toolkit`}
              target="_blank"
              rel="noreferrer"
            >
              SPOTT Sustainability Policy Transparency Toolkit.
            </a>
            &nbsp;
          </p>
        </>
      );
    }
    case 'Working mother friendly': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/spott-sustainability-policy-transparency-toolkit`}
              target="_blank"
              rel="noreferrer"
            >
              CDP Supply Chain Report,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/nafe-top-70-2018`}
              target="_blank"
              rel="noreferrer"
            >
              NAFE Top 70-2018,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/nafe-top-70-2019`}
              target="_blank"
              rel="noreferrer"
            >
              NAFE Top 70-2019,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/nafe-top-70-2020`}
              target="_blank"
              rel="noreferrer"
            >
              NAFE Top 70-2020,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/working-mother-list-2017`}
              target="_blank"
              rel="noreferrer"
            >
              Working Mother List 2017,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/working-mother-list-2018`}
              target="_blank"
              rel="noreferrer"
            >
              Working Mother List 2018,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/working-mother-list-2019`}
              target="_blank"
              rel="noreferrer"
            >
              Working Mother List 2019,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/working-multicultural-women-2018`}
              target="_blank"
              rel="noreferrer"
            >
              Working Multicultural Women 2018.
            </a>
          </p>
        </>
      );
    }
    case 'Fossil fuels': {
      return (
        <>
          {/* <p className="text-left">{`${instrumentName} has been linked to ${title} due to the Coal Involved where ${instrumentName} operates in or is closely aligned to.`}</p> */}
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/american-coal-council-member-companies`}
              target="_blank"
              rel="noreferrer"
            >
              American Coal Council Member Companies,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/u-s-energy-information-administration-coal-producers`}
              target="_blank"
              rel="noreferrer"
            >
              U.S. Energy Information Administration Coal Producers,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/van-eck-global-coal`}
              target="_blank"
              rel="noreferrer"
            >
              Van Eck Global Coal,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/world-coal-association-members`}
              target="_blank"
              rel="noreferrer"
            >
              World Coal Association Members.
            </a>
          </p>
        </>
      );
    }
    case 'Mining': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.csrhub.com/datasource/fracfocus-ca`}
              target="_blank"
              rel="noreferrer"
            >
              FracFocus.ca,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/fracking-disclosing-the-facts`}
              target="_blank"
              rel="noreferrer"
            >
              Fracking-Disclosing the Facts,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/fractracker`}
              target="_blank"
              rel="noreferrer"
            >
              FracTracker,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/marcellus-money`}
              target="_blank"
              rel="noreferrer"
            >
              Marcellus Money,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/marcellus-shale`}
              target="_blank"
              rel="noreferrer"
            >
              Marcellus Shale,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ohio-government-department-of-natural-resources-odnr-`}
              target="_blank"
              rel="noreferrer"
            >
              Ohio Government Department of Natural Resources (ODNR),
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/pennsylvania-doep`}
              target="_blank"
              rel="noreferrer"
            >
              Pennsylvania DOEP.
            </a>
          </p>
        </>
      );
    }
    case 'Diversity and equality': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.sustainalytics.com/esg-ratings/brambles-ltd/1042414777`}
              target="_blank"
              rel="noreferrer"
            >
              Sustainalytics,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings/`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.ftserussell.com/data/sustainability-and-esg-data/esg-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              FTSE Russell’s ESG Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.msci.com/our-solutions/esg-investing`}
              target="_blank"
              rel="noreferrer"
            >
              MSCI ESG Controversies,
            </a>
            &nbsp;
            <a
              href={`https://www.bloomberg.com/company/press/104-companies-included-first-sector-neutral-bloomberg-gender-equality-index/`}
              target="_blank"
              rel="noreferrer"
            >
              Bloomberg Gender Equality Index,
            </a>
            &nbsp;
            <a
              href={`https://www.hrc.org/resources/corporate-equality-index`}
              target="_blank"
              rel="noreferrer"
            >
              Human Rights Campaign List.
            </a>
          </p>
        </>
      );
    }
    case 'Community development & philanthropy': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.sustainalytics.com/esg-ratings/brambles-ltd/1042414777`}
              target="_blank"
              rel="noreferrer"
            >
              Sustainalytics,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings/`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.ftserussell.com/data/sustainability-and-esg-data/esg-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              FTSE Russell’s ESG Ratings,
            </a>
            &nbsp;
            <a href={`https://www.esgbook.com/`} target="_blank" rel="noreferrer">
              MSCI ESG Controversies,
            </a>
            &nbsp;
            <a href={`https://www.esgbook.com/`} target="_blank" rel="noreferrer">
              S-Ray Arabesque.
            </a>
          </p>
        </>
      );
    }
    case 'Corporate transparency': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.sustainalytics.com/esg-ratings/brambles-ltd/1042414777`}
              target="_blank"
              rel="noreferrer"
            >
              Sustainalytics,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings/`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.ftserussell.com/data/sustainability-and-esg-data/esg-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              FTSE Russell’s ESG Ratings,
            </a>
            &nbsp;
            <a href={`https://www.esgbook.com/`} target="_blank" rel="noreferrer">
              MSCI ESG Controversies,
            </a>
            &nbsp;
            <a href={`https://www.esgbook.com/`} target="_blank" rel="noreferrer">
              S-Ray Arabesque.
            </a>
          </p>
        </>
      );
    }
    case 'Human rights and employee conditions': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.sustainalytics.com/esg-ratings/brambles-ltd/1042414777`}
              target="_blank"
              rel="noreferrer"
            >
              Sustainalytics,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings/`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.unglobalcompact.org/what-is-gc/participants/127921`}
              target="_blank"
              rel="noreferrer"
            >
              UN Global Compact 2022,
            </a>
            &nbsp;
            <a
              href={`https://www.marketplace.spglobal.com/en/datasets/s-p-global-esg-scores-(171)`}
              target="_blank"
              rel="noreferrer"
            >
              S&P Global ESG Score,
            </a>
            &nbsp;
            <a
              href={`https://www.bloomberg.com/company/press/104-companies-included-first-sector-neutral-bloomberg-gender-equality-index/`}
              target="_blank"
              rel="noreferrer"
            >
              Bloomberg Gender Equality Index.
            </a>
          </p>
        </>
      );
    }
    case 'Recycling and Waste Reduction': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.sustainalytics.com/esg-ratings/brambles-ltd/1042414777`}
              target="_blank"
              rel="noreferrer"
            >
              Sustainalytics,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings/`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.ftserussell.com/data/sustainability-and-esg-data/esg-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              FTSE Russell’s ESG Ratings,
            </a>
            &nbsp;
            <a href={`https://www.esgbook.com/`} target="_blank" rel="noreferrer">
              MSCI ESG Controversies,
            </a>
          </p>
        </>
      );
    }
    case 'Renewable energy and clean tech': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
          <p className="text-left">
            <b>Sources used</b>:&nbsp;
            <a
              href={`https://www.sustainalytics.com/esg-ratings/brambles-ltd/1042414777`}
              target="_blank"
              rel="noreferrer"
            >
              Sustainalytics,
            </a>
            &nbsp;
            <a
              href={`https://www.csrhub.com/datasource/ideal-ratings/`}
              target="_blank"
              rel="noreferrer"
            >
              Ideal Ratings,
            </a>
            &nbsp;
            <a
              href={`https://www.ftserussell.com/data/sustainability-and-esg-data/esg-ratings`}
              target="_blank"
              rel="noreferrer"
            >
              FTSE Russell’s ESG Ratings,
            </a>
            &nbsp;
            <a href={`https://www.esgbook.com/`} target="_blank" rel="noreferrer">
              MSCI ESG Controversies,
            </a>
            &nbsp;
            <a href={`https://www.esgbook.com/`} target="_blank" rel="noreferrer">
              S-Ray Arabesque.
            </a>
          </p>
        </>
      );
    }
    case 'Pharmaceuticals': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
        </>
      );
    }
    case 'Banking': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
        </>
      );
    }
    case 'Gambling': {
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
        </>
      );
    }
    default:
      return (
        <>
          <p className="text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {reason}
          </p>
          {dataUpdatedDate}
          {holdingsWithIssues}
          {aiDisclaimer}
        </>
      );
      break;
  }
}
