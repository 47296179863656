import { isEmpty } from 'lodash';
import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Collapse, Container } from 'reactstrap';

import chevronDown from '../../_assets/img/icons/global/chevron/down@3x.png';
import chevronUp from '../../_assets/img/icons/global/chevron/up@3x.png';
import { getFaqs } from '../../service/ReferenceService';
import { LoadingPage } from '../commons';

class Faqs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      questions: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({ loading: true }));
    getFaqs()
      .then((response) => {
        this.setState({ questions: response.faqs, loading: false });
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ showMessage: true, message: err.message, loading: false });
      });
  }

  collapseQuestion(questionId) {
    const questions = this.state.questions.slice();

    if (questions[questionId].showResponse) {
      questions[questionId].showResponse = false;
    } else {
      questions.map((question) => (question.showResponse = false));
      questions[questionId].showResponse = true;
    }

    this.setState(() => ({ questions: questions }));
  }

  render() {
    const { questions } = this.state;
    return (
      <div className="slim-content">
        <Container className="p-0">
          <div className="wizard-top-title justify-content-center mb-40">
            <h2 className="d-block w-100 text-left mt-20">FAQs</h2>
          </div>
          {isEmpty(questions) && <LoadingPage />}
          {!isEmpty(questions) &&
            questions.map((question, index) => (
              <Fragment key={question.id}>
                <div
                  className="list-item-content d-flex flex-column w-100"
                  style={{
                    padding: '25px',
                    marginBottom: '1em',
                    borderRadius: '6px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    backgroundColor: '#fefffe',
                  }}
                  onClick={() => this.collapseQuestion(index)}
                >
                  <div className="list-item-header d-flex">
                    <div className="filter-name flex-grow-1">{question.question}</div>
                    <div className="filter-icon">
                      {question.showResponse ? (
                        <img src={chevronUp} alt="up" width="21" />
                      ) : (
                        <img src={chevronDown} alt="down" width="21" />
                      )}
                    </div>
                  </div>
                  <Collapse
                    isOpen={question.showResponse}
                    style={{ clear: 'both', marginTop: 20, width: '100%' }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: question.answer }} />
                  </Collapse>
                </div>
              </Fragment>
            ))}
        </Container>
      </div>
    );
  }
}

export default Faqs;
