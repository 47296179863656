import Chart from 'react-apexcharts';
import { Row, Col } from 'reactstrap';

interface AdviceActivityState {
  isLoading: boolean;
  average: number;
  total: number;
  note: string;
}

export default function InsightsAdviceActivity(props: AdviceActivityState) {
  const total = [props.total];
  const average = [props.average];

  const options = {
    labels: ['Total'],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        customScale: 1.1,
        expandOnClick: false,
        offsetY: 10,
        offsetX: 15,
        donut: {
          size: '90%',
          labels: {
            show: true,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    tooltip: {
      enabled: true,
    },
    fill: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        colorStops: [
          {
            offset: 70,
            color: '#8DE1EB',
            opacity: 0.9,
          },
          {
            offset: 90,
            color: '#649FE5',
            opacity: 0.9,
          },
          {
            offset: 100,
            color: '#3E61DF',
            opacity: 0.9,
          },
        ],
      },
    },
  };

  const options2 = {
    labels: ['Average'],
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        customScale: 1.1,
        offsetY: 10,
        expandOnClick: false,
        offsetX: -15,
        donut: {
          size: '90%',
          labels: {
            show: true,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    tooltip: {
      enabled: true,
    },
    fill: {
      type: 'gradient',
      opacity: 1,
      gradient: {
        colorStops: [
          {
            offset: 70,
            color: '#8DE1EB',
            opacity: 0.9,
          },
          {
            offset: 90,
            color: '#649FE5',
            opacity: 0.9,
          },
          {
            offset: 100,
            color: '#3E61DF',
            opacity: 0.9,
          },
        ],
      },
    },
  };
  return (
    <div className="advice-activity-panel">
      <Row style={{ marginBottom: 45 }}>
        <Col xs="6">
          <Chart options={options} series={total} type="donut" />
        </Col>
        <Col xs="6">
          <Chart options={options2} series={average} type="donut" />
        </Col>
      </Row>
      <Row>
        <p className="advice-activity-note">
          <b style={{ fontWeight: '900' }}>Note:</b> {props.note}
        </p>
      </Row>
    </div>
  );
}
