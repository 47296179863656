import React from 'react';

import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

interface GetInTouchProps {
  message?: string;
}
export default function GetInTouch(props: GetInTouchProps) {
  const history = useHistory();
  const { message } = props;

  return (
    <p>
      {message ?? 'Want to make a change to your subscription. '}
      <Button
        color="link"
        onClick={() => {
          history.push('/assistance/Support');
        }}
      >
        Get in touch
      </Button>
    </p>
  );
}
