// import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row, Button } from 'reactstrap';

import tipIcon from './../../../_assets/img/icons/global/tip.png';
import './styles.scss';

export default function Timeframe() {
  const history = useHistory();

  const options = [
    'Only account for company behaviours and practices that are current',
    'Account for company behaviours and practices from the last 2 years',
    'Account for company behaviours and practices from the last 3 years',
    'Account for company behaviours and practices from the last 4 years',
    'Account for company behaviours and practices from the last 5 years',
  ];
  // const [userAnswers, setUserAnswers] = useState([]);

  // const handleChange = (optionIndex, option) => {
  //   let newArray = [...userAnswers];
  //   let item = { ...newArray[optionIndex] };
  //   item.isChecked = !item.isChecked;
  //   newArray[optionIndex] = item;
  //   setUserAnswers(newArray);
  //   console.log(userAnswers);
  // };

  return (
    <>
      <Container className="profile-feature">
        <Row className="mt-5 justify-content-center">
          <p className="text-center profile-header">Set the time frame for your assessment</p>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col sm={4} className="category-panel">
            <Row className="pl-3 mb-10 justify-content-center">
              <p className="category-title">Set your time frame &rarr;</p>
            </Row>
            <Row className="mb-40">
              <Container className="tips-panel" style={{ width: '70%', padding: '20px' }}>
                <div className="tips-icon">
                  <img src={tipIcon} />
                </div>
                <div className="tips-text">
                  <p className="category-subtitle">
                    For behaviours you wish to avoid, the assesment can be made on current data
                    only, or account for past data. Eg. If you wish to avoid Companies involved in
                    Slave Labour, this assesment can be made on current practices, or if there has
                    ever been involvment, even if not current. <br />
                    <br />
                    Tap done when you’ve finalised your selections.
                  </p>
                </div>
              </Container>
            </Row>
            <Row className="justify-content-center">
              <Button
                onClick={() => history.push('timeframe')}
                className="btn-main-shadowless confirm-button"
              >
                Done
              </Button>
            </Row>
          </Col>
          <Col sm={8} className="category-panel">
            <div className="check-box-wrapper">
              {options.map((option) => (
                <li key={option} className="checkbox-item">
                  <div>
                    <label className="check-box">
                      <span className="check-box-input">
                        <input
                          type="checkbox"
                          className="check-box-inner"
                          // onChange={() => handleChange(index, option)}
                        />
                        <span className="customised-checkbox"></span>
                      </span>
                      <span className="check-box-label">
                        <span className="options-content">{option}</span>
                      </span>
                    </label>
                  </div>
                </li>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
