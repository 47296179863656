import React from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import successIcon from '../../../../_assets/img/icons/graphics/manTrophy@3x.png';
import ButtonLoading from '../../../commons/ButtonLoading';

const SuccessUpload = ({ onBack, onSubmit, clientName }) => (
  <Container>
    <br />
    <Row className="justify-content-center mt-10">
      <img src={successIcon} alt="success" width="184" />
    </Row>
    <Row className="justify-content-center mt-30">
      <h2>Success!</h2>
    </Row>
    <Row className="justify-content-center  mt-30 mb-30">
      You've created a profile for {clientName}.
    </Row>
    <Row className="justify-content-center mt-40">
      <Col sm={6}>
        <Button outline className="btn-secondary" onClick={onBack}>
          Add a portfolio
        </Button>
      </Col>
      <Col sm={6}>
        <ButtonLoading
          className="btn-main-shadowless mt-3"
          label="View profile"
          onClick={onSubmit}
        />
      </Col>
    </Row>
  </Container>
);

export default SuccessUpload;
