import { useHistory } from 'react-router-dom';
import { UncontrolledPopover } from 'reactstrap';

import { redirectToInstrumentPage } from '../../../../_helpers/instrument';

import { IInstrumentBase } from './Results';
import './exposureChart.scss';

interface IExposureDetails {
  item: IInstrumentBase;
  id: string;
}

const ExposureDetails = ({ id, item }: IExposureDetails) => {
  console.log(item, 'item');
  const history = useHistory();

  return (
    <div id={id}>
      <UncontrolledPopover
        placement="top"
        target={id}
        trigger="hover"
        className="exposure-details-popup"
        // modifiers={{
        //   flip: {
        //     enabled: false,
        //   },
        // }}
      >
        <h6 className="exposure-details">Exposure details.</h6>
        <p>This exposure can be found in the following holdings in the Portfolio.</p>
        {item.toolTips.map((toolTip, index) => (
          <p
            className="exposure-details-text cursor-hand"
            key={index}
            onClick={redirectToInstrumentPage(toolTip, history)}
          >
            {toolTip.instrumentName}
          </p>
        ))}
      </UncontrolledPopover>
    </div>
  );
};

export default ExposureDetails;
