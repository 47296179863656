import React from 'react';

import { Doughnut } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';

import Grade from '_components/commons/Grade';
import { LoadingPageSmall } from '_components/commons/LoadingPage';
import Modal from '_components/commons/Modal';
import 'chart.js/auto';
import { GradeInterface } from 'model/Instrument';

import { optionChart } from '../../_constants';
import NoDataView from '../commons/Instrument/NoDataView';

import './Scores.scss';

type InstrumentScores = {
  planetScore: number;
  ethicsScore: number;
  communityScore: number;
  employeesScore: number;
};
const truncate = (text: string | undefined) => {
  if (!text) {
    return '';
  }
  if (text.length > 100) {
    return `${text.substring(0, 120)}`;
  } else {
    return text;
  }
};
const DoughnutContainer = ({ score, color }: { score: number; color: string }) => {
  return (
    <>
      <Doughnut
        // height={350}
        data={GetDonutData(score, color)}
        options={{ ...optionChart, responsive: false }}
        height={102}
        width={102}
        style={{
          position: 'relative',
          zIndex: '2',
          left: '0',
          top: '0',
        }}
      />
    </>
  );
};
const ScoreContainer = ({ score, color, text }: { score: number; color: string; text: string }) => {
  return (
    <div>
      <div className="doughnut-wrapper">
        <div className="donut-text-inner">
          <h5 className="text-body">
            <span className="score-amount">{Math.trunc(score)}</span>
            <span className="score-percent">%</span>
          </h5>
        </div>
        <div
          style={{
            position: 'relative',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <DoughnutContainer score={score} color={color} />
          </div>
        </div>
      </div>
      <p className="mt-2 text-grey text-center">{text}</p>
    </div>
  );
};
const Scores = ({
  scores,
  grade,
  summary,
  logoUrl,
  instrumentName,
  generationDate,
}: {
  scores?: InstrumentScores;
  grade?: GradeInterface;
  summary?: string;
  logoUrl?: string;
  instrumentName?: string;
  generationDate?: string;
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const onClose = () => {
    setIsModalVisible(false);
  };
  const renderContent = () => {
    if (!scores) {
      return <NoDataView />;
    }

    return (
      <>
        <Modal onClose={onClose} isVisible={isModalVisible} hasPrintButton={true}>
          <div style={{ padding: '30px' }}>
            <h4>Sustainability Overview</h4>
            <div style={{ padding: '7px' }}></div>
            <div style={{ display: 'flex', gap: '5px' }}>
              <img
                src={logoUrl}
                alt="logo"
                style={{ width: '50px', height: '50px', borderRadius: '100%' }}
              />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <h5 style={{ fontSize: '20px' }}>{instrumentName}</h5>
                <Grade
                  isUnstyled={true}
                  style={{ fontWeight: 'bold', fontSize: '20px' }}
                  isGradeLabelVisible={true}
                  grade={grade}
                />
              </div>
            </div>
            <div style={{ padding: '18px' }}></div>
            <p>{summary}</p>
            <div style={{ maxWidth: '55%', marginLeft: 'auto', marginRight: 'auto' }}>
              <div style={{ display: 'flex', gap: '30px' }}>
                <ScoreContainer score={scores.planetScore} color="#7be0d3" text="Planet" />
                <ScoreContainer score={scores.ethicsScore} color="#f4b68e" text="Ethics" />
                <ScoreContainer score={scores.communityScore} color="#6aabf0" text="Community" />
                <ScoreContainer score={scores.employeesScore} color="#7d6af0" text="Employees" />
              </div>
            </div>
            <div style={{ padding: '18px' }}></div>
            <p>Date generated on {generationDate}</p>
            <div>
              <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
                *This content has been generated by an artificial intelligence language model. While
                we strive for accuracy and quality, please note that the information provided may
                not be entirely error-free or up-to-date. We recommend independently verifying the
                content and consulting with professionals for specific advice or information. We do
                not assume any responsibility or liability for the use or interpretation of this
                content. If you think this AI response inaccurate or missing information, please
                email us here
              </p>
            </div>
          </div>
        </Modal>
        <Row>
          <Col sm={5}>
            <Grade style={{ fontWeight: 'bold' }} isGradeLabelVisible={true} grade={grade} />
          </Col>
          <Col sm={7}>
            {!summary || summary === '' ? (
              <LoadingPageSmall />
            ) : (
              <p>
                {truncate(summary)}{' '}
                <a onClick={() => setIsModalVisible(true)} type="button">
                  see more
                </a>
              </p>
            )}
          </Col>
        </Row>
        <div
          style={{
            padding: '5px',
          }}
        ></div>
        <Row>
          <Col sm={3}>
            <ScoreContainer score={scores.planetScore} color="#7be0d3" text="Planet" />
          </Col>
          <Col sm={3}>
            <ScoreContainer score={scores.ethicsScore} color="#f4b68e" text="Ethics" />
          </Col>
          <Col sm={3}>
            <ScoreContainer score={scores.communityScore} color="#6aabf0" text="Community" />
          </Col>
          <Col sm={3}>
            <ScoreContainer score={scores.employeesScore} color="#7d6af0" text="Emplyoyees" />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className="border-0 p-4 panel flex-grow-1 mb-40">
      <h4 className="d-block mb-30">Sustainability overview</h4>
      {renderContent()}
    </div>
  );
};

function GetDonutData(score: number, color: string) {
  const data = {
    datasets: [
      {
        data: [100 - score, score],
        backgroundColor: ['#F2EFED', color],
        hoverBackgroundColor: ['#F2EFED', color],
        borderRadius: [{}, { innerEnd: 200, outerEnd: 200, innerStart: 200, outerStart: 200 }],
        // spacing: -5,
      },
    ],
    // labels: ['unscored', 'score'],
  };
  return data;
}

export default Scores;
