import React, { PureComponent } from 'react';

import InputMask from 'react-input-mask';
import { Label, Input, FormGroup, FormFeedback } from 'reactstrap';

import PropTypes from 'prop-types';

import ValidatorService from '../../service/validator';

class TextField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    handleKeyPress: PropTypes.func,
    control: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    validateInput: PropTypes.func,
    placeholder: PropTypes.string,
    feedBackLabel: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    hasValid: PropTypes.bool,
    row: PropTypes.bool,
    invalid: PropTypes.bool,
    validate: PropTypes.bool,
    handleSubmitted: PropTypes.bool,
    cleanAfterSubmit: PropTypes.bool,
  };

  static defaultProps = {
    label: null,
    maxLength: 50,
    minLength: 2,
    onBlur: null,
    value: '',
    disabled: false,
    validateInput: null,
    handleKeyPress: null,
    placeholder: '',
    required: false,
    row: null,
    feedBackLabel: null,
    invalid: undefined,
    hasValid: null,
    validate: true,
    handleSubmitted: false,
    cleanAfterSubmit: false,
  };

  constructor(props) {
    super(props);
    this.state = { valid: undefined };
  }

  validateInput(value) {
    if (!this.props.validate) {
      return null;
    }
    if (this.props.validateInput) {
      const valid = this.props.validateInput(value);
      return this.setState(() => ({ valid }));
    }
    const { maxLength, minLength } = this.props;
    const valid = ValidatorService.validateText(value, maxLength, minLength);
    return this.setState(() => ({ valid }));
  }

  async handleChange({ target: { value } }) {
    if (this.props.value === value) {
      return;
    }
    await this.props.onChange({ target: { value, id: this.props.control } });
    this.validateInput(value);
  }

  handleBlur(value) {
    if (!value || !this.props.onBlur) {
      return;
    }
    this.validateInput(value);
    this.props.onBlur(value);
  }

  cleanValid() {
    this.setState(() => ({ valid: null }));
  }

  render() {
    const {
      control,
      label,
      value,
      maxLength,
      minLength,
      disabled,
      handleKeyPress,
      required,
      placeholder,
      hasValid,
      feedBackLabel,
      invalid,
      validateInput,
      validate,
      handleSubmitted,
      cleanAfterSubmit,
      ...props
    } = this.props;
    const { valid } = this.state;
    if (this.props.cleanAfterSubmit) {
      this.cleanValid();
    }
    return (
      <FormGroup row={this.props.row}>
        {label && <Label>{label}</Label>}
        <Input
          onKeyPress={handleKeyPress}
          onBlur={(e) => this.handleBlur(e.target.value)}
          invalid={!handleSubmitted ? !hasValid && (valid === false || invalid) : !valid}
          name={control}
          valid={!handleSubmitted ? hasValid || valid : valid}
          {...{
            required,
            value,
            minLength,
            disabled,
            placeholder,
          }}
          {...props}
          onChange={(e) => this.handleChange(e)}
          type="text"
          mask="99/99"
          tag={InputMask}
        />
        {feedBackLabel && <FormFeedback>{feedBackLabel}</FormFeedback>}
      </FormGroup>
    );
  }
}

export default TextField;
