import { size, inRange } from 'lodash';

// eslint-disable-next-line max-len
const emailRegex = /^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/;
const stringRegex = /^[a-zA-Z ]*$/;
const stringWithCommaRegex = /^[a-zA-Z0-9\s,'-]*$/;
const lettersRegex = /^[a-zA-ZñÑ\s]+$/;
const numbersRegex = /^[0-9]+$/;
const numbersAndSlashRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
const monthRegex = /^(0[1-9]|1[0-2])$/;
const numbersAndSpacesRegex = /^(\s*[0-9]+\s*)+$/;
const lettersOrNumberRegex = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
const time12HourRegex = /^(1[0-1]|0?[0-9]):[0-5][0-9]$/i;
const urlRegex = /^(http(s)?:\/\/)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export default class ValidatorService {
  static validateText(input, max = 50, min = 2) {
    return stringRegex.test(input) && inRange(size(input), min, max + 1);
  }

  static validateUrl(url) {
    return urlRegex.test(url);
  }

  static validateEmail(email) {
    return emailRegex.test(email);
  }

  static validateInputTextOrNumber(input, max = 50, min = 2) {
    return lettersOrNumberRegex.test(input) && input && input.length < max && input.length > min;
  }

  static validateInputTextWithComma(input, max = 50, min = 2) {
    return stringWithCommaRegex.test(input) && input && input.length < max && input.length > min;
  }

  static validateInputTextOrNumberWithoutRange(input) {
    return lettersOrNumberRegex.test(input);
  }

  static validateInputText(input, max = 50, min = 2) {
    return lettersRegex.test(input);
  }

  static validateInputNumber(input) {
    return numbersRegex.test(input);
  }

  static validateInputNumberWithSize(input, max = 50, min = 2) {
    return numbersRegex.test(input) && input && input.length < max && input.length > min;
  }

  static validateInputCreditCard(input, max = 50, min = 2) {
    return numbersAndSpacesRegex.test(input) && input && input.length > min;
  }

  static validateInputDate(input, max = 50, min = 2) {
    return numbersAndSlashRegex.test(input) && input && input.length > min;
  }

  static validateInputMonthDate(input) {
    return monthRegex.test(input) && input;
  }

  static validateInputLongDate(input, max = 11, min = 9) {
    return dateRegex.test(input) && input && input.length < max && input.length > min;
  }

  static validate12HourTimeFormat(input) {
    return input && time12HourRegex.test(input);
  }

  static validateInputTextAndRange(input, max = 50, min = 2) {
    return inRange(size(input), min, max + 1) && lettersRegex.test(input);
  }

  static passwordFeedback(password) {
    if (!password) {
      return 'Please insert password';
    }
    if (password.length < 8) {
      return 'Password must be at least 8 characters';
    }
  }

  static validateRegisterForm(user, errors) {
    if (!user.firstName) {
      errors.firstName = 'Please insert valid first name';
    }
    if (!user.lastName) {
      errors.lastName = 'Please insert valid last name';
    }
    if (!user.email) {
      errors.email = 'Please insert email';
    }
    if (!ValidatorService.validateEmail(user.email)) {
      errors.email = 'Please insert a valid email';
    }
    if (!user.password) {
      errors.password = 'Please insert valid password';
    }
    if (user.password && user.password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }
    return errors;
  }

  static validatePassword(pass, { password, ...errors }) {
    if (!pass) {
      errors.password = 'Please insert valid password';
    }
    if (pass && pass.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }
    return errors;
  }
}
