import React, { PureComponent } from 'react';

import { Row, Col } from 'reactstrap';

class SectionTitle extends PureComponent {
  static defaultProps = {
    results: null,
  };

  render() {
    return (
      <Row style={{ marginTop: 15 }}>
        <Col sm="6">
          <h5>{this.props.title}</h5>
          <h2 className="font-weight-bold">{this.props.subTitle}</h2>
        </Col>

        {this.props.command && (
          <Col
            sm="6"
            className="d-flex align-items-end justify-content-end"
            style={{ marginBottom: '0.5rem' }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href={this.props.command.href}>{this.props.command.label}</a>
          </Col>
        )}

        {this.props.results !== null && (
          <Col sm="12">
            <span className="result-text">{this.props.results} results.</span>
          </Col>
        )}
      </Row>
    );
  }
}

export default SectionTitle;
