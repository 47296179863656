import { isEmpty } from 'lodash';
import React from 'react';

import { InstrumentInterface, InstrumentType, Instrument, IToolTip } from '../model/Instrument';

export const redirectToInstrumentPage =
  (value: InstrumentInterface | Instrument | IToolTip, history: any) =>
  (event: React.MouseEvent) => {
    const pathArray = history.location.pathname.split('/');

    let navigateTo = '';

    if ('id' in value || 'instrumentId' in value) {
      const idValue = 'id' in value ? (value as Instrument).id : (value as IToolTip).instrumentId;
      if (pathArray[1] === 'clients') {
        navigateTo = `/${pathArray[1]}/${pathArray[2]}/instruments/${idValue}/results`;
      } else {
        navigateTo = `/${pathArray[1]}/instruments/${idValue}/results`;
      }
    }

    history.push({
      pathname: navigateTo,
    });
    event.preventDefault();
  };

export const getAppliedFilters = (filtersAndSortBy: any): string[] => {
  let filters: string[] = [];
  if (filtersAndSortBy?.filters && !isEmpty(filtersAndSortBy?.filters)) {
    filtersAndSortBy?.filters?.forEach((filter: any) => {
      filters = [...filters, ...filter.options];
    });
  }
  return filters;
};

export const showTableData = (field: unknown) => {
  switch (typeof field) {
    case 'string':
      return field === '' ? '-' : field;
    case 'number':
      return field.toString();
    case 'boolean':
      return field ? 'Yes' : 'No';
    default:
      return field ? JSON.stringify(field) : '-';
  }
};

export function getInstrumentKey(value: string) {
  return Object.entries(InstrumentType).find(([, val]) => val === value)?.[0];
}
