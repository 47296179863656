import React from 'react';

import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import PropTypes from 'prop-types';

import SortableItem from './SortableItem';

const containerStyle = {
  padding: 15,
  margin: 10,
  flex: 1,
};

export default function CardContainer(props) {
  const { id, items, className } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext
      id={id}
      items={items.map((item) => item.id)}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} style={containerStyle} className={`a-list ${className}`}>
        {items.map((item) => (
          <SortableItem key={item.id} id={item.id} item={item} />
        ))}
      </div>
    </SortableContext>
  );
}

CardContainer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      behaviourDetail: PropTypes.string,
      category: PropTypes.string,
      iconUrl: PropTypes.string,
      id: PropTypes.string,
      imageFileName: PropTypes.string,
      isForConflict: PropTypes.bool,
      isForMatch: PropTypes.bool,
      question: PropTypes.string,
      questionDesc: PropTypes.string,
    }),
  ),
};
