import api, { apiSetup } from '../config/api';

import { getSessionToken } from './LocalStorageService';

export function getPreferenceQandA(params) {
  const getPreferenceQandARequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Profiling/GetPreferenceQandA', params)
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getPreferenceQandARequest);
}

export function getExclusionQandA(params) {
  const getExclusionQandARequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Profiling/GetExclusionQandA', params)
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getExclusionQandARequest);
}

export function getChoiceModellingQandA(params) {
  const getChoiceModellingQandARequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Profiling/GetChoiceModellingQandA', params)
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getChoiceModellingQandARequest);
}

export function getBehaviourDetails(params) {
  const getBehaviourDetailsRequest = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Profiling/BehaviourDetails', params)
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getBehaviourDetailsRequest);
}

export function getProfileMatches(params) {
  const getProfileMatches = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Profiling/GetProfileMatches', params)
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(getProfileMatches);
}

export function createUpdateProfile(params) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Profiling/CreateUpdateProfile', params)
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'network error' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}

export function getActiveProfileByClientId(ClientCoreId) {
  const request = (resolve, reject) => {
    apiSetup();
    api
      .post('/api/Profiling/GetActiveProfileByClientId', { ClientCoreId })
      .then((res) => {
        if (res.ok && res.data.httpStatusCode === 200) {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'network error' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(request);
}
