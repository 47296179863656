import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Comparison from '_components/clients/Comparison/Comparison';
import Insights from '_components/insights';
import Layout from '_components/ui/Layout';
import Presets from '_components/ui/Presets';
import BasicInformation from '_components/user/Register/Steps/BasicInformation';
import VerifyEmail from '_components/user/VerifyEmail';

import Account from '../account';
import Assistance from '../assistance';
import Clients from '../clients';
import Research from '../research';
import Search from '../search';
import RegisterPage from '../user/Register/RegisterPage';

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/search" component={Research} />
      <Route path="/account" component={Account} />
      <Route path="/assistance" component={Assistance} />
      {/* <Route path="/compare" component={CompareInvestments} /> */}
      {/* <Route path="/search" component={Search} /> */}
      <Route path="/clients" component={Clients} />
      <Routes path="/compare" component={Comparison} />
      <Route path="/insights" component={Insights} />
      <Route path="/" component={Clients} />
      {/* Sign Up */}
      <Route render={() => <Layout component={VerifyEmail} header={Presets.signUp} />} />
      {/* Subscriptions */}
      {/* <Route
        path={`/register/inviteCodeApplied`}
        render={() => <Layout component={InviteCodeApplied} header={Presets.signUp} />}
      />
      <Route
        path={`/register/inviteCodeSkip`}
        render={() => <Layout component={InviteCodeSkip} header={Presets.signUp} />}
      />
      <Route
        path={`/register/inviteCode`}
        render={() => <Layout component={InviteCode} header={Presets.signUp} />}
      /> */}
      <Route
        path="/register/basicInformation"
        component={BasicInformation}
        header={Presets.signUp}
      />
      <Route render={() => <Layout component={StepControl} header={Presets.signUp} />} />
      {/* Without Session */}
      <Route
        path={`/forgotpassword`}
        render={() => <Layout component={ForgotPassword} header={Presets.signUp} />}
      />
      <Route
        path="/resetPassword"
        render={() => <Layout component={ResetPassword} header={Presets.signUp} />}
      />
      <Route path="/register" component={RegisterPage} />
      {/* <Route path="/login" component={Login} />  
      <Route path="/" component={Login} /> */}
    </Switch>
  </Router>
);

export default Routes;
