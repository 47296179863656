import { useEffect, useState } from 'react';

import { ListGroupItem, Button } from 'reactstrap';

import { LoadingSpinner } from '_components/commons';
import DateUtilsService from 'service/DateUtils';
import { setPortfolioIsActive } from 'service/PortfolioService';

import PortfolioCheckbox from './PortfolioCheckbox';

type Instrument = {
  id: string;
  clientPortfolioId: string;
  instrumentId: string;
  instrumentName: string;
  exchange: string;
  ticker: string;
  apirCode: string;
  isin: string;
  citiCode: string;
  type: string;
  percentage: number;
};

type Portfolio = {
  loading: boolean;
  clientCoreId: string;
  clientPortfolioId: string;
  isSelected: boolean;
  adviserClientPortfolioDetails: Instrument[];
  matchedInstruments: Instrument[];
  portfolioName: string;
  unMatchedInstruments: Instrument[];
  updatedWhen: string;
};
type Props = {
  viewItems: (portfolioId: string) => void;
  portfolios: Portfolio[];
  handleRemove: (portfolio: Portfolio, idx: number) => void;
};
export default function PortfoliosContainer({
  portfolios: portfoliosProp,
  viewItems,
  handleRemove,
}: Props) {
  console.log(portfoliosProp, 'portfolioContainer');
  const [portfoliosState, setPortfoliosState] = useState<Portfolio[]>([]);
  const selectPortfolio = function (portfolioId: string, clientId: string) {
    const portfoliosCopy = [...portfoliosState];
    for (let i = 0; i < portfoliosCopy.length; ++i) {
      const portfolio = portfoliosCopy[i];
      portfoliosCopy[i].isSelected = false;
      if (portfolio.clientPortfolioId === portfolioId) {
        portfoliosCopy[i].isSelected = true;
      }
    }
    setPortfoliosState(portfoliosCopy);
    setPortfolioIsActive({
      clientId,
      portfolioId,
    });
  };
  useEffect(() => {
    setPortfoliosState(portfoliosProp);
  }, [portfoliosProp]);
  return (
    <>
      {portfoliosState.map((portfolio, idx) => (
        <>
          <ListGroupItem key={portfolio.clientPortfolioId}>
            <div className="list-item-layout w-100">
              <div className="list-item-description d-flex justify-content-start align-items-center flex-grow-1 p-1">
                <Button
                  color="link"
                  className="review-portfolio-button"
                  onClick={() => viewItems(portfolio.clientPortfolioId)}
                >
                  {portfolio.portfolioName} {DateUtilsService.formatDate(portfolio.updatedWhen)}
                </Button>
              </div>
              <div className="list-item-info d-flex justify-content-center align-items-center p-1 flex-column justify-content-center">
                {portfolio.loading ? (
                  <LoadingSpinner stroke="#007bff" />
                ) : (
                  <Button color="link" className="p-0" onClick={() => handleRemove(portfolio, idx)}>
                    Remove
                  </Button>
                )}
              </div>
            </div>
          </ListGroupItem>
          <PortfolioCheckbox
            key={`${portfolio.clientPortfolioId}-2`}
            isSelected={portfolio.isSelected}
            portfolios={portfoliosState}
            selectPortfolio={selectPortfolio}
            portfolioId={portfolio.clientPortfolioId}
            clientId={portfolio.clientCoreId}
            setState={setPortfoliosState}
          />
        </>
      ))}
    </>
  );
}
