import { isEmpty } from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Row, Col } from 'reactstrap';

import checkIcon from '../../../_assets/img/icons/global/check@2x.png';
import notification from '../../../_assets/img/icons/global/notification@3x.png';
import tipIcon from '../../../_assets/img/icons/profile/Bulb@1.5x.png';
import { getApl } from '../../../service/AplService';
import DateUtilsService from '../../../service/DateUtils';
import { ButtonLoading } from '../../commons';

const AplSummary = () => {
  const history = useHistory();
  const [apl, setApl] = useState({});
  const [loadingApl, setLoadingApl] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    getApl()
      .then((response) => {
        setApl(response);
        setLoadingApl(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setShowMessage(true);
        setMessage(err.message);
        setLoadingApl(false);
      });
  }, []);

  if (loadingApl) {
    return <Fragment />;
  }

  const icon = apl.unMatchedApprovedProductListItems.length == 0 ? checkIcon : notification;
  const matchCountColor =
    apl.unMatchedApprovedProductListItems.length > 0 ? { color: '#AC003C' } : { color: '#2AB564' };
  const apls = apl.matchedApprovedProductListItems.concat(apl.unMatchedApprovedProductListItems);
  const uploadDate = DateUtilsService.formatDate(apl.dateUploaded);

  return (
    <Fragment>
      <div>
        <div className="ml-40">
          <div className="d-flex mt-50">
            <div className="justify-content-start align-items-start p-1">
              <img src={icon} alt="tip" width="30" />
            </div>
            <div className="justify-content-center align-items-center p-2 flex-grow-1 justify-content-between">
              <h3>Uploaded APL review</h3>
            </div>
          </div>
          <div>
            <p style={{ fontSize: '20px', color: '#111111' }}>
              Portfolio Name: {apl.name} | Uploaded on {uploadDate}
            </p>
            <p style={{ marginTop: '-10px' }}>
              <span style={matchCountColor}>{apl.matchedApprovedProductListItems.length}</span> of
              the <span style={{ color: '#111111' }}>{apls.length}</span> products uploaded have
              been successfully matched
            </p>
            <br />
            <br />
          </div>
        </div>
        <div>
          {apl.unMatchedApprovedProductListItems.length > 0 && (
            <div>
              <div className="tips-panel ml-40 mb-5" style={{ marginTop: '-50px' }}>
                <img src={tipIcon} alt="icon" className="tip-icon" />
                For unmatched data please check spelling and codes are correct and re-upload file.
              </div>
            </div>
          )}
        </div>
        {apls.map((details, id) => {
          const matchText =
            details.instrumentId === null ? 'Data match failed' : 'Data match succesful';
          const matchedName = details.instrumentId === null ? 'Unmatched Name' : 'Matched Name';

          const color = details.instrumentId === null ? '#AC003C' : '#2AB564';

          return (
            <div key={id} className="ml-30">
              <Row>
                <Col sm={6}>Name:</Col>
                <Col sm={6}>{details.instrumentName}</Col>
              </Row>
              <Row>
                <Col sm={6}>Ticker:</Col>
                <Col sm={6}>{details.symbol}</Col>
              </Row>
              <Row>
                <Col sm={6}>Exchange:</Col>
                <Col sm={6}>{details.exchange}</Col>
              </Row>
              <Row>
                <Col sm={6}>ISIN:</Col>
                <Col sm={6}>{details.isin}</Col>
              </Row>
              <Row>
                <Col sm={6}>{matchedName}:</Col>
                <Col sm={6} style={{ color: color }}>
                  {matchText}
                </Col>
              </Row>
              <br />
            </div>
          );
        })}
        <Row className="ml-5 mt-50">
          <Col sm={6}>
            <Button
              outline
              className="btn-secondary"
              onClick={() => {
                history.push(`/reviewApl`);
              }}
            >
              Close and re-upload
            </Button>
          </Col>
          <Col sm={6}>
            <ButtonLoading
              label="View Client List"
              className="btn-main-shadowless"
              small
              onClick={() => {
                history.push(`/clients`);
              }}
            />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AplSummary;
