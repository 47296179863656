type Instrument = {
  id: string;
  clientPortfolioId: string;
  instrumentId: string;
  instrumentName: string;
  exchange: string;
  ticker: string;
  apirCode: string;
  isin: string;
  citiCode: string;
  type: string;
  percentage: number;
};
type Portfolio = {
  loading: boolean;
  clientCoreId: string;
  clientPortfolioId: string;
  isSelected: boolean;
  adviserClientPortfolioDetails: Instrument[];
  matchedInstruments: Instrument[];
  portfolioName: string;
  unMatchedInstruments: Instrument[];
  updatedWhen: string;
};
type Props = {
  isSelected: boolean | undefined;
  clientId: string;
  portfolioId: string;
  portfolios: Portfolio[];
  selectPortfolio: (portfolioId: string, clientId: string) => void;
  setState: React.Dispatch<React.SetStateAction<Portfolio[]>>;
};
export default function PortfolioCheckbox(props: Props) {
  const { isSelected: isSelectedProp, clientId, portfolioId, selectPortfolio } = props;
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={3}
        stroke="#3f61df"
        style={{ opacity: isSelectedProp ? '1' : '0', width: '30px', height: '30px' }}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>

      <input
        type="checkbox"
        onChange={() => void selectPortfolio(portfolioId, clientId)}
        checked={isSelectedProp}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          opacity: '0',
        }}
      />
    </div>
  );
}
