import apiAuth, { apiAuthSetup } from '../config/apiAuth';

import { getSessionToken } from './LocalStorageService';

const { REACT_APP_AUTORIZATION_APP } = process.env;

export default class Auth {
  static async login(params) {
    apiAuth.setHeaders({ AuthorizationApp: 'Bearer ' + REACT_APP_AUTORIZATION_APP });
    return await apiAuth.post('/api/user/login', params);
  }

  static async loginWithCode(code) {
    apiAuth.setHeaders({ AuthorizationApp: 'Bearer ' + REACT_APP_AUTORIZATION_APP });
    return await apiAuth.get('/api/authenticate?code=' + code);
  }

  static forgotPassword(email) {
    apiAuth.setHeaders({ AuthorizationApp: 'Bearer ' + REACT_APP_AUTORIZATION_APP });
    return apiAuth.post('/api/user/forgetPasswordRequest', { email });
  }

  static async saveUser({ _id, ...userIn }) {
    userIn.acceptLegals = true;
    apiAuth.setHeaders({ AuthorizationApp: 'Bearer ' + REACT_APP_AUTORIZATION_APP });
    const saved = await apiAuth.post('/api/user/advisersignup', userIn);
    if (saved.problem) {
      return { error: saved.data.message };
    }
    apiAuth.setHeaders({ AuthorizationApp: 'Bearer ' + REACT_APP_AUTORIZATION_APP });
    const login = await apiAuth.post('/api/user/login', {
      Email: userIn.email,
      Password: userIn.password,
    });
    return { saved, login };
  }

  static isPasswordStrong(password) {
    apiAuth.setHeaders({ AuthorizationApp: 'Bearer ' + REACT_APP_AUTORIZATION_APP });
    return apiAuth.post('api/user/IsPasswordStrong', { password });
  }

  static validateShareCode(inviteCode) {
    apiAuth.setHeaders({ AuthorizationApp: 'Bearer ' + REACT_APP_AUTORIZATION_APP });
    return apiAuth.post('api/user/ValidateShareCode', { codeEntered: inviteCode });
  }

  static requestVerificationEmail() {
    apiAuth.setHeaders({ Authorization: 'Bearer ' + getSessionToken() });
    return apiAuth.get('api/user/resendverifyemail');
  }

  static getUser() {
    apiAuth.setHeaders({ Authorization: 'Bearer ' + getSessionToken() });
    apiAuthSetup();
    return apiAuth.get('api/user');
  }

  static setWelcomeScreen() {
    apiAuth.setHeaders({ Authorization: 'Bearer ' + getSessionToken() });
    apiAuthSetup();
    return apiAuth.get('api/confirmWelcome');
  }
}

export function emailavailable(params) {
  const emailavailableRequest = (resolve, reject) => {
    apiAuth.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      'Content-Type': 'application/json',
    });
    apiAuth
      .post('/api/user/emailavailable', params)
      .then((res) => {
        if (res.ok && res.status === 200 && res.data.httpStatusCode === 200) {
          if (res.data.emailAvailable === true) {
            resolve(res.data);
          } else {
            reject({ message: 'email already exist' });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(emailavailableRequest);
}

export function isPasswordStrong(params) {
  const isPasswordStrongRequest = (resolve, reject) => {
    apiAuth.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      'Content-Type': 'application/json',
    });
    apiAuth
      .post('/api/user/IsPasswordStrong', params)
      .then((res) => {
        if (res.ok && res.status === 200) {
          if (res.data.isStrongPassword) {
            resolve(res.data);
          } else {
            reject({ message: res.data.message });
          }
        } else {
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(isPasswordStrongRequest);
}

export function register(params) {
  const registerRequest = (resolve, reject) => {
    apiAuth.setHeaders({
      AuthorizationApp: 'Bearer ' + process.env.REACT_APP_AUTORIZATION_APP,
      'Content-Type': 'application/json',
    });
    apiAuth
      .post('/api/user/advisersignup', params)
      .then((res) => {
        if (res.ok) {
          if (res.status === 200) {
            resolve(res.data);
          }
        } else {
          if (res.status === 409) {
            reject({ message: res.data.message });
          }
          reject({ message: 'something went wrong' });
        }
      })
      .catch((err) => {
        reject({ message: err });
      });
  };
  return new Promise(registerRequest);
}

export function resetPassword(email) {
  return apiAuth.put('auth/send_reset_password_instructions', { email });
}

export function resetNewPassword(body) {
  return apiAuth.put('auth/reset_password', body);
}
