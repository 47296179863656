import React from 'react';

import { Card, Col, Row } from 'reactstrap';

import { ButtonLoading } from '../commons';
import ButtonModal from '../commons/ButtonModal';

import InfoModal from './InfoModal';

// currentActionStatus Add = 1, Retain = 2, Remove = 3, Review = 4, None is everything else

const AdviceOutputActions = ({
  markActionLoading,
  makeAsAdd,
  makeAsRetain,
  makeAsRemove,
  makeAsReview,
  inPortfolio,
  disabledMarks,
  currentActionStatus,
}) => {
  let mrkAsReview = currentActionStatus === 4 ? "Marked as 'Review'" : "Mark as 'Review'";
  let mrkAsRemove = currentActionStatus === 3 ? "Marked as 'Remove'" : "Mark as 'Remove'";
  let mrkAsRetain = currentActionStatus === 2 ? "Marked as 'Retain'" : "Mark as 'Retain'";
  let mrkAsAdd = currentActionStatus === 1 ? "Marked as 'Add'" : "Mark as 'Add'";

  return (
    <Row>
      <Col sm={12}>
        <div className="advice-output">
          <div className="content-advice-output-btns padding-10">
            <ButtonLoading
              style={{ color: '#2E3074' }}
              className="btn-main-shadowless lightblue-btn advice-output-btn"
              onClick={makeAsReview}
              label={mrkAsReview}
              disabled={disabledMarks}
              loading={markActionLoading}
            ></ButtonLoading>
            <ButtonLoading
              style={{ color: '#2E3074' }}
              className="btn-main-shadowless lightblue-btn advice-output-btn"
              onClick={makeAsRemove}
              label={mrkAsRemove}
              disabled={disabledMarks}
              loading={markActionLoading}
            ></ButtonLoading>
            {inPortfolio && (
              <ButtonLoading
                style={{ color: '#2E3074' }}
                className="btn-main-shadowless lightblue-btn advice-output-btn"
                onClick={makeAsRetain}
                label={mrkAsRetain}
                disabled={disabledMarks}
                loading={markActionLoading}
              ></ButtonLoading>
            )}
            {!inPortfolio && (
              <ButtonLoading
                style={{ color: '#2E3074' }}
                className="btn-main-shadowless lightblue-btn advice-output-btn"
                onClick={makeAsAdd}
                label={mrkAsAdd}
                disabled={disabledMarks}
                loading={markActionLoading}
              ></ButtonLoading>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AdviceOutputActions;
