import { Col, Row } from 'reactstrap';

import emptyProfile from '../../../../_assets/img/icons/graphics/values-diamond@3x.png';
import { TileType } from '../../../../model/enums';

interface EmptyResultsProps {
  selectedTileType: string;
  clientFirstName?: string;
}

export default function EmptyResults(props: EmptyResultsProps) {
  switch (props.selectedTileType) {
    case TileType['SustainabilityOverview']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="Empty" width="182" />
          </Row>
          <Row className="mt-30">
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No sustainability overview now`}</h3>
            </Col>
          </Row>
        </div>
      );
    }
    case TileType['PortfolioConflicts']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="Empty" width="182" />
          </Row>
          <Row className="mt-30">
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No conflicts!`}</h3>
              {`${props.clientFirstName}'s portfolio is in great shape.`}
            </Col>
          </Row>
        </div>
      );
    }
    case TileType['PortfolioMatches']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="Empty" width="182" />
          </Row>
          <Row className="mt-30">
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No matches!`}</h3>
              {`${props.clientFirstName}'s portfolio doesn't match any of the profile questions.`}
            </Col>
          </Row>
        </div>
      );
    }
    case TileType['PortfolioAlternatives']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="Empty" width="182" />
          </Row>
          <Row className="mt-30">
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No alternatives!`}</h3>
            </Col>
          </Row>
        </div>
      );
    }
    case TileType['ProfileAlternatives']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="laptop" width="182" />
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No alternatives!`}</h3>
            </Col>
          </Row>
        </div>
      );
    }
    case TileType['ProfileConflicts']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="laptop" width="182" />
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No conflicts!`}</h3>
            </Col>
          </Row>
        </div>
      );
    }
    case TileType['ProfileMatches']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="laptop" width="182" />
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No matches!`}</h3>
            </Col>
          </Row>
        </div>
      );
    }
    case TileType['AplAlternatives']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="laptop" width="182" />
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No alternatives!`}</h3>
            </Col>
          </Row>
        </div>
      );
    }
    case TileType['AplConflicts']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="laptop" width="182" />
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No conflicts!`}</h3>
            </Col>
          </Row>
        </div>
      );
    }
    case TileType['AplMatches']: {
      return (
        <div>
          <Row className="justify-content-center mt-50 mb-40">
            <img src={emptyProfile} alt="laptop" width="182" />
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <h3>{`No matches!`}</h3>
            </Col>
          </Row>
        </div>
      );
    }
  }
  return <></>;
}
