import { useContext, useState, Fragment, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Button, Container, Row, Col } from 'reactstrap';

import { ProfileContext } from 'store/ProfileContext';

import termsDoc from '../../_assets/documents/Oko Adviser Terms of Service.pdf';
import referralEmailTemplate from '../../_assets/documents/Referral Email Template.pdf';
import { getMyShareCode } from '../../service/ShareCodeService';
import { LoadingPage, PageHeader } from '../commons';

const AccountInvite = (props) => {
  const profileCtx = useContext(ProfileContext);
  const [code, setCode] = useState('');
  const [copyCodeText, setCopyCodeText] = useState('Copy invite code');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMyShareCode()
      .then((response) => {
        setCode(response.shareCode);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // this.setState({ showMessage: true, message: err.message, loading: false });
        toast.error(err?.message);
      });
  }, []);

  const copyCode = () => {
    const copyText = document.getElementById('codeText');
    const textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    setCopyCodeText('Code copied!');
    setTimeout(() => {
      setCopyCodeText('Copy invite code');
    }, 1700);
  };

  const handleLogout = () => {
    profileCtx.logOut();
  };

  return (
    <div className="slim-content">
      <Container>
        <PageHeader
          title="Invite other advisers"
          style={{ paddingLeft: '-15px' }}
          rightButton={
            <Button color="link" onClick={() => handleLogout()}>
              Sign out
            </Button>
          }
        />
        {loading && <LoadingPage />}
        {!loading && (
          <Col>
            <Fragment>
              <Row className="mb-20">
                <p>
                  Know other advisers who could use some help in having a conversation with their
                  clients around investing with their values?
                </p>
                <p>
                  Use your referral code to invite others to Oko Adviser. For every referral who
                  signs up to a paid plan using your code, you will receive a 10% discount on your
                  subscription plan.
                </p>
                <p>
                  For monthly plans, the discount will apply to the next month's payment. For annual
                  subscriptions, a refund of 10% on the current subscription will be applied.
                </p>
              </Row>
              <Row>
                <span className="mb-2">Share your invite code</span>
                <Col sm={12}>
                  <Row className="account-invite-code">
                    <span id="codeText">{code}</span>
                    <Button className="btn" style={{ width: '186px' }} onClick={() => copyCode()}>
                      {copyCodeText}
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-20">
                <span className="mr-10">Are you an enterprise?</span>
                <Button color="link" href="https://okoadviser.com/">
                  Get in touch
                </Button>
              </Row>
              <div className="mt-40 mb-5">
                <Row className="mt-40 mb-3">
                  <Button
                    color="link"
                    href={referralEmailTemplate}
                    target="_blank"
                    alt="download email template"
                  >
                    Download an email template
                  </Button>
                </Row>
                <Row className="mt-10">
                  <Button color="link" href={termsDoc} target="_blank" alt="Terms and conditions">
                    Terms and conditions
                  </Button>
                </Row>
              </div>
            </Fragment>
          </Col>
        )}
      </Container>
    </div>
  );
};

export default AccountInvite;
