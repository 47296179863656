import { isEmpty, forEach, last } from 'lodash';
import React, { PureComponent } from 'react';

import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';

import { clientDefaultRow, clientRow } from '../../../_constants';
import ValidatorService from '../../../service/validator';
import { CopyPaste } from '../../commons';

import ExampleCard from './ExampleCard';

class ClientCopyPaste extends PureComponent {
  handleSubmit(grid) {
    if (!last(last(grid)).value) {
      grid.pop();
    }
    const clients = [];
    let error = false;
    forEach(grid, async (row) => {
      if (!row[3].value) {
        return;
      }
      if (row[3].value && !ValidatorService.validateEmail(row[3].value)) {
        error = true;
        return toast.error(`Check email in row # ${row[0].value}`);
      }
      const client = {
        ClientFirstName: row[1].value,
        ClientLastName: row[2].value,
        ClientEmailAddress: row[3].value,
      };
      clients.push(client);
    });
    if (!isEmpty(clients) && !error) {
      return this.props.onSubmit(clients);
    }
    return toast.error(`Check data has error`);
  }

  render() {
    const { onBack, loading } = this.props;
    return (
      <Container>
        <ExampleCard />
        <div className="step-copy-paste">
          Paste email address, first name and last name fields from a spreedsheet.
        </div>
        <CopyPaste
          defaultRow={clientDefaultRow}
          newRow={clientRow}
          onSubmit={(grid) => this.handleSubmit(grid)}
          onBack={onBack}
          loading={loading}
          buttonText="Add clients to my list"
        />
        <br />
        <Row>
          <p className="text-comment">
            Duplicate addresses will be removed. We do not send confirmation emails to imported
            addresses and trust that you've gathered proper permission to send to every address in
            your audience.
          </p>
        </Row>
      </Container>
    );
  }
}

export default ClientCopyPaste;
