import React from 'react';

import { CardBody } from 'reactstrap';

import tipIcon from '../../_assets/img/icons/global/tip@3x.png';

const TipCard = ({ message, weighting }) => (
  <CardBody
    className={`comments-card ${weighting || ''}`}
    style={weighting === 'weight-tipcard' ? { flex: 'none' } : {}}
  >
    <div className="list-item-layout w-100">
      <div className="list-item-description d-flex justify-content-start align-items-center p-1">
        <img src={tipIcon} alt="tip" width="34" />
      </div>
      <div className="list-item-info d-flex justify-content-center align-items-center p-1 flex-grow-1 justify-content-between">
        {message}
      </div>
    </div>
  </CardBody>
);

export default TipCard;
