import React, { PureComponent, useContext, useState } from 'react';

import { toast } from 'react-toastify';
import { Button, Form, Col, Row, Spinner } from 'reactstrap';
import { Container } from 'reactstrap';

import { ProfileContext } from 'store/ProfileContext';

import privacyPolicy from '../../../../_assets/documents/Oko Adviser Privacy Policy.pdf';
import termsDoc from '../../../../_assets/documents/Oko Adviser Terms of Service.pdf';
import arrowNext from '../../../../_assets/img/icons/profile/ArrowNext@1.5x.png';
import check from '../../../../_assets/img/icons/profile/Complete@3x.png';
import apiStripe from '../../../../config/apiStripe';
import { createSubscription } from '../../../../service/SubscriptionAccountService';
import ValidatorService from '../../../../service/validator';
import { TextField, TextFieldCardMask, TextFieldCardDateMask } from '../../../commons';

import './Step2.scss';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

const parseAmount = (amount) => {
  const decimals = amount.toString().split('.');
  if (!decimals[1]) {
    return amount;
  }
  const regex = /(\d*.\d{0,2})/;
  return amount.toString().match(regex)[0];
};

const fourteenDaysFromNow = new Date(Date.now() + 12096e5);
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default function EnterPayment(props) {
  const profileContext = useContext(ProfileContext);
  const [paymentResult, setPaymentResult] = useState();
  const [card, setCard] = useState({
    name: '',
    number: '',
    exp_month: '',
    exp_year: '',
    cvc: '',
    expiry: '',
  });
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState('Start free trial');
  const [loadingButton, setLoadingButton] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoadingButton(true);
    const subscriptionId = profileContext.selectedSubscriptionId;

    let cardData = {
      name: encodeURI(card.name),
      number: card.number.replace(/[^0-9]/g, ''),
      exp_month: card.expiry.split('/')[0],
      exp_year: '20' + card.expiry.split('/')[1],
      cvc: card.cvc,
    };

    let queryString = Object.keys(cardData)
      .map((key) => 'card[' + key + ']=' + cardData[key])
      .join('&');

    apiStripe.setHeaders({
      Authorization: 'Bearer ' + REACT_APP_STRIPE_PUBLIC_KEY,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    apiStripe
      .post('/v1/tokens', queryString)
      .then((res) => {
        if (res.data.id && res.status === 200) {
          createSubscription(subscriptionId, res.data.id)
            .then(() => {
              let paymentResult = true;
              // this.props.updateStore({ paymentResult });
              props.history.push('/welcome');
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
              let paymentResult = false;
              // this.props.updateStore({ paymentResult });
              // this.props.jumpToStep(2);
            })
            .finally(() => {
              setLoadingButton(false);
            });
        } else {
          console.log(res);
          toast.error(res.data.error.message);
          setLoadingButton(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong.');
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }

  function handleChangeCard({ target: { id, value } }) {
    setCard((state) => ({ ...state, [id]: value }));
  }

  const handleJumpBack = () => {
    if (this.props.prevScreen === 1) {
      return this.props.jumpToStep(0);
    }
    this.props.jumpToStep(2);
  };

  return (
    <Container>
      <Row className="mt-30 mb-10">
        <h2 className="title-pages text-center d-block w-100">Start your free trial!</h2>
      </Row>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className="row-steps">
          <TextField
            control="name"
            onChange={(e) => handleChangeCard(e)}
            label="Name on card"
            placeholder="Adviser Co Pty Ltd"
            value={card.name}
            required
          />
          <TextFieldCardMask
            control="number"
            onChange={(e) => handleChangeCard(e)}
            placeholder="4666 6666 6666 6666"
            label="Card number"
            type="text"
            validateInput={() => ValidatorService.validateInputCreditCard(card.number, 19, 11)}
            value={card.number}
            required
          />
          <Row className="mt-10">
            <Col md={6}>
              <TextFieldCardDateMask
                control="expiry"
                onChange={(e) => handleChangeCard(e)}
                label="Expiry"
                placeholder="MM/YY"
                value={card.expiry}
                type="number"
                validateInput={() => ValidatorService.validateInputDate(card.expiry, 6, 3)}
                required
              />
            </Col>
            <Col md={6}>
              <TextField
                control="cvc"
                onChange={(e) => handleChangeCard(e)}
                label="CCV"
                placeholder="eg. 465"
                value={card.cvc}
                type="number"
                validateInput={() => ValidatorService.validateInputNumberWithSize(card.cvc, 5, 2)}
                required
              />
            </Col>
          </Row>
          <Row className="mt-20">
            <Col className="payment-text">
              <span>
                <img src={check} alt="check" width="20" />
              </span>
              &nbsp; You won't be billed today
            </Col>
          </Row>
          <Row>
            <Col className="payment-text">
              <span>
                <img src={check} alt="check" width="20" />
              </span>
              &nbsp;{' '}
              {`Cancel any time before ${fourteenDaysFromNow.getDate()} of ${
                monthNames[fourteenDaysFromNow.getMonth()]
              }`}
            </Col>
          </Row>
          <Row className="mt-30">
            <Col md={12} className="d-flex justify-content-center">
              {loadingButton ? (
                <Button className="btn-main-shadowless">
                  <Spinner color="primary" size="sm" />
                </Button>
              ) : (
                <Button className="btn-main-shadowless">
                  {label} &nbsp;
                  <img src={arrowNext} />
                </Button>
              )}
            </Col>
          </Row>
          <Row className="mt-30">
            <Col xs={12} className="d-flex justify-content-center mb-10">
              <div className="payment-text">
                By clicking "start free trial" you agree to Oko Adviser's
                <a href={termsDoc} target="_blank" rel="terms and conditions">
                  {` Terms and Conditions`}
                </a>
                {` and `}
                <a href={privacyPolicy} target="_blank" rel="privacy policy">
                  {`Privacy policy.`}
                </a>
                {` Your card will be charged based on your chosen plan.`}
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </Container>
  );
}
