import { GradeInterface } from 'model/Instrument';

import './Grade.scss';

interface GradeProps {
  grade?: GradeInterface;
  className?: string;
  isGradeLabelVisible?: boolean;
  style?: React.CSSProperties;
  isUnstyled?: boolean;
}

export default function Grade({
  grade,
  className,
  isGradeLabelVisible,
  style,
  isUnstyled = false,
}: GradeProps) {
  return (
    <div className={`grade-container ${isUnstyled ? 'grade-container-unstyled' : ''} ${className}`}>
      <div style={style} className="grade-text green-a-star">
        {isGradeLabelVisible ? 'Grade ' : ''}
        {grade?.gradeValue ?? 'N/A'}
      </div>
    </div>
  );
}

// switch (true) {
//   case overallScore >= 90 && overallScore <= 100: {

//     );
//   }
//   case overallScore >= 80 && overallScore < 90: {
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text green-a-plus">A+</div>
//       </div>
//     );
//   }
//   case overallScore >= 70 && overallScore < 80: {
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text green-a">A</div>
//       </div>
//     );
//   }
//   case overallScore >= 60 && overallScore < 70: {
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text green-b-plus">B+</div>
//       </div>
//     );
//   }
//   case overallScore >= 50 && overallScore < 60: {
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text green-b">B</div>
//       </div>
//     );
//   }
//   case overallScore >= 40 && overallScore < 50: {
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text orange-c-plus">C+</div>
//       </div>
//     );
//   }
//   case overallScore >= 30 && overallScore < 40: {
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text orange-c">C</div>
//       </div>
//     );
//   }
//   case overallScore >= 20 && overallScore < 30: {
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text orange-d">D</div>
//       </div>
//     );
//   }
//   case overallScore >= 10 && overallScore < 20: {
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text red-e">E</div>
//       </div>
//     );
//   }
//   case overallScore >= 0 && overallScore < 10: {
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text red-f">F</div>
//       </div>
//     );
//   }
//   default:
//     return (
//       <div className={`grade-container ${className}`}>
//         <div className="grade-text NA">N/A</div>
//       </div>
//     );
//     break;
// }
