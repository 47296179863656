import iconSearch from '../../_assets/img/icons/nav/search/white@3x.png';
import iconPlus from '../../_assets/img/icons/nav/side/add@3x.png';
import iconAdvice from '../../_assets/img/icons/nav/side/advice@3x.png';
import iconAccountBilling from '../../_assets/img/icons/nav/side/billing@3x.png';
import iconInsights from '../../_assets/img/icons/nav/side/chart-pie-simple-light@4x.png';
import iconClientDetails from '../../_assets/img/icons/nav/side/clientDetails@3x.png';
import iconProfile from '../../_assets/img/icons/nav/side/clientProfile@3x.png';
import iconFaqs from '../../_assets/img/icons/nav/side/faqs@3x.png';
import iconImport from '../../_assets/img/icons/nav/side/import@3x.png';
import iconAccountInvite from '../../_assets/img/icons/nav/side/invite@3x.png';
import iconCompare from '../../_assets/img/icons/nav/side/list-check-light@4x.png';
import iconList from '../../_assets/img/icons/nav/side/list@3x.png';
import iconPorfolios from '../../_assets/img/icons/nav/side/portfolios@3x.png';
import iconSetting from '../../_assets/img/icons/nav/side/sliders-light@3x.png';
import iconAccountSubscription from '../../_assets/img/icons/nav/side/subscription@3x.png';
import iconSupport from '../../_assets/img/icons/nav/side/support@3x.png';
import iconSustainabilityProfile from '../../_assets/img/icons/nav/side/sustainabilityProfile@3x.png';
import iconUpload from '../../_assets/img/icons/nav/side/upload@3x.png';
import iconAccountDetails from '../../_assets/img/icons/nav/side/yourDetails@3x.png';

const Presets = {
  main(builder) {
    builder.addHeader('clients', 'Clients', '/clients');
  },

  signIn(builder) {
    builder.addHeader('Sign In');
  },

  signUp(builder) {
    builder.addHeader('Sign Up');
  },

  clients(builder) {
    builder.addSidebar('list', 'Client list', '/clients', iconList);
    builder.addSidebar('create', 'Add a new client', '/clients/add', iconPlus);
    builder.addSidebar('importClients', 'Import clients', '/clients/importClients', iconImport);
    builder.addSidebar(
      'portfoliosUpload',
      'Upload portfolios',
      '/clients/portfoliosUpload',
      iconUpload,
    );
    builder.addSidebar('aplUpload', 'Upload APL', '/aplUpload', iconUpload);
    builder.addSidebar('reviewApl', 'Review APL', '/reviewApl', iconPorfolios);
    //builder.addSidebar('list.upload', 'Upload Portfolio', '/clients/upload');
    //builder.addSidebar('list.copypaste', 'Copy/Paste', '/clients/copy-paste');
    //builder.addSidebar('create.uploadPortfolio', 'Upload portfolio', `/clients/uploadPortfolio`);
    //builder.addSidebar('create.uploadPortfolio', 'Upload file', `/clients/uploadPortfolio`);
  },

  assistance(builder) {
    builder.addSidebar('faqs', 'FAQs', '/assistance/faqs', iconFaqs);
    builder.addSidebar('support', 'Support', '/assistance/support', iconSupport);
  },

  profile(router) {
    return (builder) => {
      const id = router.match.params.id;
      builder.addSidebar('results', 'Results', `/clients/${id}/results`, iconList);
      // builder.addSidebar('results.conflicts', 'Profile Conflicts (0)', `/clients/${id}/results/conflicts`);
      // builder.addSidebar('results.matches', 'Profile Matches (0)', `/clients/${id}/results/matches`);
      // builder.addSidebar('results.alternatives', 'Non Matches (0)', `/clients/${id}/results/alternatives`);
      builder.addSidebar(
        'results.portfolioResults',
        'Portfolio Results',
        `/clients/${id}/results/portfolioResults`,
        iconSustainabilityProfile,
      );
      builder.addSidebar(
        'results.aplResults',
        'APL Results',
        `/clients/${id}/results/aplResults`,
        iconPorfolios,
      );
      builder.addSidebar(
        'results.profileResults',
        'Profile Results',
        `/clients/${id}/results/profileResults`,
        iconClientDetails,
      );
      builder.addSidebar('search', 'Search', `/clients/${id}/search`, iconSearch);
      builder.addSidebar('compare', 'Compare', `/clients/${id}/compare`, iconCompare);
      builder.addSidebar(
        'sustainabilityProfile',
        'Sustainability Profile',
        `/clients/${id}/profile/values`,
        iconSustainabilityProfile,
      );
      builder.addSidebar('portfolios', 'Manage Portfolios', `/clients/${id}/portfolios`, iconPorfolios);
      // builder.addSidebar('profile.values', 'Values', `/clients/${id}/profile/values`);
      // builder.addSidebar('profile.details', 'Details', `/clients/${id}/profile/details`);
      builder.addSidebar('advice', 'Advice Outputs', `/clients/${id}/advice/outputs`, iconAdvice);
      builder.addSidebar('advice.outputs', 'Outputs', `/clients/${id}/advice/outputs`, iconAdvice);
      builder.addSidebar(
        'clientDetails',
        'Client Details',
        `/clients/${id}/clientDetails`,
        iconClientDetails,
      );
      // builder.addSidebar('advice.outcomes', 'Outcomes', `/clients/${id}/advice/outcomes`);
      builder.addBackButton('clients', '< Back to client list', `/clients`, id);
      builder.addSidebar('profile.portfolios', `/portfolioUpload/review/:id`);
    };
  },

  research(builder) {
    builder.addSidebar('search', 'Search', '/search', iconSearch);
    // builder.addSidebar('investmentList', 'Investment list', '/research/investmentList', iconList);
  },
  comparison(builder) {
    builder.addSidebar('compare', 'Compare', '/compare', iconSearch);
  },

  account(builder) {
    builder.addSidebar('account-details', 'Your details', '/account', iconAccountDetails);
    // builder.addSidebar('insights', 'Insights', '/insights', iconInsights);
    builder.addSidebar('account-settings', 'Settings', '/account/settings', iconSetting);
    builder.addSidebar(
      'account-billing',
      'Billing details',
      '/account/billing',
      iconAccountBilling,
    );
    builder.addSidebar(
      'account-subscription',
      'Subscription details',
      '/account/subscription',
      iconAccountSubscription,
    );
    builder.addSidebar(
      'account-invite',
      'Invite other advisers',
      '/account/accountinvite',
      iconAccountInvite,
    );
    builder.addSidebar('faqs', 'FAQs', '/account/faqs', iconFaqs);
    builder.addSidebar('support', 'Support', '/account/support', iconSupport);
  },
};

export default Presets;
