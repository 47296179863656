import { isEmpty, isEqual } from 'lodash';
import React, { PureComponent, Fragment } from 'react';

import { toast } from 'react-toastify';
import { Container, Row, Col, Button, ListGroup, ListGroupItem, Spinner } from 'reactstrap';

import closeIcon from '../../../../../_assets/img/icons/global/close@2x.png';
import { getClient } from '../../../../../service/ClientsService';
import DateUtilsService from '../../../../../service/DateUtils';
import {
  getAllClientPortfolios,
  deleteClientPortfolio,
  getClientPortfolio,
} from '../../../../../service/PortfolioService';
import { getActiveProfileByClientId } from '../../../../../service/ProfilingService';
import {
  LoadingPage,
  TipCard,
  SectionTitle,
  StartQuestionnaireNow,
  UploadPortfolio,
} from '../../../../commons';
import PortfoliosContainer from './PortfoliosContainer';

class Portfolios extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      portfolios: [],
      portfolio: null,
      loading: false,
      adviserClient: null,
      loadingButton: false,
      PortfolioName: '',
      showStartQuestionnaire: true,
    };
  }

  async componentDidMount() {
    const ClientCoreId = this.props.match.params.id;
    this.setState(() => ({ loading: true }));

    await getClient({ ClientCoreId }).then((res) => {
      this.setState(() => ({ adviserClient: res.adviserClient }));
    });

    await getAllClientPortfolios(ClientCoreId)
      .then((response) => {
        this.setState(() => ({ portfolios: response.adviserClientPortfolioWithDetails }));
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ showMessage: true, message: err.message, loading: false });
      });

    await getActiveProfileByClientId(ClientCoreId)
      .then((getActiveProfileByClientIdResponse) => {
        if (getActiveProfileByClientIdResponse.profileExists === true) {
          this.setState(() => ({ showStartQuestionnaire: false }));
        }
      })
      .catch((err) => {
        toast.error(err.message);
        this.setState({ showMessage: true, message: err.message, loading: false });
      });
    this.setState(() => ({ loading: false }));
  }

  async handleRemove(portfolio, index) {
    const portfolios = this.state.portfolios.slice();
    portfolios[index].loading = true;
    this.setState(() => ({ portfolios }));
    await deleteClientPortfolio(portfolio.clientPortfolioId)
      .then(() => {
        this.setState({ portfolios: portfolios.filter((_, i) => i !== index) });
      })
      .catch((err) => {
        toast.error(err.message);
        const portfolios = this.state.portfolios.slice();
        portfolios[index].loading = false;
        this.setState(() => ({
          showMessage: true,
          message: err.message,
          loading: false,
          portfolios,
        }));
      });
  }

  async viewItems(portfolioId) {
    const id = this.props.router.match.params.id;
    this.props.history.push(`/clients/${id}/portfolioUpload/review/${portfolioId}`);
  }

  renderPortfolio() {
    const id = this.props.router.match.params.id;
    const { adviserClient, portfolios, portfolio, portfolioName } = this.state;
    return (
      <div className="slim-content">
        <Fragment>
          <SectionTitle title="Portfolio" results={portfolios.length} />
          <div className='portfolio-grid'>
            <div></div>
            <div style={{ color: "#3f61df" }}>Active</div>
            <PortfoliosContainer handleRemove={this.handleRemove.bind(this)} portfolios={portfolios} viewItems={this.viewItems.bind(this)} />
          </div>
          <Row className="justify-content-center mb-40 mt-30">
            <Col sm={6}>
              <Button
                className="btn-modal btn btn-secondary small-btn"
                style={{ maxWidth: '210px', width: '210px' }}
                onClick={() => this.props.history.push(`/clients/${id}/profile/portfolios/add`)}
              >
                Add a new portfolio
              </Button>
            </Col>
          </Row>
          <TipCard
            message={`Don't forget that if ${adviserClient.clientFirstName}
                    's portfolio changes, you'll need to upload it again so that you're up to date!`}
          />
        </Fragment>
      </div>
    );
  }

  renderContent() {
    const id = this.props.router.match.params.id;
    const { loading, portfolios, adviserClient, showStartQuestionnaire } = this.state;
    if (loading) {
      return <LoadingPage />;
    } else if (showStartQuestionnaire) {
      return (
        <StartQuestionnaireNow
          clientId={id}
          startInvestingProfile={() =>
            this.props.history.push(
              `/clients/${this.props.match.params.id}/investing-profile/start`,
            )
          }
        />
      );
    } else if (isEmpty(portfolios)) {
      return (
        <UploadPortfolio
          adviserClient={adviserClient}
          addPortfolioRedirect={() =>
            this.props.history.push(`/clients/${id}/profile/portfolios/add`)
          }
        />
      );
    } else {
      return this.renderPortfolio();
    }
  }

  render() {
    return (
      <div className="slim-content">
        <Container>{this.renderContent()}</Container>
      </div>
    );
  }
}

export default Portfolios;
