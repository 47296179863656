import { useContext } from 'react';

import { create } from 'apisauce';

import { ProfileContext } from '../store/ProfileContext';

const { REACT_APP_AUTH_API_URL } = process.env;

const apiAuth = create({
  baseURL: REACT_APP_AUTH_API_URL,
  timeout: 55000,
});

export const apiAuthSetup = (dispatch) => {
  apiAuth.addMonitor((response) => {
    if (response.status === 401) {
      const ctx = useContext(ProfileContext);
      ctx.logOut();
      // dispatch(actions.sessionExpired());
      console.warn('Unhandled session expiration');
    }
  });

  apiAuth.addMonitor((response) => {
    if (response.problem === 'NETWORK_ERROR') {
      // dispatch(actions.noInternetConnection());
      console.warn('Unhandled request without connection');
    }
  });
};

export default apiAuth;
