import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row } from 'reactstrap';

import ComparisonOverview from '_components/clients/Comparison/ComparisonOverview';
import InstrumentCompare from '_components/clients/Comparison/InstrumentCompare';
import { IInstrument } from 'interfaces/responses/compare/ICompareDto';
import { getCompare } from 'service/CompareServices';

import unsureIcon from '../../../../../../_assets/img/icons/coDetails/Group 2@4x.png';
import conflictsIcon from '../../../../../../_assets/img/icons/coDetails/conflict/focus@2x.png';
import checkIcon from '../../../../../../_assets/img/icons/profile/Complete@3x.png';

export default function Comparison() {
  const [showInvolvement, setShowInvolvement] = useState(true);
  const [portfolioScores, setPortfolioScores] = useState({});
  const [removeInstruments] = useState(false);
  const [instrumentData, setInstrumentData] = useState<IInstrument[]>([]);
  const [involvements, setInvolvements] = useState([]);
  const [, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    getCompare(id)
      .then((response) => {
        setPortfolioScores(response);
        setInstrumentData(response.instruments);
        setInvolvements(involvements);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [id, involvements]);

  const getStatus = (involvement: number) => {
    if (!involvement) return '-';
    else if (involvement === 1) return <img src={checkIcon} width="20px" height="20px" />;
    else if (involvement === 2) return <img src={conflictsIcon} width="20px" height="20px" />;
    else if (involvement === 3) return <img src={unsureIcon} width="20px" height="20px" />;
  };
  return (
    <Row className="detail box-advice">
      <ComparisonOverview
        showInvolvement={showInvolvement}
        setShowInvolvement={setShowInvolvement}
        portfolioScores={portfolioScores}
        getStatus={getStatus}
        id={id}
        hasTip={false}
        hasViewDetails={false}
      />
      {removeInstruments === false && (
        <InstrumentCompare
          hasViewDetails={false}
          instrumentData={instrumentData}
          involvements={involvements}
          id={id}
        />
      )}
    </Row>
  );
}
