import React from 'react';

import Chart from 'react-apexcharts';

type DoughnutGraphState = {
  dataItems: dataItem[];
  doughnutColor: string[];
};

type dataItem = {
  title: string;
  percent: number;
};

export default function DoughnutGraph(props: DoughnutGraphState) {
  if (props.dataItems == null || props.dataItems.length === 0) {
    return <div>loading</div>;
  }

  // Define the percentages and labels for donut chart
  const percentages = props.dataItems.map((item: dataItem) => Math.round(item.percent));
  const labels = props.dataItems.map((item: dataItem) => item.title);

  const options = {
    labels: labels,
    colors: props.doughnutColor,
    legend: {
      show: true,
      fontSize: '11px',
      fontWeight: 300,
      offsetY: 40,
      offsetX: -35,
      markers: {
        width: 8,
        height: 8,
      },
    },
    plotOptions: {
      pie: {
        customScale: 1.1,
        offsetY: 10,
        offsetX: 15,
        expandOnClick: false,
        donut: {
          size: '55%',
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: '50px !important',
        fontWeight: 300,
      },
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 0,
    },
    tooltip: {
      style: {
        fontSize: '8px',
      },
    },
  };

  return <Chart options={options} series={percentages} type="donut" width={520} height={350} />;
}
