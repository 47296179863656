import React from 'react';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Row, Button } from 'reactstrap';

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  rectIntersection,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { LoadingPage } from '_components/commons';

import { getCountryDetails } from '../../../service/ProfileService';

import tipIcon from './../../../_assets/img/icons/global/tip.png';
import StatesCardContainer from './components/StatesCardContainer';
import { Item } from './components/StatesSortableItem';
import './styles.scss';

export default function States() {
  const history = useHistory();

  const [countries, setCountries] = useState({
    states: [],
    avoid: [],
  });

  const [activeId, setActiveId] = useState();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function findContainer(id) {
    if (id in countries) {
      return id;
    }
    return Object.keys(countries).find((key) => countries[key].some((obj) => obj.id === id));
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over || {};

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setCountries((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      //   Find the indexes for the items
      const activeObj = activeItems.find((obj) => obj.id === id);
      const activeIndex = activeItems.indexOf(activeObj);
      const overObj = overItems.find((obj) => obj.id === overId);
      const overIndex = overItems.indexOf(overObj);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          countries[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over || {};
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = countries[activeContainer].findIndex((item) => item.id === active.id);
    const overIndex = countries[overContainer].findIndex((item) => item.id === over.id);

    if (active.id !== over.id) {
      setCountries((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      }));
    }

    setActiveId(null);
  }

  useEffect(() => {
    getCountryDetails()
      .then((response) => {
        const countryDetails = response.countries;
        setCountries((prev) => ({
          ...prev,
          states: countryDetails,
        }));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  const handleButtonShow = () => {
    const avoidStates = countries.avoid;
    localStorage.setItem('avoidStates', JSON.stringify(avoidStates));
    history.push('final');
  };

  const sustainabilityModal = () => {
    return (
      <>
        <Container className="profile-feature">
          <Row className="mt-5 justify-content-center">
            <p className="text-center profile-header">
              Let us know if you want to avoid any specific sovereign countries
            </p>
          </Row>
          {/* <Row className="mt-2 justify-content-center">
            <DndContext
              sensors={sensors}
              collisionDetection={rectIntersection}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
            >
              <Col sm={3} className="category-panel">
                <Row className="pl-3 mb-10">
                  <p className="category-title">Avoid sovereign states &rarr;</p>
                </Row>
                <Row className="mb-40">
                  <Container className="tips-panel" style={{ width: '90%', padding: '20px' }}>
                    <div className="tips-icon">
                      <img src={tipIcon} />
                    </div>
                    <div className="tips-text">
                      <p className="category-subtitle">
                        Drag and drop any sovereign states that you wish to avoid. Oko Adviser
                        systems will make an assesment based Company HQ, the underlying currency of
                        cash holdings, as well as against sovereign bonds. <br />
                        <br />
                        Tap done when you’ve finalised your selections.
                      </p>
                    </div>
                  </Container>
                </Row>
                <Row className="justify-content-center">
                  <Button onClick={handleClick} className="btn-main-shadowless confirm-button">
                    Done
                  </Button>
                </Row>
              </Col>
              <Col sm={3} className="category-panel">
                <Row>
                  <p className="category-title">Sovereign States</p>
                  <p className="category-subtitle">
                    Based on company HQ, currency and bond issuance
                  </p>
                </Row>
                <Row className="mt-3 causes">
                  <StatesCardContainer id="states" items={countries.states} />
                </Row>
              </Col>
              <Col sm={3} className="category-panel">
                <Row style={{ flexDirection: 'column' }}>
                  <p className="category-title">Avoid</p>
                  <p className="category-subtitle">States you want to avoid</p>
                </Row>
                <Row className="mt-3 avoid">
                  <StatesCardContainer id="avoid" items={countries.avoid} />
                </Row>
              </Col>
              <DragOverlay>
                {activeId ? <Item id={activeId} item={countries[activeId]} /> : null}
              </DragOverlay>
            </DndContext>
          </Row> */}
          <div
            style={{ marginTop: '42px' }}
            className="d-flex justify-content-center overflow-hidden"
          >
            <div
              style={{ gap: '12px', paddingLeft: '10px', paddingRight: '10px' }}
              className="d-flex"
            >
              <div className="draggable-area-grid states-draggable-area-grid">
                <p style={{ gridRow: 'span 2' }} className="category-title tips-panel-header">
                  Avoid sovereign states &rarr;
                </p>
                <p className="category-title">Sovereign States</p>
                <p className="category-title">States you want to avoid</p>
                <p className="category-subtitle">Company HQ and currency</p>
                <p className="category-subtitle">States to avoid</p>
                <div style={{ gap: '40px' }} className="d-flex flex-column">
                  <Container style={{ marginTop: '10px' }} className="tips-panel">
                    <div className="tips-icon">
                      <img src={tipIcon} />
                    </div>
                    <div className="tips-text">
                      <p className="category-subtitle">
                        Drag and drop any sovereign states that you wish to avoid. Oko Adviser
                        systems will make an assesment based Company HQ, the underlying currency of
                        cash holdings, as well as against sovereign bonds. <br />
                        <br />
                        Tap done when you’ve finalised your selections.
                      </p>
                    </div>
                  </Container>
                  <Row className="justify-content-center">
                    <Button
                      onClick={() => handleButtonShow()}
                      className="btn-main-shadowless confirm-button"
                    >
                      <div className="d-flex justify-content-center align-items-center"> Done</div>
                    </Button>
                  </Row>
                </div>
                <DndContext
                  sensors={sensors}
                  collisionDetection={rectIntersection}
                  onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}
                >
                  <StatesCardContainer className="causes" id="states" items={countries.states} />

                  <StatesCardContainer className="avoid" id="avoid" items={countries.avoid} />
                </DndContext>
              </div>
              <DragOverlay>
                {activeId ? <Item id={activeId} item={countries[activeId]} /> : null}
              </DragOverlay>
            </div>
          </div>
        </Container>
      </>
    );
  };

  const loadingModal = () => {
    return <LoadingPage />;
  };

  return countries.states.length !== 0 ? sustainabilityModal() : loadingModal();
}
