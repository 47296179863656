import React from 'react';

import { Col, Input, Label, Row } from 'reactstrap';

interface AdviceOutputAction {
  instrumentId: string;
  instrumentName: string;
  adviceOutputId: string;
  adviceOutputActionId: string;
  actionStatus: number;
}
interface ClientAdviserActionsProps {
  adviceOutputActions: [AdviceOutputAction];
  handleActionsChange: (e: any) => void;
  isEditable: boolean;
}

export default function ClientAdviserAction(props: ClientAdviserActionsProps) {
  // if (isEmpty(props.adviceOutputActions)) {
  //   return null;
  // }

  return (
    <div className="box-advice">
      <h5>Decisions</h5>
      <p>
        After discussing these results, the client and the Adviser decided on the following actions:
      </p>
      <div className="dasshed-box">
        {props.adviceOutputActions.map((adviceOutputAction, idx) => (
          <Row form key={idx}>
            <Col xs={12} md={4} className="d-flex align-items-center justify-content-start">
              <div className="form-check p-0 d-flex align-items-center">
                {adviceOutputAction.instrumentName}
              </div>
            </Col>
            <Col xs={12} md={2} className="d-flex align-items-center justify-content-center">
              <div className="form-check p-0 d-flex align-items-center radio-custom">
                <Label
                  className="form-check-label"
                  for={`remove_${adviceOutputAction.adviceOutputActionId}`}
                >
                  remove
                  <Input
                    className="form-check-input"
                    type="radio"
                    name={adviceOutputAction.adviceOutputActionId}
                    id={`remove_${adviceOutputAction.adviceOutputActionId}`}
                    value="3"
                    onChange={props.handleActionsChange}
                    defaultChecked={adviceOutputAction.actionStatus === 3 ? true : false}
                    disabled={!props.isEditable}
                  />
                  <span className="checkmark"></span>
                </Label>
              </div>
            </Col>
            <Col xs={12} md={2} className="d-flex align-items-center justify-content-center">
              <div className="form-check p-0 d-flex align-items-center radio-custom">
                <Label
                  className="form-check-label"
                  for={`retain_${adviceOutputAction.adviceOutputActionId}`}
                >
                  retain
                  <Input
                    className="form-check-input"
                    type="radio"
                    name={adviceOutputAction.adviceOutputActionId}
                    id={`retain_${adviceOutputAction.adviceOutputActionId}`}
                    value="2"
                    onChange={props.handleActionsChange}
                    defaultChecked={adviceOutputAction.actionStatus === 2 ? true : false}
                    disabled={!props.isEditable}
                  />
                  <span className="checkmark"></span>
                </Label>
              </div>
            </Col>
            <Col xs={12} md={2} className="d-flex align-items-center justify-content-center">
              <div className="form-check p-0 d-flex align-items-center radio-custom">
                <Label
                  className="form-check-label"
                  for={`review_${adviceOutputAction.adviceOutputActionId}`}
                >
                  review
                  <Input
                    className="form-check-input"
                    type="radio"
                    name={adviceOutputAction.adviceOutputActionId}
                    id={`review_${adviceOutputAction.adviceOutputActionId}`}
                    onChange={props.handleActionsChange}
                    value="4"
                    defaultChecked={adviceOutputAction.actionStatus === 4 ? true : false}
                    disabled={!props.isEditable}
                  />
                  <span className="checkmark"></span>
                </Label>
              </div>
            </Col>
            <Col xs={12} md={2} className="d-flex align-items-center justify-content-center">
              <div className="form-check p-0 d-flex align-items-center radio-custom">
                <Label
                  className="form-check-label"
                  for={`add_${adviceOutputAction.adviceOutputActionId}`}
                >
                  add
                  <Input
                    className="form-check-input"
                    type="radio"
                    name={adviceOutputAction.adviceOutputActionId}
                    id={`add_${adviceOutputAction.adviceOutputActionId}`}
                    onChange={props.handleActionsChange}
                    value="1"
                    defaultChecked={adviceOutputAction.actionStatus === 1 ? true : false}
                    disabled={!props.isEditable}
                  />
                  <span className="checkmark"></span>
                </Label>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}
